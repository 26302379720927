import React from 'react'
import { Banner } from '../../Banner'
import TrustedOrganization from '../../TrustedOrganization'
import SimulationData from '../../../data/HCMLearning/Simulation.json'
import FooterBrands from '../../FooterBrands'
import { GoChevronRight } from "react-icons/go";
import { Link } from 'react-router-dom'
import { FooterContact } from '../../FooterContact'
import { GetStarted } from '../../FooterSection/GetStarted'
// import {Image} from '../../../image/About/Approached.png'

const Simulation = () => {
    return (
        <>
            <Banner />
            <TrustedOrganization />
            <div className="SimulationContainer">
                {
                    SimulationData.map((item, index) => {
                        const { className, HeadingData } = item;
                        return (
                            className !== "FlexContentBody" && (
                                <div className={className} key={index}>
                                    {
                                        HeadingData && (
                                            HeadingData.map((item, index) => {
                                                const { className, Grid } = item;
                                                return (
                                                    <div className={className} key={index}>
                                                        <div className="Heading-Content justify-content-center text-center align-items-center row px-4">
                                                            {
                                                                Grid.map((item, index) => {
                                                                    const { GridName, className, Name } = item;
                                                                    return (
                                                                        <div className={GridName} key={index}>
                                                                            <p className={className}>{Name}</p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        )
                                    }
                                </div>
                            )
                        )
                    })
                }
                {/* <FooterContact /> */}
                <FooterBrands />

            </div>
            <GetStarted />
        </>
    )
}

export default Simulation
