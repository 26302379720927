import React from 'react'
import { Banner } from '../../Banner'
import TrustedOrganization from '../../TrustedOrganization'
import '../../../style/HCMLearning.css'
import FooterBrands from '../../FooterBrands';
import { FooterContact } from '../../FooterContact';
import { GetStarted } from '../../FooterSection/GetStarted';

const ContentResourceCenter = ({ adata }) => {
  const ContentResourceData = adata;
  return (
    <>
      <Banner />
      <TrustedOrganization />

      <div className={ContentResourceData[0].MainClassName}>
        {
          ContentResourceData[0].Cards.map((item, index) => (
            <div className={`Content_resource_cards ${item.className}`} key={index} style={{ padding: '0px', margin: '0px' }}>
              <div className="container" style={{ padding: '3% 3% 0% 3%' }}>


                <h3 className='Content_Card_header text-center fw-bold'>{item.Name}</h3>
                {
                  item.Desc.map((item2, index2) => (
                    <p className={item.Desc_bg}>{item2.Name}</p>
                  ))
                }

                <div className="row justify-content-center position-relative" >
                  {
                    item.card.map((item1, index1) => (
                      <>
                        <p className="Card-Dots1">{item1.Dot1}</p>
                        <p className="Card-Dots2">{item1.Dot2}</p>
                        <p className="Card-Dots3">{item1.Dot2}</p>
                        <div className={`${item1.className}`}>
                          <div className="cardImg text-start d-flex justify-content-between align-items-center w-100">
                            <p className='text-start mt-4 fw-bolder'>{item1.Header}</p>
                            <img src={require(`../../../image/HCMLearning/${item1.Img}.png`)} height="50" width="50" alt="" />
                          </div>
                          <p className='text-start mt-2 w-100'>{item1.Desc}</p>
                        </div>
                      </>
                    ))
                  }
                </div>
              </div>
            </div>

          ))
        }
      </div>
      {/* <FooterContact /> */}
      <FooterBrands />
      <GetStarted />
    </>
  )
}

export default ContentResourceCenter