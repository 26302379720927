import React, { useState } from "react";
import hcmcoe from "../../data/HCM/employee_portals.json";
import { Banner } from "../Banner";
import TrustedOrganization from "../TrustedOrganization";
import FooterBrands from "../FooterBrands";
import { GetStarted } from "../FooterSection/GetStarted";
import { Helmet } from 'react-helmet';

const EmployeePortals = () => {

    const [activeTab, setActiveTab] = useState("tab1");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            <div className="" style={{ overflowX: 'hidden' }}>
            <Helmet>
                <title>Unleash Human Ingenuity with an Employee Portal</title>
                <meta name="description" content="See how you can unleash the value in your workforce with an employee portal designed for the way your people work." />
            </Helmet>
                <Banner />
                <TrustedOrganization />
                {hcmcoe.map((item) => {
                    const { id, title, desc, bannerImage, infotext, grid, org, subtitle } = item;
                    return (
                        <div className="row">
                            <div className="col-12 w-100" key={id}>
                                {id === 1 && (
                                    <div className="row d-flex justify-content-around mt-3 mb-1">
                                        <div className="col-lg-11 col-md-11 col-sm-11 col-11">
                                            <div className="d-flex flex-column align-items-center justify-content-around mt-3 ">
                                                <div className="col-lg-8 col-md-8 col-sm-11 col-11 mb-2">
                                                    <h4 className="text-center fw-bold  title-font" style={{ color: 'rgb(242,111,33)' }}>{title}</h4>
                                                    <div>
                                                        <p className="text-center title-font">{desc}</p>
                                                    </div>
                                                    <h4 className="text-center fw-bold title-font" style={{ color: 'rgb(242,111,33)' }}>{subtitle}</h4>
                                                    <div>
                                                        {infotext.map((text, index) => (
                                                            <div className="text-center  title-font" key={index}>
                                                                {text}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {id === 2 && (
                                    <div className="row d-flex justify-content-around mb-3 ">
                                        <div className="col-12 d-flex justify-content-around flex-column ">
                                            <div className="row w-100" style={{ margin: "0PX", padding: "0PX" }}>
                                                {grid && grid.map((gridItem) => {
                                                    const { position, tab, storyl } = gridItem;
                                                    return (
                                                        <div className="employee_portal_content col-lg-12 col-md-12 col-sm-12 col-12 align-items-center d-flex flex-column p-0" key={position}>
                                                            {storyl && storyl.map((story, index) => {
                                                                const { id, title, subtitle, servicegrid, url, icon, fotter } = story;
                                                                const isEven = index % 2 === 0;
                                                                const rowColor = isEven ? 'bg-white' : 'bg-blue';
                                                                const textColor = isEven ? '' : 'text-white';
                                                                const imgG = isEven ? '' : 'exclusion';
                                                                return (
                                                                    <div className={`py-3 ${rowColor}`} key={id}>
                                                                        <div className={`row d-flex align-items-center flex-column ${textColor}`}>
                                                                            <div className="col-lg-8 col-md-8 col-sm-10 col-11  text-center">
                                                                                {story.title && <h4 className="fw-bold  Pcolor title-font">{story.title}</h4>}
                                                                                {icon && <img src={require(`../../image/HCM Coe/employeePortals/${icon}.png`)} alt="HCM" className="img-fluid" />}
                                                                                {story.subject && <p className="pb-3  normal-text-font">{story.subject}</p>}
                                                                                {story.subtitle && <h4 className="fw-bold Pcolor text-center title-font">{story.subtitle}</h4>}
                                                                                {story.subtext && <p className="pb-3  normal-text-font">{story.subtext}</p>}

                                                                            </div>
                                                                            <div className={`col-lg-11 col-md-11 col-sm-11 col-12  px-5`}>
                                                                                <div className="row justify-content-center">
                                                                                    {servicegrid.map((item, index) => {
                                                                                        const { id, className, icon, title, desc, btn, url, bullets, card, cta } = item;
                                                                                        const rowColor = index % 2 === 0 ? 'bg-white' : 'bg-blue';
                                                                                        const isEven = index % 2 === 0;
                                                                                        return (
                                                                                            <>
                                                                                                <div className={`col-12 col-md-4 col-sm-6 col-lg-4 text-center mt-3`} key={id} >
                                                                                                    <div className={`card text-center erp-card-radius card-bg-color py-3 px-3 erp-card-background Description-Fontsize  py-2 px-2 h-100 border`} >
                                                                                                        <div className={`d-flex justify-content-around mt-2`} >
                                                                                                            <img src={require(`../../image/HCM Coe/employeePortals/${icon}.png`)} className="icon-size icon-box" alt="HCM" />
                                                                                                        </div>
                                                                                                        <div className="d-flex flex-column mt-2 justify-content-between h-100 align-items-center">
                                                                                                            <div className="align-items-center">
                                                                                                                <p className=" fw-bold title-font d-flex align-items-center justify-content-around">{title}</p>
                                                                                                                <p className="normal-text-font text-start">{desc}</p>
                                                                                                            </div>

                                                                                                            <div className="mb-2">
                                                                                                                {btn && <a href={url} target="_blank" className="btn button-hover overlaybtn text-white normal-text-font" rel="noreferrer noopener">{btn}</a>}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        );
                                                                                    })}
                                                                                    <div className="text-center mt-3">
                                                                                        <p>{gridItem.storyl[index].fotter}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {id === 3 && (
                                    <div className="row d-flex justify-content-around mt-3 mb-1">
                                        <div className="col-lg-7 col-md-7 col-sm-10 col-11">
                                            <div style={{ border: "1px solid #333", width: "100%" }}></div>
                                            <div className="d-flex flex-column align-items-center justify-content-around mt-3 ">
                                                <div className="col-lg-8 col-md-8 col-sm-11 col-11 mb-2">
                                                    <div>
                                                        <p className="text-center fs-6 title-font fw-bold">"{desc}"</p>
                                                    </div>
                                                    <div>
                                                        {infotext.map((text, index) => (
                                                            <div className="text-center  title-font" key={index}>
                                                                {text}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ border: "1px solid #333", width: "100%" }}></div>
                                        </div>
                                    </div>
                                )}
                                {id === 4 && (
                                    <div className="row d-flex justify-content-around mt-3 mb-1">
                                        <div className="col-lg-11 col-md-11 col-sm-11 col-11">
                                            <div className="d-flex flex-column align-items-center justify-content-around mt-3 ">
                                                <div className="col-lg-10 col-md-10 col-sm-11 col-11 mb-2">
                                                    <h4 className="text-center fw-bold  title-font" style={{ color: 'rgb(242,111,33)' }}>{title}</h4>
                                                    <div>
                                                        {infotext.map((text, index) => (
                                                            <div className="text-center  title-font" key={index}>
                                                                {text}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {id === 5 && (
                                    <div className="row d-flex justify-content-around mt-2 mb-3 card_section_bg py-4">
                                        <div className="col-11 d-flex justify-content-around flex-column px-5">
                                            <div className="row d-flex align-items-center justify-content-between">
                                                {grid && grid.map((gridItem) => {
                                                    const { position, icon, title, tab, storyl } = gridItem;
                                                    return (
                                                        <div className="col-lg-3 col-md-6 col-sm-12 col-12 mt-3" key={position}>
                                                            <div
                                                                className={`tab d-flex align-items-center flex-column service-button talent_management_service_btn ${activeTab === tab ? 'active-tab  service_btn_border_color' : ''}`}
                                                                onClick={() => handleTabClick(tab)}
                                                                style={{ backgroundColor: activeTab === tab ? 'rgb(255 255 255)' : '', color: activeTab === tab ? '#000' : '', width: '95%' }}
                                                            >
                                                                <div className="mt-2 img-hh mb-2 " style={{ filter: activeTab === tab ? '' : 'brightness(0) invert(1)' }}>
                                                                    <img src={require(`../../image/HCM Coe/employeePortals/${icon}.png`)} className="img-fluid" alt={icon} />
                                                                </div>
                                                                <div className="mb-2 fs-6">
                                                                    {title}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="row">
                                                {grid && grid.map((gridItem) => {
                                                    const { position, tab, storyl } = gridItem;
                                                    return (
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12" key={position}>
                                                            {activeTab === tab && storyl && storyl.map((story) => {
                                                                const { id, title, subtitle, servicegrid, url } = story;
                                                                return (
                                                                    <div className="py-3 text-center" key={id}>
                                                                        <div className="row d-flex align-items-center flex-column ">
                                                                            <div className="col-lg-8 col-md-8 col-sm-10 col-11 mt-3">
                                                                                <h4 className="fw-bold  Pcolor title-font">{story.title}</h4>
                                                                                {story.subject.map((sub, index) => (
                                                                                    <p className="line-_height  pb-3 normal-text-font">{sub}</p>
                                                                                ))}
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="row justify-content-center">
                                                                                    {servicegrid && servicegrid.map(service => {
                                                                                        const { icon, className } = service;
                                                                                        return (
                                                                                            <div className={`col-12 col-md-6 col-sm-6 ${service.position === "CTA" ? 'col-lg-8' : 'col-lg-4'} mt-3`} key={service.position}>
                                                                                                <div className={`card text-center erp-card-radius card-bg-color erp-card-background Description-Fontsize  ${className} ${service.position === "CTA" ? 'flex-row-reverse' : 'flex-column'}  py-2 px-4 h-100`} style={{ width: service.position === "CTA" ? '97%' : '97%' }}>
                                                                                                    <div className="cardImg text-start d-flex justify-content-between align-items-center">
                                                                                                        <p className=" fw-bold title-font d-flex align-items-center justify-content-around">{service.title}</p>
                                                                                                        <img src={require(`../../image/HCM Coe/employeePortals/${service.icon}.png`)} alt={service.icon} height="25" className="icon-size icon-box" />
                                                                                                    </div>

                                                                                                    <div className="d-flex flex-column mt-2 justify-content-between  text-start">
                                                                                                        <div style={{ width: service.position === "CTA" ? '75%' : '100%' }}>
                                                                                                            <p className="normal-text-font line-_height">{service.desc}</p>
                                                                                                        </div>
                                                                                                        <div className="mb-2">
                                                                                                            {service.btn && <a href={service.url} target="_blank" className="btn button-hover overlaybtn text-white normal-text-font" rel="noreferrer noopener">{service.btn}</a>}

                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                })}


                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>
                        </div >
                    );
                })}
                <FooterBrands />
                <GetStarted />
            </div >
        </>
    );
}

export default EmployeePortals;