
import React from 'react';
import { Link } from 'react-router-dom';
import '../style/search.css'; // Import CSS for styling

const computeSimilarity = (text, query) => {
  const queryLower = query.toLowerCase();
  const textLower = text.toLowerCase();
  let score = 0;

  // Count the number of characters in query that match in text
  for (let i = 0; i < queryLower.length; i++) {
    if (textLower.includes(queryLower[i])) {
      score++;
    }
  }

  return score;
};

const highlightText = (text = '', query) => {
  if (!query) return text;
  const parts = text.split(new RegExp(`(${query})`, 'gi'));
  return parts.map((part, index) =>
    part.toLowerCase() === query.toLowerCase() ? (
      <span key={index} className="highlighted">{part}</span>
    ) : (
      part
    )
  );
};

const SearchPopup = ({ results, query, onClose }) => {
  if (!query) return null; // Don't render if there's no query

  // Find the result with the highest similarity score
  const bestMatch = results.reduce((best, result) => {
    const titleScore = computeSimilarity(result.title, query);
    return titleScore > best.score ? { ...result, score: titleScore } : best;
  }, { score: 0 });

  return (
    <div className="search-popup">
      <div className="search-popup-content">
        <div className="search-popup-header mt-4 mb-4">
          {results.length === 0
            ? 'No results found'
            : `${results.length} result${results.length > 1 ? 's' : ''} found`}
        </div>
        {results.length > 0 && (
          <>
            {results.map((result, index) => (
              <div key={index} className={`search-popup-item ${result === bestMatch ? 'highlighted-result' : ''}`}>
                <div className="search-popup-title">
                  <h4>{highlightText(result.title, query)}</h4>
                </div>
                <div className="search-popup-path">
                  <Link to={result.path} onClick={onClose} className="search-popup-link">
                    {highlightText(result.path, query)}
                  </Link>
                </div>
                <div className="search-popup-description">
                  {highlightText(result.description, query)}
                </div>
                <div className="search-popup-description">
                  {highlightText(result.content, query)}
                </div>
                <hr className="search-popup-divider" />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default SearchPopup;


