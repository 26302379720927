import React, { useState } from 'react'
import { Banner } from '../Banner'
import { Helmet } from 'react-helmet'
import TrustedOrganization from '../TrustedOrganization'
import { GetStarted } from '../FooterSection/GetStarted'
import DigitalTransformationData from '../../data/EB/DigitalTransformation.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Accordion, AccordionButton } from 'react-bootstrap'
import { GoHorizontalRule, GoPlus } from 'react-icons/go'
import '../../style/EB.css'

const DigitalTransformationJourneys = () => {

    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (eventKey) => {
        setExpandedItem(expandedItem === eventKey ? null : eventKey);
    };


    return (
        <>
            <Helmet>
                <title>Phenom eCloud Digital Transformation Services</title>
                <meta
                    name="description"
                    content="Take control of your business's future. Learn how Phenom eCloud enables you to transition to your digital future."
                />
            </Helmet>
            <Banner />
            <TrustedOrganization />
            {DigitalTransformationData.map((item, index) => {
                const { id, title, desc, bannerImage, accordians, infotext, grid, sub_title, card, CTA } = item;
                return (
                    <div className="" key={id}>
                        {id == 1 ? (
                            <div class="row d-flex justify-content-evenly py-4  mb-3 align-items-center  erp_future_analytic  whitebg_section discover_possible">
                                <div className="col-md-4 text-center col-sm-12 col-12">
                                    <div>
                                        <img src={require(`../../image/EB/DigitalTransformation/${bannerImage}.png`)} className="img-fluid " />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 col-12">
                                    {
                                        desc.map((data, index) => (
                                            <div className="head_on_desc_body mb-3">
                                                {data.Desc_header && <p className="mb-3" style={{ fontWeight: 'bolder', lineHeight: '30px', fontSize: '27px' }}>{data.Desc_header}</p>}
                                                {data.Desc &&
                                                    data.Desc.map((value, index1) => (
                                                        <div className="explore_service_section">
                                                            <div className="d-flex align-items-center mb-2" key={index}>
                                                                <div>
                                                                    <p className="normal-text-font" style={{ fontWeight: '600', lineHeight: '20px', fontSize: '16px' }}>{value.text} </p>
                                                                    {
                                                                        value.ul && value.ul.map((value1, index2) => (
                                                                            <li className="normal-text-font" dangerouslySetInnerHTML={{ __html: value1.li }}></li>
                                                                        ))
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>

                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        ) : ''}
                        {
                            (id == 2 || id == 4) && (
                                <div className={`row d-flex justify-content-center align-items-center  mb-3 ${id == 2 ? 'allcoe_service_card_bg' : ''}`}>
                                    {title && <h3 className="text-center fw-bold hcm_offer_header mt-4">{title}</h3>}
                                    {desc && <div className="title_desc  d-flex flex-column align-items-center">
                                        {
                                            desc.map((value, items) => (
                                                value && <p key={items} className="text-center service_desc" style={{ width: '70%', fontSize: '16px' }}>{value.text}</p>
                                            ))
                                        }
                                    </div>
                                    }
                                    <div className="row d-flex justify-content-center align-items-center our_service_body_content mt-3 mb-3">
                                        {
                                            card.map((data, index) => {

                                                return <div className="col-md-5 col-dm-12 m-3 our_service_card card d-flex flex-column card-bg-color justify-content-evenly p-3 DigitalTransformationCards">
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <p className="fw-bold">{data.card_name}</p>
                                                        <img src={require(`../../image/EB/DigitalTransformation/${data.icon}`)} alt="" srcset="" className='icon-box' />
                                                    </div>
                                                    <div className="card-body px-1 d-flex flex-column justify-content-center">
                                                        {
                                                            data.card_desc && data.card_desc.map((item, index) => (
                                                                <div className="d-flex col-md-11 align-items-baseline">
                                                                    <span style={{ marginRight: '3%' }}><FontAwesomeIcon icon={faArrowRight} style={{ color: "#5CB6F2" }} /></span>
                                                                    <p className="our_service_card_desc" dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                                                </div>

                                                            ))
                                                        }
                                                    </div>
                                                    <div className="  col-md-12 d-flex justify-content-end align-items-center">
                                                        {data.card_url && <Link style={{ marginRight: '2%', textDecoration: 'none' }} to={data.card_url}>
                                                            <p >Discover your possibilities</p></Link>}
                                                        {data.card_url && <Link to={data.card_url} className=''>
                                                            <button class="btn card-btn-color"> <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} /></button></Link>}

                                                    </div>
                                                </div>

                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }

                    </div>
                );
            })}
            <GetStarted />
        </>
    )
}

export default DigitalTransformationJourneys