import React from "react";
import { Banner } from "../Banner";
import TrustedOrganization from "../TrustedOrganization";
import hcmcoe from "../../data/HCM/payroll.json";
import FooterBrands from "../FooterBrands";
import { GetStarted } from "../FooterSection/GetStarted";
import { Helmet } from 'react-helmet';


const Payroll = () => {



    return (
        <div className="" style={{ overflowX: 'hidden' }}>
            <Helmet>
                <title>Phenom eCloud Payroll System Services</title>
                <meta name="description" content="Phenom eCloud payroll services guide your payroll system selection, implementation, testing, and optimization to keep your payroll running smoothly support services for compensation technology." />
            </Helmet>
            <Banner />
            <TrustedOrganization />
            {hcmcoe.map((item) => {
                const { id, title, desc, bannerImage, infotext, grid, org } = item;
                return (
                    <div className="row">
                        <div className="col-12 w-100" key={id}>
                            {id === 1 && (

                                <div className="row d-flex justify-content-around mt-3 mb-3">
                                    <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_fix">
                                        <div className=" ">
                                            <div className="">
                                                <h2 className="text-center fw-bold fs-2 title-font">{title}</h2>
                                            </div>
                                            <div className="">
                                                <div className="row d-flex justify-content-center align-items-center">
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-12 ">
                                                        <img src={require(`../../image/HCM Coe/Payroll/${bannerImage}.png`)} className="img-fluid" />
                                                    </div>
                                                    <div className="col-lg-8 col-md-8 col-sm-8 col-12 d-flex flex-column justify-content-evenly" style={{ padding: '16px' }}>
                                                        {infotext.map((paragraph, index) => (
                                                            <p className=" normal-text-font" key={index}>{paragraph}</p>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {id === 2 && (
                                <div className="row d-flex justify-content-around mt-2 mb-3 ">
                                    <div className="col-12 d-flex justify-content-around align-items-center flex-column ">

                                        <div className="row w-100">
                                            {grid && grid.map((gridItem, index) => {
                                                const { position, tab, title, storyl } = gridItem;
                                                const isEven = index % 2 === 0;
                                                const rowColor = isEven ? 'bg-white' : 'bg-blue';
                                                const textColor = isEven ? '' : 'text-white';
                                                const imgG = isEven ? '' : 'exclusion';
                                                const colr = isEven ? 'rgb(242,111,33)' : 'white';
                                                if (position != 4.5) {
                                                    if (position == 4.3) {
                                                        return (
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 align-items-center d-flex flex-column payroll_body p-0" key={position}>
                                                                {storyl && storyl.map((story, index) => {
                                                                    const { id, servicegrid, icon, img } = story;

                                                                    return (
                                                                        <div className={`py-3 ${rowColor}`} key={id}>
                                                                            <div className={`row d-flex align-items-center flex-column ${textColor}`}>
                                                                                <div className="col-lg-8 col-md-8 col-sm-10 col-11 mt-3 text-center">
                                                                                    <h4 className="fw-bold title-font" style={{ color: colr }}>{story.title}</h4>
                                                                                    {icon && <img src={require(`../../image/HCM Coe/Payroll/${icon}.png`)} alt="HCM" className="img-fluid" />}
                                                                                    <p className="  pb-3 normal-text-font">{story.subject}</p>
                                                                                </div>
                                                                                <div className={`col-lg-11 col-md-11 col-sm-11 col-12 mt-3 px-5 tablet_padding_remove`}>
                                                                                    <div className="row justify-content-center align-items-center Project_Implementation">
                                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column Project_Implementation_content  box-h2">
                                                                                            {servicegrid.map((item, index) => {
                                                                                                const { id, icon, title, desc, btn, url } = item;
                                                                                                const rowColor = index % 2 === 0 ? 'bg-white' : 'bg-blue';
                                                                                                const isEven = index % 2 === 0;
                                                                                                return (
                                                                                                    <>

                                                                                                        <div>
                                                                                                            <div className="d-flex align-items-center mb-3" key={index}>
                                                                                                                <div>
                                                                                                                    <img src={require(`../../image/HCM Coe/Payroll/${icon}.png`)} className="payrollSmImg" alt="HCM" />
                                                                                                                </div>
                                                                                                                <div><p className="normal-text-font" style={{ marginLeft: '10px' }}>{desc}</p></div>

                                                                                                            </div>
                                                                                                        </div>


                                                                                                    </>
                                                                                                );

                                                                                            })}
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column Project_Implementation_img align-items-center mb-3">

                                                                                            <div>
                                                                                                <img src={require(`../../image/HCM Coe/Payroll/${img}.png`)} alt="HCM" className="img-fluid" />
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        );
                                                    }
                                                    else {
                                                        return (
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 align-items-center d-flex flex-column payroll_body p-0" key={position}>
                                                                {storyl && storyl.map((story, index) => {
                                                                    const { id, servicegrid, icon } = story;

                                                                    return (
                                                                        <div className={`py-3 ${rowColor}`} key={id}>
                                                                            <div className={`row d-flex align-items-center flex-column ${textColor}`}>
                                                                                <div className="col-lg-8 col-md-8 col-sm-10 col-11 mt-3 text-center">
                                                                                    <h4 className="fw-bold title-font" style={{ color: colr }}>{story.title}</h4>
                                                                                    {icon && <img src={require(`../../image/HCM Coe/Payroll/${icon}.png`)} alt="HCM" className="img-fluid" />}
                                                                                    <p className="  pb-3 normal-text-font">{story.subject}</p>
                                                                                </div>
                                                                                <div className={`col-lg-11 col-md-11 col-sm-11 col-12 mt-3 px-5 tablet_padding_remove`}>
                                                                                    <div className="row justify-content-center">

                                                                                        {servicegrid.map((item, index) => {
                                                                                            const { id, className, icon, title, desc, btn, url } = item;
                                                                                            const rowColor = index % 2 === 0 ? 'bg-white' : 'bg-blue';
                                                                                            const isEven = index % 2 === 0;
                                                                                            return (
                                                                                                <>
                                                                                                    <div className={`col-12 col-md-4 col-sm-6 col-lg-4 text-center mt-3`} key={id} >
                                                                                                        <div className={`d-flex  flex-column card-radius card-box-fade-shadow py-2 px-2 h-100 card ${className}`} >
                                                                                                            <div className={`d-flex justify-content-around`} >
                                                                                                                <img src={require(`../../image/HCM Coe/Payroll/${icon}.png`)} className="icon-size" alt="HCM" />
                                                                                                            </div>
                                                                                                            <div className="d-flex flex-column mt-2 justify-content-between h-100 align-items-center">
                                                                                                                <div className="align-items-center">
                                                                                                                    <p className="d-flex align-items-center justify-content-around">{title}</p>
                                                                                                                    <p className="normal-text-font position-relative z-1">{desc}</p>
                                                                                                                </div>
                                                                                                                <div className="mb-2">
                                                                                                                    {btn && <a href={url} target="_blank" className="btn button-hover overlaybtn text-white normal-text-font" rel="noreferrer noopener">{btn}</a>}

                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>

                                                                                                </>
                                                                                            );

                                                                                        })}

                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        );
                                                    }

                                                }
                                                else {
                                                    return (
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 align-items-center justify-content-around d-flex payroll_body" key={position}>
                                                            {storyl && storyl.map((story, index) => {
                                                                const { id, servicegrid } = story;
                                                                return (
                                                                    <div className={`py-3 ${rowColor}`} key={id}>
                                                                        <div className={`row d-flex align-items-center justify-content-around ${textColor}`}>
                                                                            <div className={`col-lg-11 col-md-11 col-sm-11 col-12 mt-3 px-5 tablet_padding_remove padding_remove`}>
                                                                                {servicegrid.map((item, index) => {
                                                                                    const { id, icon, title, desc, btn, url } = item;
                                                                                    const rowColor = index % 2 === 0 ? 'bg-white' : 'bg-blue';
                                                                                    const isEven = index % 2 === 0;
                                                                                    return (
                                                                                        <div className={`col-lg-11 col-md-11 col-sm-11 col-12 mt-3 px-5 tablet_padding_remove padding_remove`} key={id} >
                                                                                            <div className={`row d-flex flex-row align-items-center  py-2 px-2 h-100 GetDetails`} >
                                                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-row mt-2 h-100">
                                                                                                    <div className="">
                                                                                                        <p className="normal-text-font">{desc}</p>
                                                                                                        {btn && <a href={url} className="btn HoverBtn overlaybtn text-white normal-text-font" rel="noreferrer noopener">{btn}</a>}
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className={` col-lg-6 col-md-6 col-sm-6 col-12 d-flex justify-content-around `} >
                                                                                                    <img src={require(`../../image/HCM Coe/Payroll/${icon}.png`)} className="img-fluid" style={{ mixBlendMode: imgG }} alt="HCM" />
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    );

                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    );

                                                }

                                            })}


                                        </div>
                                    </div>

                                </div>
                            )}

                        </div>
                    </div>

                );
            })}
            <FooterBrands />
            <GetStarted />
        </div>
    );
}

export default Payroll;