import React from 'react'
import { GoArrowUpRight } from "react-icons/go";
import data from '../data/OurPartners.js.json'
import { Banner } from './Banner'
import { GetStarted } from './FooterSection/GetStarted';


export const OurPartners = () => {
    return (
        <>
            <Banner />
            <div className='container-flued'>
                {
                    data.map((item) => {
                        const { Id, Heading, Description, Data, className } = item;
                        return (
                            <div key={Id}>
                                <div className="BrandContainer">
                                    {
                                        Id !== 4 && (
                                            <div className={`row p-5 ${className} justify-content-center align-items-end`}>
                                                {
                                                    Heading !== "" && (
                                                        <div className="d-flex justify-content-center">
                                                            <h3 className='Heading'>{Heading}</h3>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    Data.map((item) => {
                                                        const { Id, className, Img } = item
                                                        return (
                                                            <div className={`p-2 ${className}`} key={Id}>
                                                                <img src={require(`../image/OurPartners/${Img}`)} alt="" />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    Id === 4 && (
                                        <div className="ServicePartner p-5">

                                            <div className="row p-5 padding_fix tablet_padding_remove">
                                                <div className="PartnerContainer p-2 mb-4">
                                                    <h1 className="Heading fw-bold">{Heading}</h1>
                                                    <p className="Description fw-bold">{Description}</p>
                                                </div>

                                                {
                                                    Data.map((item) => {
                                                        const { Id, Heading, Description, Img } = item;
                                                        return (
                                                            <div className="col-md-4 mb-5 MainCard" key={Id}>
                                                                <div className="card">
                                                                    <div className="card-body p-0">
                                                                        <img src={require(`../image/OurPartners/${Img}`)} alt="" />
                                                                        <div className="CardDetails p-4 text-start">
                                                                            <h4 className="Heading fw-bold mb-4">{Heading}</h4>
                                                                            <p className="Description">{Description}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className="MoreBtn">
                                                    <button className="btn btn-dark">Learn More
                                                        <GoArrowUpRight /></button>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    })
                }
            </div>
            <GetStarted />
        </>
    )
}
