import React from 'react'
import { Banner } from '../Banner'
import Agile from './Agile'
import { GetStarted } from '../FooterSection/GetStarted'
import { Helmet } from 'react-helmet'

export const Home = () => {
    return (
      <div>
        <Helmet>
          <title>Phenom eCloud | Technology Consulting and Support</title>
          <meta
            name="description"
            content="At Phenom ecloud, we provide tailored IT solutions that address your unique business needs, ensuring technology serves to solve problems, not create them."
          />
        </Helmet>
        <Banner />
        <Agile />
        <GetStarted />
      </div>
    );
}
