import React, { useState }  from 'react'
import { Helmet } from 'react-helmet';
import TrustedOrganization from '../TrustedOrganization';
import { Banner } from '../Banner';
import ManagementData from '../../data/ERP/DataManagement.json'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Accordion, AccordionButton } from 'react-bootstrap';
import { GoHorizontalRule, GoPlus } from 'react-icons/go';
import { GetStarted } from '../FooterSection/GetStarted';

const DataManagement = () => {

    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (eventKey) => {
        setExpandedItem(expandedItem === eventKey ? null : eventKey);
    };


  return (
    <>
            <Helmet>
                <title>Phenom eCloud Master Data Management for ERP</title>
                <meta name="description" content="To make your business more efficient and effective, your ERP requires clean, well-managed, valuable data. Learn how you can achieve that performance level." />
            </Helmet>
            <Banner />
            <TrustedOrganization />
            {ManagementData.map((item, index) => {
                const { id, title, desc, bannerImage, accordians, infotext, grid, sub_title, card } = item;
                return (
                    <div className="" key={id}>
                        {id == 1 ? (
                            <div class="row d-flex justify-content-evenly py-4  mb-3 align-items-center    whitebg_section discover_possible">
                                <div className="col-md-4 text-center col-sm-12 col-12">
                                    <div>
                                        <img src={require(`../../image/ERP/DataManagement/${bannerImage}.png`)} className="img-fluid " />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 col-12">
                                    {
                                        desc.map((data, index) => (
                                            <div className="head_on_desc_body mb-3">
                                                {data.Desc_header && <p className="mb-3" style={{ fontWeight: 'bolder', lineHeight: '23px', fontSize: '27px' }}>{data.Desc_header}</p>}
                                                {data.Desc &&
                                                    data.Desc.map((value, index1) => (
                                                        <div className="explore_service_section">
                                                            <div className="d-flex align-items-center mb-2" key={index}>
                                                                <div>
                                                                    <p className="normal-text-font" style={{ fontWeight: '600', lineHeight: '20px', fontSize: '16px' }}>{value.text} </p>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        ) : ''}
                        {
                            id == 3 && (
                                <div className="row d-flex justify-content-center align-items-center allcoe_service_card_bg mb-3">
                                    {title && <h3 className="text-center fw-bold hcm_offer_header mt-4">{title}</h3>}
                                    <div className="row d-flex justify-content-center align-items-center our_service_body_content mt-3 mb-3">
                                        {
                                            card.map((data, index) => {
                                                
                                                    return <div className="col-md-5 col-dm-12 m-3 our_service_card card d-flex flex-column card-bg-color justify-content-evenly p-3 DataManagementCards">
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <p className="fw-bold">{data.card_name}</p>
                                                            <img src={require(`../../image/ERP/DataManagement/${data.icon}`)} alt="" srcset="" className='icon-box' />
                                                        </div>
                                                        <div className="card-body px-1 d-flex flex-column">
                                                            {
                                                                data.card_desc && data.card_desc.map((item,index) =>(
                                                                    <div className="d-flex col-md-11 align-items-baseline">
                                                                        <span style={{marginRight:'3%'}}><FontAwesomeIcon icon={faArrowRight} style={{ color: "#5CB6F2" }} /></span>
                                                                        <p className="our_service_card_desc"  dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                                                    </div>
                                                                   
                                                                ))
                                                            } 
                                                        </div>
                                                        <div className="  col-md-12 d-flex justify-content-end align-items-center">
                                                     {data.card_url &&    <Link style={{ marginRight: '2%', textDecoration: 'none' }} to={data.card_url}>
                                                                <p >Discover your possibilities</p></Link>}   
                                                                {data.card_url &&   <Link to={data.card_url} className=''>
                                                                <button class="btn card-btn-color"> <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} /></button></Link>}   
                                                        
                                                         </div>
                                                    </div>
                                                 
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {id === 4 && (
                            <div className="row d-flex justify-content-around mt-3 mb-3">
                                <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_fix">
                                    <div className=" ">
                                        <div className="">
                                            <p className="text-center fw-bold" style={{ fontSize: '25px' }}>{title}</p>
                                            {
                                                desc.map((data, id) => (
                                                    <p className='line-_height text-center'>{data.text}</p>
                                                ))
                                            }
                                        </div>
                                        <div className="">
                                            <div className="row d-flex justify-content-center whitebg_section Advisory_service">
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column  " style={{ padding: '16px' }}>
                                                    {<Accordion className='d-flex flex-column EcCoe'>
                                                        {accordians.map((item, index) => {
                                                            const { header, desc } = item;
                                                            return (
                                                                <Accordion.Item eventKey={index.toString()} className='mb-2 acoordian_items' key={index} style={{ border: 'none', background: '#E1EDFE' }} >
                                                                    <AccordionButton className='Dropdown-Bg-Color'
                                                                        onClick={() => handleToggle(index.toString())}
                                                                        aria-expanded={expandedItem === index.toString()}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                                            <span className='fw-bolder sm-font-size'>{header}</span>
                                                                            {expandedItem === index.toString() ? <GoHorizontalRule /> : <GoPlus className='PlusIcon' />}
                                                                        </div>
                                                                    </AccordionButton>

                                                                    <Accordion.Body key={index} className='py-1'>
                                                                        {
                                                                            desc && desc.map((item,index) =>(
                                                                                <p className='text-start'  dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                                                            ))
                                                                        } 
                                                                    </Accordion.Body>

                                                                </Accordion.Item>
                                                            );
                                                        })}
                                                    </Accordion>
                                                    }
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center dataManagement_Acrd">
                                                    <img src={require(`../../image/ERP/DataManagement/${bannerImage}.png`)} className=" accordian_img" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
            <GetStarted />
           
        </>
  )
}

export default DataManagement