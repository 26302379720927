import React from 'react'
import { Banner } from './Banner'
import TrustedOrganization from './TrustedOrganization'
import FooterBrands from './FooterBrands'
import Content from '../data/MobileApplication.json'
import { GetStarted } from './FooterSection/GetStarted'

export const MobileApplication = () => {
    return (
        <>
            <Banner />
            <TrustedOrganization />
            {
                Content.map((item) => {
                    const { Id, MainClassName, className, HeadingClassName, ContentClassName, ImgClassName, Heading, Img, Data } = item;

                    return (
                        <div className={MainClassName} key={Id}>
                            <div className={className}>
                                <div className="MobileContent row py-5">
                                    <div className={HeadingClassName}>
                                        <div className="col-md-8 mt-5 mb-5">
                                            <h3 className="Heading mb-3 fw-bolder">{Heading}</h3>
                                        </div>
                                    </div>

                                    <div className={ContentClassName}>
                                        {
                                            Data.map((item) => {
                                                const { Id, className, Name } = item;
                                                return (
                                                    <div className={className} key={Id}>
                                                        <p className="Description">{Name}</p>
                                                    </div>
                                                )
                                            })
                                        }


                                    </div>
                                    <div className={ImgClassName}>
                                        <div className="ImgSection">
                                            <img src={require(`../image/Mobile/${Img}`)} alt={Heading} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            <FooterBrands />
            <GetStarted />
        </>
    )
}
