import React from 'react'
import backImage from '../../image/34992.jpg';

import mg1 from '../../image/partners page/2807.png';
import mg2 from '../../image/partners page/2151027607.jpg';
import mg3 from '../../image/partners page/asset-1.jpg';
import { Link } from 'react-router-dom';
import HCM_AMS from '../HCM/HCM_AMS';

export const Guides = () => {
    return (
        <div className="row d-flex justify-content-around container-fluid mt-3 main-home mb-2">
            <div className="col-11">
                <div className="row d-flex justify-content-around CrdCarouselBody">

                    <div className="col-md-6 pe-4 mb-2">
                        <div className="CardCarousels">
                            <div id="carouselGuides" className="carousel carousel-white slide" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselGuides" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselGuides" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselGuides" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="h-10 position-absolute w-100 text-center mt-3 z-1 Heading">
                                        <h4 className="fw-bold Description-Fontsize text-white">Guides & White Papers</h4>
                                    </div>
                                    <div className="carousel-item active" data-bs-interval="10000">
                                        <img src={mg1} className="d-block w-100 h-100" alt="..." />
                                    </div>
                                    <div className="carousel-item" data-bs-interval="2000">
                                        <img src={mg2} className="d-block w-100 h-100" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={mg3} className="d-block w-100 h-100" alt="..." />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselGuides" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselGuides" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                        <div className="h-10 w-100 mt-5">
                            <h4 className="text-white fw-semibold">Tame Your Tech Stack With Application Management Services</h4>
                            {/* <Link to={'/hcm/hcm-ams'} className="btn read text-white">Read more</Link> */}
                        </div>
                    </div>
                    <div className="col-md-6  d-flex justify-content-center h-100">
                        <div className="row d-flex flex-column justify-content-evenly w-100 MainCardsSections" style={{ height: '100%' }}>
                            <div className="col-lg-9 col-md-9 col-sm-9 col-11 mb-4 rounded-4 card d-flex cards1 w-100 card-h" >
                                <div className="card-body pt-4 pb-5 ps-4 pe-4">
                                    <div className="d-flex flex-column justify-content-center ">
                                        <h4 className="card-title mb-3">Empowering Global Businesses with Innovative Solutions </h4>
                                        <p className="card-text fs-6 text-wrap ">Discover how Phenom assists our global clients in overcoming their most significant challenges, from digital transformation to sustainability initiatives. Our technology and expertise enable businesses to thrive.</p>
                                    </div>
                                    <div className="h-25">
                                        <a href="/" className="btnSlide"> <i className="fa-solid fa-arrow-right " style={{ color: "white" }}></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-9 col-11  rounded-4 card cards2 w-100">
                                <div className="card-body pt-4 pb-5 ps-4 pe-4">
                                    <div className="d-flex flex-column justify-content-center">
                                        <h4 className="card-title mb-3">More Information: </h4>
                                        <p className="card-text">Join us for our “Live Event” and learn how Phenom can revolutionize your business operations. Experience the power of Phenom firsthand at our live event. Learn more about our Customer Success Stories.</p>
                                    </div>
                                    <a href="/" className="btnSlide" > <i className="fa-solid fa-arrow-right " style={{ color: "white" }}></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="h-10 d-flex justify-content-center w-100 mt-4">
                        <Link to={'/aboutUs'} className="btn ResourcesBtn text-white">View All Resources</Link>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
