import React from "react";
import { Banner } from "./Banner";
import siteMapData from "../data/SiteMap.json";
import { Link } from "react-router-dom";

const SiteMap = () => {
  return (
    <>
      <Banner />
      <div className="siteMap_body container mt-5">
        <ul>
          <li style={{ listStyleType: "circle" , color: "#f37021"}}>
            <Link to={(siteMapData[0].Url) }>
              <h4 className="siteMap_body_header" style={{ color: "#f37021" }}>
                {siteMapData[0].Heading}
              </h4>
            </Link>
          </li>

          {siteMapData[0].Data.map((item, index) => (
            <ul key={index}>
              <li style={{ listStyleType: "square", color: "#f37021" }}>
                {item.Header}
                <div className="product_body_content row">
                  {item.ul.map((value, index2) => (
                    <div className="col-md-4" key={index2}>
                      {value.Header !== "" ? (
                        <li style={{ listStyleType: "circle" }}>
                          <Link to={value.Url !== "" ?value.Url:null}   style={{ color: "#000" }}>
                            {value.Header}
                          </Link>
                        </li>
                      ) : null}
                      <ul className={value.Header}>
                        {value.UL.map((value1, index3) => (
                          <li style={{ listStyleType: "square" }}>
                            <Link   to={value1.Url !== "" ?value1.Url:null} href="">{value1.li}</Link>
                            {value1.UL &&
                              value1.UL.map((value2, index4) => (
                                <ul>
                                  <li style={{ listStyleType: "square" }}>
                                    <Link  to={value2.Url !== "" ?value2.Url:null}  >{value2.li}</Link>
                                  </li>
                                </ul>
                              ))}
                          </li>
                        ))}
                      </ul>
                      {value.headers &&
                        value.headers.map((value3, index5) => (
                          <li style={{ listStyleType: "square", color: "#f37021" }}>
                            <Link  to={value3.Url !== "" ?value3.Url:null} style={{color:'#f37021',textDecoration:'none'}}>{value3.li}</Link>
                          </li>
                        ))}
                    </div>
                  ))}
                </div>
              </li>
            </ul>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SiteMap;
