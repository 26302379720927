import React from 'react'
import { Banner } from '../../Banner'
import TrustedOrganization from '../../TrustedOrganization'
import FooterBrands from '../../FooterBrands'
import { GoChevronRight } from "react-icons/go";
import { Link } from 'react-router-dom';
import StaffAugmentedData from '../../../data/HCM-COE/StaffingAugmentation.json'
import '../../../style/SubHcmCoe.css'
import { FooterContact } from '../../FooterContact';
import { GetStarted } from '../../FooterSection/GetStarted';

const StaffingAugmentation = () => {
  return (
    <>
      <Banner />
      <TrustedOrganization />


      {
        StaffAugmentedData.map((data, index) => (
          index === 1 ? (
            <></>
          ) : (
            <div className={data.MainClassName}>
              {
                data.Cards.map((item, index) => (
                  <div className={`staffingAugmentation_cards ${item.className}`} key={index} style={{ padding: '0px', margin: '0px' }}>
                    <div className="container" style={{ padding: '3%' }}>


                      <h3 className='staffingAugmentation_Card_header text-center fw-bold'>{item.Name}</h3>
                      {
                        item.Desc.map((item2, index2) => (
                          <p key={index2} className={item.Desc_bg}>{item2.Name}</p>
                        ))
                      }

                      <div className="row justify-content-center" >
                        {
                          item.card.map((item1, index1) => (
                            <div className={`${item1.className}`}>
                              <img src={require(`../../../image/HCMLearning/${item1.Img}.png`)} height="50" width="50" alt="" />
                              <p className={`text-start mt-4 ${item1.CardHeardingClass}`}>{item1.Header}</p>
                              {
                                item1.Desc && (<p className='text-start mt-2'>{item1.Desc}</p>)
                              }
                            </div>
                          ))
                        }
                      </div>
                      {
                        item.subDesc && (
                          <div className="subDesc">
                            <p className='text-center mt-5'>{item.subDesc}</p>
                          </div>
                        )
                      }

                    </div>
                  </div>

                ))
              }
            </div>
          )

        ))
      }
      <FooterBrands />
      <GetStarted />
    </>
  )
}

export default StaffingAugmentation