import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Banner } from "../Banner";
import TrustedOrganization from "../TrustedOrganization";
import ProcessData from "../../data/ERP/ProcessAutomation.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Accordion, AccordionButton } from "react-bootstrap";
import { GoHorizontalRule, GoPlus } from "react-icons/go";
import { GetStarted } from "../FooterSection/GetStarted";

const ProcessAutomation = () => {

  const [expandedItem, setExpandedItem] = useState(null);

  const handleToggle = (eventKey) => {
    setExpandedItem(expandedItem === eventKey ? null : eventKey);
  };


  return (
    <>
      <Helmet>
        <title>Phenom eCloud Process Automation: RPA or BPA?</title>
        <meta
          name="description"
          content="Learn how to make the right decision about which process automation method will best satisfy your specific purpose."
        />
      </Helmet>
      <Banner />
      <TrustedOrganization />
      {ProcessData.map((item, index) => {
        const {
          id,
          title,
          desc,
          bannerImage,
          section,
          accordians,
          infotext,
          tabledata,
          grid,
          sub_title,
          card,
        } = item;
        return (
          <div className="" key={id}>
            {id == 1 ? (
              <div class="row d-flex justify-content-evenly py-4  mb-3 align-items-center    whitebg_section discover_possible">
                <div className="col-md-4 text-center col-sm-12 col-12">
                  <div>
                    <img
                      src={require(`../../image/ERP/ProcessAutomation/${bannerImage}.png`)}
                      className="img-fluid "
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-12">
                  {desc.map((data, index) => (
                    <div className="head_on_desc_body mb-3">
                      {data.Desc_header && (
                        <p
                          className="mb-3"
                          style={{
                            fontWeight: "bolder",
                            lineHeight: "20px",
                            fontSize: "27px",
                          }}
                        >
                          {data.Desc_header}
                        </p>
                      )}
                      {data.Desc &&
                        data.Desc.map((value, index1) => (
                          <div className="explore_service_section">
                            <div
                              className="d-flex align-items-center mb-2"
                              key={index}
                            >
                              <div>
                                <p
                                  className="normal-text-font"
                                  style={{
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                    fontSize: "16px",
                                  }}
                                >
                                  {value.text}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {id == 2 && (
              <div className="row d-flex justify-content-center align-items-center allcoe_service_card_bg  m-0">
                {title && (
                  <h3 className="text-center fw-bold hcm_offer_header mt-4">
                    {title}
                  </h3>
                )}
                {desc && (
                  <div className="title_desc  d-flex flex-column align-items-center">
                    {desc.map(
                      (value, items) =>
                        value && (
                          <p
                            key={items}
                            className="text-center service_desc mt-4"
                            style={{ width: "70%", fontSize: "16px" }}
                          >
                            {value.text}
                          </p>
                        )
                    )}
                  </div>
                )}
                <div className="row d-flex justify-content-center align-items-center our_service_body_content mt-3 mb-3">
                  {card.map((data, index) => {
                    return (
                      <div className="col-md-3 col-dm-12 m-3 our_service_card card d-flex flex-column card-bg-color justify-content-evenly p-3 processAutomationCards DataManagementCards">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="fw-bold">{data.card_name}</p>
                          <img
                            src={require(`../../image/ERP/ProcessAutomation/${data.icon}`)}
                            alt=""
                            srcset=""
                            className="icon-box"
                          />
                        </div>
                        <div className="card-body px-1 d-flex flex-column">
                          {data.card_desc &&
                            data.card_desc.map((item, index) => (
                              <div className="d-flex col-md-11 align-items-baseline">
                                <span style={{ marginRight: "3%" }}>
                                  <FontAwesomeIcon
                                    icon={faArrowRight}
                                    style={{ color: "#5CB6F2" }}
                                  />
                                </span>
                                <p
                                  className="our_service_card_desc"
                                  dangerouslySetInnerHTML={{
                                    __html: item.text,
                                  }}
                                ></p>
                              </div>
                            ))}
                        </div>
                        <div className="  col-md-12 d-flex justify-content-end align-items-center">
                          {data.card_url && (
                            <Link
                              style={{
                                marginRight: "2%",
                                textDecoration: "none",
                              }}
                              to={data.card_url}
                            >
                              <p>Discover your possibilities</p>
                            </Link>
                          )}
                          {data.card_url && (
                            <Link to={data.card_url} className="">
                              <button class="btn card-btn-color">
                                {" "}
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  style={{ color: "#ffffff" }}
                                />
                              </button>
                            </Link>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {id == 3 ? (
              <div class="row d-flex justify-content-evenly py-4    align-items-center   whitebg_section discover_possible reverse_flow Automation_benifit">
                <h4 className="  text-center fw-bold" style={{ fontSize: '27px' }}>{title}</h4>
                <div className="col-md-4 text-center col-sm-12 col-12">
                  <div>
                    <img
                      src={require(`../../image/ERP/ProcessAutomation/${bannerImage}.png`)}
                      className="img-fluid "
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-12">
                  {desc.map((data, index) => (
                    <div className="head_on_desc_body mb-3">
                      {data.Desc_header && (
                        <p
                          className="mb-3"
                          style={{
                            fontWeight: "bolder",
                            lineHeight: "20px",
                            fontSize: "27px",
                          }}
                        >
                          {data.Desc_header}
                        </p>
                      )}
                      {data.Desc &&
                        data.Desc.map((value, index1) => (
                          <div className="explore_service_section">
                            <div
                              className="d-flex align-items-center"
                              key={index}
                            >
                              <div>
                                <p className="normal-text-font" style={{ fontWeight: "600", lineHeight: "20px", fontSize: "16px", }} dangerouslySetInnerHTML={{ __html: value.text }}>
                                  {/* {value.text}{" "} */}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
            {
              id == 4 && (
                <div className="row d-flex justify-content-center align-items-center allcoe_service_card_bg m-0 ">
                  {title && <h3 className="text-center fw-bold hcm_offer_header mt-4">{title}</h3>}
                  {
                    section.map((sectionitem, index2) => (
                      index2 === 2 ? (<>
                        {sub_title && <h3 className="text-center fw-bold hcm_offer_header mt-4">{sub_title}</h3>}
                        {
                          sectionitem.desc && <> <div className="title_desc automation_conclusion d-flex flex-column align-items-center mb-3">
                            {
                              sectionitem.desc.map((value, items) => (
                                value && <p key={items} className="text-center mb-3 service_desc" style={{ width: '85%', fontSize: '16px' }}>{value.text}</p>
                              ))
                            }
                          </div> </>
                        }
                      </>) : (
                        sectionitem.desc && <> <div className="title_desc  d-flex flex-column align-items-center">
                          {
                            sectionitem.desc.map((value, items) => (
                              value && <p key={items} className="text-center Automation_service_desc" style={{ width: '70%', fontSize: '20px' }}>{value.text}</p>
                            ))
                          }
                        </div>

                          <div className="row d-flex justify-content-center align-items-center our_service_body_content   mb-3">
                            {

                              sectionitem.card.map((data, index) => {

                                return <div className={`col-md-3  our_service_card col-dm-12 m-3 card text-center erp-card-radius card-bg-color py-3 px-3 erp-card-background Description-Fontsize Automation-card-fix-height ${data.card_class}`}>

                                  <div className='cardImg text-start d-flex justify-content-between align-items-center'>

                                    <p className="fw-bold">{data.card_name}</p>
                                    <img src={require(`../../image/ERP/ProcessAutomation/${data.icon}`)} alt="" className='icon-box' />
                                  </div>
                                  <div className="card-body px-1 d-flex">

                                    <div className="onboarding_card_desc" >
                                      <p className="our_service_card_desc">{data.card_desc}</p></div>
                                  </div>
                                  {
                                    data.card_url && (
                                      <div className=" col-md-12 d-flex justify-content-end align-items-center">
                                        <Link style={{ marginRight: '2%', textDecoration: 'none' }} to={data.card_url}>
                                          <p >{data.card_btn}</p></Link>
                                        <Link to={data.card_url} >
                                          <button class="btn card-btn-color"> <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} /></button></Link>
                                      </div>
                                    )
                                  }
                                </div>

                              })

                            }
                          </div>
                        </>
                      )



                    ))
                  }



                </div>
              )
            }
          </div>
        );
      })}
      <GetStarted />
    </>
  );
};

export default ProcessAutomation;
