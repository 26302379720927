import React from "react";
import hcmcoe from "../../data/HCM/hcmResourceCenter.json";
import { Banner } from "../Banner";
import TrustedOrganization from "../TrustedOrganization";
import FooterBrands from "../FooterBrands";
import '../../style/HCMLearning.css'
import { Link } from "react-router-dom";
import { GetStarted } from "../FooterSection/GetStarted";

const HCMResourceCenter = () => {


    return (
        <>
            <div className="" style={{ overflowX: 'hidden' }}>
                <Banner />
                <TrustedOrganization />

                {hcmcoe.map((item) => {
                    const { id, title, desc, grid } = item;

                    return (
                        <div className="row" key={id}>
                            <div className="col-12">
                                {id === 1 && (
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 align-items-center d-flex flex-column">
                                        <div className="row d-flex justify-content-around mt-3 mb-1">
                                            <div className="col-lg-11 col-md-11 col-sm-11 col-11">
                                                <div className="d-flex flex-column align-items-center justify-content-around mt-3">
                                                    <div className="col-lg-12 col-md-12 col-sm-11 col-11 mb-2">
                                                        <h4 className="text-center fw-bold title-font" style={{ color: 'rgb(242,111,33)' }}>{title}</h4>
                                                        <div>
                                                            {desc.map((text, index) => (
                                                                <div className="text-center fs-5 title-font" key={index}>
                                                                    {text}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {grid && grid.map((gridItem) => {
                                            const { storyl } = gridItem;

                                            return (
                                                <div className="py-3" key={gridItem.tab}>
                                                    <div className="row d-flex align-items-center justify-content-around flex-row">
                                                        <div className="col-lg-11 col-md-11 col-sm-11 col-12 mt-3">
                                                            <div className="row  flex-row" style={{ margin: "0PX", padding: "0PX" }}>
                                                                {storyl && storyl.map((story, index) => {
                                                                    return (
                                                                        <div className="d-flex row justify-content-around" style={{ margin: "0PX", padding: "0PX" }} key={index}>
                                                                            {story.servicegrid.map((item, index) => {
                                                                                const { id, icon, title, desc, btn, url } = item;
                                                                                return (
                                                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 text-center mt-3" key={id}>
                                                                                        <div className="d-flex flex-column py-2 px-2 h-100 card card-radius card-box-fade-shadow">
                                                                                            <div className="d-flex justify-content-around">
                                                                                                <img src={require(`../../image/HCM Coe/HCMResourceCenter/${item.icon}.png`)} className="img-fluid" alt="HCM" />
                                                                                            </div>
                                                                                            <div className="d-flex flex-column mt-2 justify-content-between h-100 align-items-center">
                                                                                                <div className="align-items-center">
                                                                                                    <p className="fw-bold title-font d-flex align-items-center justify-content-around">{title}</p>
                                                                                                    <p className="normal-text-font">{desc}</p>
                                                                                                </div>
                                                                                                <div className="mb-2">
                                                                                                    {btn && <Link to={url} className="btn HoverBtn fix-highr-btn overlaybtn text-white normal-text-font" rel="noreferrer noopener">{btn}</Link>}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
                <FooterBrands />
                <GetStarted />
            </div >
        </>
    );
}

export default HCMResourceCenter;