import React, { useState } from 'react'
import { Banner } from '../Banner'
import FooterBrands from '../FooterBrands';
import TrustedOrganization from '../TrustedOrganization';
import { Link } from 'react-router-dom';
import { GoChevronRight, GoHorizontalRule, GoPlus } from 'react-icons/go';
import { GetStarted } from '../FooterSection/GetStarted';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Accordion, AccordionButton } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

export const OurPartnersBrands = ({ adata }) => {

    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (eventKey) => {
        setExpandedItem(expandedItem === eventKey ? null : eventKey);
    };
  

    var Data = adata;
    return (
        <>
          <Helmet>
                <title>{adata[0].MetaTtile}</title>
                <meta name="description" content={adata[0].MetaDesc} />
         </Helmet>
            <Banner />
            <TrustedOrganization />
            <div className="OurPartners">
                {
                    Data.map((item) => {
                        const { Id, bottomText, headingClassName, className, parentClassName, subClassName, childClassName, Heading, Description, HeadingImg, Data, DataToggle, Btn, TabData, HeadingContent, Url , desc , bannerImage , card , title , accordians , tabledata , id} = item;
                        return (
                            <div className={headingClassName} key={Id}>
                                {
                                    headingClassName !== "ProjectServiceSection" && headingClassName !== "MoreInfoSection" && (
                                        <>
                                            {className && (
                                                <div className={className}>
                                                    <div className={parentClassName}>
                                                        <h3 className='mb-3'>{Heading}</h3>
                                                    </div>
                                                    {
                                                        Description && (
                                                            <div className={subClassName}>
                                                                <p className="Description line-_height" dangerouslySetInnerHTML={{ __html: Description }}></p>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        TabData && (
                                                            <div className='row justify-content-center mt-3'>
                                                                <ul className="list-group col-md-4 text-start mb-4">
                                                                    {
                                                                        TabData.map((item) => {
                                                                            const { Id, ActiveName, IdName, ToggleId, ariaSelected, Heading, Img } = item;
                                                                            return (
                                                                                <li className={`list-group-item ${ActiveName}`} id={IdName} data-bs-toggle="pill" data-bs-target={`#${ToggleId}`} type="button" role="tab" aria-controls={ToggleId} aria-selected={ariaSelected} key={Id}>
                                                                                    <img className='me-2' src={require(`../../image/CloudTransformation/CardIcon/${Img}`)} alt="" />
                                                                                    {Heading}
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                                <div className="tab-content text-start col-md-5 justify-content-center align-items-center row" id="NavTabSectionContent">
                                                                    {
                                                                        TabData.map((item, index) => {
                                                                            const { Id, ActiveName, IdName, ToggleId, NavTabToggle } = item;
                                                                            return (
                                                                                <div className={`tab-pane fade ${ActiveName} w-100`} id={ToggleId} role="tabpanel" aria-labelledby={IdName} key={Id}>
                                                                                    {
                                                                                        NavTabToggle.map((item) => {
                                                                                            const { Id, Description, subDescription, childDescription, subChildDescription, Img, subImg, childImg, subChildImg } = item;
                                                                                            return (
                                                                                                <div key={Id}>
                                                                                                    {
                                                                                                        Description && (
                                                                                                            <div className="Content d-flex align-items-center py-2 mb-2" key={Id}>
                                                                                                                {
                                                                                                                    Img && (
                                                                                                                        <img className='me-2' src={require(`../../image/CloudTransformation/CardIcon/${Img}`)} alt="" />
                                                                                                                    )
                                                                                                                }
                                                                                                                <p>{Description}</p>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        subDescription && (
                                                                                                            <div className="Content d-flex align-items-center py-2 mb-2" key={Id}>
                                                                                                                {
                                                                                                                    subImg && (
                                                                                                                        <img className='me-2' src={require(`../../image/CloudTransformation/CardIcon/${subImg}`)} alt="" />
                                                                                                                    )
                                                                                                                }
                                                                                                                <p>{subDescription}</p>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        childDescription && (
                                                                                                            <div className="Content d-flex align-items-center py-2 mb-2" key={Id}>
                                                                                                                {
                                                                                                                    childImg && (
                                                                                                                        <img className='me-2' src={require(`../../image/CloudTransformation/CardIcon/${childImg}`)} alt="" />
                                                                                                                    )
                                                                                                                }
                                                                                                                <p>{childDescription}</p>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                    {
                                                                                                        subChildDescription && (
                                                                                                            <div className="Content d-flex align-items-center py-2 mb-2" key={Id}>
                                                                                                                {
                                                                                                                    subChildImg && (
                                                                                                                        <img className='me-2' src={require(`../../image/CloudTransformation/CardIcon/${subChildImg}`)} alt="" />
                                                                                                                    )
                                                                                                                }
                                                                                                                <p>{subChildDescription}</p>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }

                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>)
                                                    }
                                                    {
                                                        HeadingContent && (
                                                            <div className='NavTabHeadingSection'>
                                                                {
                                                                    HeadingContent.map((item) => {
                                                                        const { Id, gridClass, Heading, Description } = item;
                                                                        return (
                                                                            <div key={Id} className='row justify-content-center mt-3'>
                                                                                {
                                                                                    Heading && (
                                                                                        <div className={gridClass}>
                                                                                            <h3 className='Heading'>{Heading}</h3>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    Description && (
                                                                                        <div className={gridClass}>
                                                                                            <p>{Description}</p>
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    <div className={childClassName}>
                                                        {
                                                            Data.map((item) => {
                                                                const { Id, Img, className, cardName, headingClassName, Title, Description } = item;
                                                                return (
                                                                    <div className={className} key={Id}>
                                                                        <div className={cardName}>
                                                                            <div className={`cardImg text-start d-flex align-items-center w-100 ${Title ? 'justify-content-between' : 'justify-content-center'}`}>
                                                                                {
                                                                                    Title && (
                                                                                        <p className={headingClassName}>{Title}</p>
                                                                                    )
                                                                                }
                                                                                <img className='mt-2' src={require(`../../image/CloudTransformation/${Img}`)} alt="" />
                                                                            </div>
                                                                            <div className="card-body px-1 d-flex flex-column text-start justify-content-center">
                                                                                <p className="Description m-0" dangerouslySetInnerHTML={{ __html: Description }}></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )
                                }
                                {
                                    DataToggle && (
                                        <>
                                            <div className="NavTabsSections pt-0">
                                                <ul className="nav nav-pills mb-3 d-flex justify-content-center" id="pills-tab" role="tablist">
                                                    {
                                                        DataToggle.map((item) => {
                                                            const { Id, ActiveName, IdName, ToggleId, ariaSelected, Heading, Img } = item;
                                                            return (
                                                                <li key={Id} className={`nav-item ${ActiveName}`} id={IdName} data-bs-toggle="pill" data-bs-target={`#${ToggleId}`} type="button" role="tab" aria-controls={ToggleId} aria-selected={ariaSelected} >
                                                                    <img src={require(`../../image/HCM Coe/${Img}`)} alt="" />
                                                                    <p className="BtnName">{Heading}</p>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                <div className="tab-content" id="CardsContent">
                                                    {
                                                        DataToggle.map((item) => {
                                                            const { Id, ToggleId, IdName, ActiveName, Data } = item;
                                                            return (
                                                                <div key={Id} className={`tab-pane fade ${ActiveName}`} id={ToggleId} role="tabpanel" aria-labelledby={IdName}>
                                                                    {
                                                                        Data.map((item) => {
                                                                            const { Id, className, HeadingCardClassName, Heading, Description, subDescription, subHeading, subHeadingDescription, CardData, Content, Staffing } = item;
                                                                            return (
                                                                                <div className={`${className} text-center d-flex flex-column align-items-center mt-5`} key={Id}>


                                                                                    <h2 className='Heading mb-4'>{Heading}</h2>
                                                                                    <p className="Description mb-4">{Description}</p>
                                                                                    {
                                                                                        subHeading && (
                                                                                            <h4 className='SubDescription fw-bold'>{subHeading}</h4>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        subDescription && (
                                                                                            <p className='HeadingSubDescription mb-4'>{subDescription}</p>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        subHeadingDescription && (
                                                                                            <h4 className='SubDescription mb-4 fw-bold'>{subHeadingDescription}</h4>
                                                                                        )
                                                                                    }
                                                                                    <div className={`${HeadingCardClassName}`}>
                                                                                        {
                                                                                            CardData.map((item) => {
                                                                                                const { Id, className, subClassName, topHeading, topDescripton, Heading, Description, FullDesc, subFullDesc, ContentDesc, subDescription, secSubDescription, subChildDescription, btnName, btnLink, Img,
                                                                                                    DescriptionImg, subDescriptionImg, secSubDescriptionImg, subChildDescriptionImg, imgGrid, cardGrid, cardClass } = item;
                                                                                                return (
                                                                                                    className !== "" ? (<div className={`${className}`} key={Id}>

                                                                                                        <div className={`${subClassName}`}>
                                                                                                            <div className={imgGrid}>
                                                                                                                <img className='' src={require(`../../image/CloudTransformation/${Img}`)} alt="" />
                                                                                                            </div>
                                                                                                            <div className={cardGrid}>
                                                                                                                <div className={`card-body ${cardClass}`}>
                                                                                                                    <h2 className='CardHeading mb-3'>{Heading}</h2>
                                                                                                                    {
                                                                                                                        FullDesc && (
                                                                                                                            <div className="d-flex mb-3 align-items-center">
                                                                                                                                <p className='CardDes'>{FullDesc}</p>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    {
                                                                                                                        Description && (
                                                                                                                            <div className="d-flex mb-3 align-items-center">
                                                                                                                                <p className='CardDes'>{Description}</p>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    <div className='SubDesc'>
                                                                                                                        {
                                                                                                                            btnName && (
                                                                                                                                <div className='BtnSection'>
                                                                                                                                    <Link to={btnLink} className='btn btn-dark'>{btnName}
                                                                                                                                        <i className="fas fa-angle-right"></i>
                                                                                                                                    </Link>

                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>


                                                                                                    </div>) : (
                                                                                                        <div className={`${subClassName}`}>
                                                                                                            {
                                                                                                                topHeading && (
                                                                                                                    <div className="TopHeadingSection row justify-content-center mb-4 text-center">
                                                                                                                        <div className="col-md-5 mb-3">
                                                                                                                            <h4>{topHeading}</h4>
                                                                                                                        </div>
                                                                                                                        <div className="col-md-10">{topDescripton}</div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                            <div className={imgGrid}>
                                                                                                                <img className='' src={require(`../../image/CloudTransformation/${Img}`)} alt="" />
                                                                                                            </div>
                                                                                                            <div className={cardGrid}>
                                                                                                                <div className={`card-body ${cardClass ? cardClass : ""}`}>
                                                                                                                    <h2 className='CardHeading mb-3'>{Heading}</h2>
                                                                                                                    {
                                                                                                                        FullDesc && (
                                                                                                                            <div className="d-flex mb-3 align-items-center">
                                                                                                                                <p className='CardDes'>{FullDesc}</p>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    {
                                                                                                                        subFullDesc && (
                                                                                                                            <div className="d-flex mb-3 align-items-center">
                                                                                                                                <p className='CardDes'>{subFullDesc}</p>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    {
                                                                                                                        Description && (
                                                                                                                            <div className="d-flex mb-3 align-items-center">
                                                                                                                                {
                                                                                                                                    DescriptionImg && (
                                                                                                                                        <p className='CardSideIcon'>
                                                                                                                                            <img className='pe-2' src={require(`../../image/CloudTransformation/CardIcon/${DescriptionImg}`)} alt="" />
                                                                                                                                        </p>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                <p className='CardDes' dangerouslySetInnerHTML={{ __html: Description }}></p>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    <div className='SubDesc'>
                                                                                                                        {
                                                                                                                            subDescription && (
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        subDescription && (
                                                                                                                                            <div className="d-flex mb-3 align-items-center">
                                                                                                                                                {subDescriptionImg && (
                                                                                                                                                    <p className='CardSideIcon'><img className='pe-2' src={require(`../../image/CloudTransformation/CardIcon/${subDescriptionImg}`)} alt="" /> </p>
                                                                                                                                                )}
                                                                                                                                                <p className='CardDescription'>{subDescription}</p>
                                                                                                                                            </div>
                                                                                                                                        )

                                                                                                                                    }
                                                                                                                                    {
                                                                                                                                        secSubDescription && (
                                                                                                                                            <div className="d-flex mb-3 align-items-center">
                                                                                                                                                {secSubDescriptionImg && (
                                                                                                                                                    <p className='CardSideIcon'><img className='pe-2' src={require(`../../image/CloudTransformation/CardIcon/${secSubDescriptionImg}`)} alt="" /> </p>
                                                                                                                                                )}
                                                                                                                                                <p className='CardDescription' >{secSubDescription}</p>
                                                                                                                                            </div>
                                                                                                                                        )

                                                                                                                                    }
                                                                                                                                    {
                                                                                                                                        subChildDescription && (
                                                                                                                                            <div className="d-flex mb-3 align-items-center mb-3 align-items-center">
                                                                                                                                                {subChildDescriptionImg && (
                                                                                                                                                    <p className='CardSideIcon'><img className='pe-2' src={require(`../../image/CloudTransformation/CardIcon/${subChildDescriptionImg}`)} alt="" /> </p>
                                                                                                                                                )}
                                                                                                                                                <p className='CardDescription' dangerouslySetInnerHTML={{ __html: subChildDescription }}></p>
                                                                                                                                            </div>
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    {
                                                                                                                                        ContentDesc && (
                                                                                                                                            <div className="d-flex mb-3 align-items-center">
                                                                                                                                                <p className='ContentDesc'>{ContentDesc}</p>
                                                                                                                                            </div>
                                                                                                                                        )
                                                                                                                                    }

                                                                                                                                </>
                                                                                                                            )
                                                                                                                        }
                                                                                                                        {
                                                                                                                            btnName && (
                                                                                                                                <div className='BtnSection'>
                                                                                                                                    <Link to={btnLink} className='btn btn-dark'>{btnName}
                                                                                                                                        <i className="fas fa-angle-right"></i>
                                                                                                                                    </Link>

                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )

                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                    {
                                                                                        Content && (
                                                                                            Content.map((item) => {
                                                                                                const { Id, className, parentClassName, childClassName, Title, Description, Data } = item;
                                                                                                return (
                                                                                                    <div className={className} key={Id}>
                                                                                                        <div className={parentClassName}>
                                                                                                            <div className="col-md-8">
                                                                                                                <h3 className="Heading mb-3 font-weight-bold">{Title}
                                                                                                                </h3>
                                                                                                            </div>

                                                                                                            <div className="col-md-10 mb-5">
                                                                                                                <p className="cardDescription">{Description}</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className={childClassName}>
                                                                                                            {Data.map((item) => {
                                                                                                                const { Id, Img, className, headingClassName, Title, Description, cardName } = item;
                                                                                                                return (
                                                                                                                    <div className={className} key={Id}>
                                                                                                                        <div className={cardName}>
                                                                                                                            <div className="cardImg text-start d-flex justify-content-between align-items-center w-100">
                                                                                                                                <h5 className={headingClassName}>{Title}</h5>
                                                                                                                                <img className='ImgContent' src={require(`../../image/CloudTransformation/${Img}`)} alt="" />
                                                                                                                            </div>
                                                                                                                            <div className='card-body px-1 d-flex flex-column text-start justify-content-center'>
                                                                                                                                <p className="cardDescription">{Description}</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })}

                                                                                                        </div>
                                                                                                        <div className={childClassName}>
                                                                                                            <div className="ImgSection">
                                                                                                                {
                                                                                                                    HeadingImg && (
                                                                                                                        <img src={require(`../../image/CloudTransformation/${HeadingImg}`)} alt="" />
                                                                                                                    )
                                                                                                                }
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })

                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        Staffing && (
                                                                                            Staffing.map((item) => {
                                                                                                const { Id, className, subClassName, classSection, secClassSection, Heading, HeadingImg, Description, Data } = item;
                                                                                                return (
                                                                                                    <div className={className} key={Id}>
                                                                                                        <div className={subClassName}>
                                                                                                            <div className={classSection}>
                                                                                                                <div className="col-md-12 mb-3">
                                                                                                                    <h4>{Heading}</h4>
                                                                                                                </div>
                                                                                                                <div className="col-md-4 mt-3 mb-3">
                                                                                                                    <img className='ImgSection' src={require(`../../image/CloudTransformation/${HeadingImg}`)} alt="" />
                                                                                                                </div>
                                                                                                                <div className="col-md-11 mb-3">
                                                                                                                    <p className='CardDescription'>{Description}</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className={secClassSection}>
                                                                                                                {
                                                                                                                    Data.map((item) => {
                                                                                                                        const { Id, className, gridClass, Heading, Img, Btn, Url } = item;
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                {
                                                                                                                                    Heading && (<div className={gridClass}>
                                                                                                                                        <div className={className} key={Id}>
                                                                                                                                            <div className="CardImg mt-4">
                                                                                                                                                {Img && (
                                                                                                                                                    <img src={require(`../../image/CloudTransformation/${Img}`)} alt="" />
                                                                                                                                                )}
                                                                                                                                            </div>
                                                                                                                                            <div className="card-body">
                                                                                                                                                <p className='CardDescription'>{Heading}</p>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>)
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    className === "BtnClass" && (
                                                                                                                                        <div className={gridClass}>
                                                                                                                                            <div className="SubDesc mt-4">
                                                                                                                                                <div className="CardBtn">
                                                                                                                                                    <Link to={Url} className="btn btn-dark">{Btn}<i className="fas fa-angle-right"></i></Link>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            )

                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )
                                }

                                {
                                    headingClassName === "ProjectServiceSection" && (
                                        <div className={className}>
                                            <div className={parentClassName}>
                                                <div className="col-md-8">
                                                    <h3 className="Heading mb-3  font-weight-bold">{Heading}
                                                    </h3>
                                                </div>

                                                <div className="col-md-11 mb-5">
                                                    <p className="CardDescription">{Description}</p>
                                                </div>
                                            </div>
                                            <div className={subClassName}>
                                                {Data.map((item) => {
                                                    const { Id, Img, className, Title, Description } = item;
                                                    return (
                                                        <div className={className} key={Id}>
                                                            <div>
                                                                {
                                                                    Img && (
                                                                        <img className='ImgContent' src={require(`../../image/CloudTransformation/${Img}`)} alt="" />
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='HeadingSection ms-3 d-flex align-items-center flex-column'>
                                                                {
                                                                    Title && (
                                                                        <h5 className='d-block w-100 text-start m-0'>{Title}</h5>
                                                                    )
                                                                }
                                                                <p className="line-_height m-0" dangerouslySetInnerHTML={{ __html: Description }}></p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </div>

                                            <div className={childClassName}>
                                                <div className="ImgSection">
                                                    {
                                                        HeadingImg && (
                                                            <img src={require(`../../image/CloudTransformation/${HeadingImg}`)} alt="" />
                                                        )
                                                    }
                                                </div>

                                            </div>
                                            <div className="bottom-text text-center mt-4">
                                                <p>{bottomText}</p>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    headingClassName === "MoreInfoSection" && (
                                        <div className={className}>
                                            <div className={parentClassName}>
                                                <p className="InfoSectionDescription">{Description}</p>
                                                <Link to={Url} className='btn btn-dark HoverBtn'>
                                                    {Btn}
                                                    <GoChevronRight />
                                                </Link>

                                            </div>
                                            <div className={subClassName}>
                                                <div className="ImgSection">
                                                    <img src={require(`../../image/CloudTransformation/${HeadingImg}`)} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    headingClassName === "FirstSection" && (
                                        <div class="row d-flex justify-content-evenly py-4  mb-3 align-items-center  erp_future_analytic  whitebg_section discover_possible">
                                        <div className="col-md-4 text-center col-sm-12 col-12">
                                            <div>
                                                <img src={require(`../../image/OurPartners/CornerstoneSbx/${bannerImage}.png`)} className="img-fluid " />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            {
                                                desc.map((data, index) => (
                                                    <div className="head_on_desc_body mb-3">
                                                        {data.Desc_header && <p className="mb-3" style={{ fontWeight: 'bolder', lineHeight: '23px', fontSize: '27px' }}>{data.Desc_header}</p>}
                                                        {data.Desc &&
                                                            data.Desc.map((value, index1) => (
                                                                <div className="explore_service_section">
                                                                    <div className="d-flex align-items-center mb-2" key={index}>
                                                                        <div>
                                                                            <p className="normal-text-font" style={{ fontWeight: '600', lineHeight: '20px', fontSize: '16px' }}>{value.text} </p>
                                                                            {
                                                                            value.ul &&  value.ul.map((value1, index2) => (
                                                                                <li className="normal-text-font"  dangerouslySetInnerHTML={{ __html: value1.li }}></li>
                                                                              ))
                                                                            }
                                                                        </div>
        
                                                                    </div>
                                                                </div>
        
                                                            ))
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
        
                                    </div>
                                    )
                                }
                                {
                                    headingClassName === "SecondSection" && (
                                        <div className={`row d-flex justify-content-center align-items-center  mb-3 ${id == 2 ?'allcoe_service_card_bg':''}`}>
                                        {title && <h3 className="text-center fw-bold hcm_offer_header mt-4">{title}</h3>}
                                       {desc && <div className="title_desc  d-flex flex-column align-items-center">
                                                        {
                                                            desc.map((value, items) => (
                                                                value && <p key={items} className="text-center service_desc" style={{ width: '70%', fontSize: '16px' }}>{value.text}</p>
                                                            ))
                                                        }
                                          </div>
                                        } 
                                        <div className="row d-flex justify-content-center align-items-center our_service_body_content mt-3 mb-3">
                                            {
                                                card.map((data, index) => {
                                                    
                                                    return <div className="col-md-5 col-dm-12 m-3 our_service_card card d-flex flex-column card-bg-color justify-content-evenly p-3 CornerstonesbxCards">
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <p className="fw-bold">{data.card_name}</p>
                                                            <img src={require(`../../image/OurPartners/CornerstoneSbx/${data.icon}`)} alt="" srcset="" className='icon-box' />
                                                        </div>
                                                        <div className="card-body px-1 d-flex flex-column justify-content-center">
                                                            {
                                                                data.card_desc && data.card_desc.map((item,index) =>(
                                                                    <div className="d-flex col-md-11 align-items-baseline">
                                                                        <span style={{marginRight:'3%'}}><FontAwesomeIcon icon={faArrowRight} style={{ color: "#5CB6F2" }} /></span>
                                                                        <p className="our_service_card_desc"  dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                                                    </div>
                                                                   
                                                                ))
                                                            } 
                                                        </div>
                                                        <div className="  col-md-12 d-flex justify-content-end align-items-center">
                                                     {data.card_url &&    <Link style={{ marginRight: '2%', textDecoration: 'none' }} to={data.card_url}>
                                                                <p >Discover your possibilities</p></Link>}   
                                                                {data.card_url &&   <Link to={data.card_url} className=''>
                                                                <button class="btn card-btn-color"> <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} /></button></Link>}   
                                                        
                                                         </div>
                                                    </div>
                                                 
                                            })
                                            }
                                        </div>
                                    </div>
                                    )
                                }
                                {
                                    headingClassName === "ThirdSection" && (
                                        <div className={`row d-flex justify-content-around mt-3 mb-3 ${ id == 6 ?'allcoe_service_card_bg py-3':''}`}>
                                        <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_fix">
                                            <div className=" ">
                                                <div className="">
                                                    <p className="text-center fw-bold" style={{ fontSize: '25px' }}>{title}</p>
                                                    {
                                                        desc.map((data, id) => (
                                                            <p className='line-_height text-center'>{data.text}</p>
                                                        ))
                                                    }
                                                </div>
                                                <div className=""> 
                                                      {
                                                        <div className="row d-flex justify-content-center whitebg_section Advisory_service">
                                                               <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column  " style={{ padding: '16px' }}>
                                                            {<Accordion className='d-flex flex-column EcCoe align-items-center'>
                                                                {accordians.map((item, index) => {
                                                                    const { header, desc } = item;
                                                                    return (
                                                                        <Accordion.Item eventKey={index.toString()} className='mb-2 acoordian_items' key={index} style={{ border: 'none', background: '#E1EDFE' }} >
                                                                            <AccordionButton className='Dropdown-Bg-Color'
                                                                                onClick={() => handleToggle(index.toString())}
                                                                                aria-expanded={expandedItem === index.toString()}
                                                                            >
                                                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                                                    <span className='fw-bolder sm-font-size'>{header}</span>
                                                                                    {expandedItem === index.toString() ? <GoHorizontalRule /> : <GoPlus className='PlusIcon' />}
                                                                                </div>
                                                                            </AccordionButton>
        
                                                                            <Accordion.Body key={index} className='py-1'>
                                                                                {
                                                                                    desc && desc.map((item,index) =>(
                                                                                        <p className='text-start'  dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                                                                    ))
                                                                                } 
                                                                            </Accordion.Body>
        
                                                                        </Accordion.Item>
                                                                    );
                                                                })}
                                                            </Accordion>
                                                            }
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center CornerstoneSbx_workflow_img">
                                                                     <img src={require(`../../image/OurPartners/CornerstoneSbx/${bannerImage}.png`)} className=" accordian_img" />
                                                                 </div>
                                                        </div> 
                                                      } 
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                }
                                {
                                    headingClassName === "FourthSection" && (
                                        <div className="row d-flex justify-content-around mb-3 allcoe_service_card_bg text-white py-4">
                              
                                        <div className="col-12">
                                            {tabledata.map((item, index) => {
                                                const { id, image1, head, text, ul, CTA, btntext, btn1, btn2, Url1, Url2 } = item;
                                                const rowColor = index % 2 === 0 ? ' ' : 'bg-white small_screen_col_reverse ';
                                                const isEven = index % 2 === 0;
        
                                                return (
                                                    <div className={`col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end align-items-center  ${rowColor}`} key={id}>
                                                        <div className="row d-flex flex-row align-items-center justify-content-around p-3 erp_application">
                                                            <div className="col-lg-10 col-md-10 col-sm-11 col-12 flex-row align-items-center">
                                                                <div className={`row flex-row align-items-center card-content ipad_col_rev erp_apm ${rowColor}`}>
                                                                    {isEven ? (
                                                                        <>
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column  box-h2" style={{ color: "black" }}>
                                                                                <div>
                                                                                    <p className="fw-bold fs-4" style={{ color: 'rgb(242,111,33)' }} >{head}</p>
                                                                                    {text.map((paragraph, idx) => (
                                                                                        <p className={`normal-text-font  text-white ${idx == 2 ? 'fw-bold':'mb-3'}`} key={idx}>{paragraph}</p>
                                                                                    ))}
                                                                                    
                                                                                    {
                                                                                        ul.map((list,index3) =>(
                                                                                            <li className="normal-text-font text-white">{list.li}</li>
                                                                                        ))
                                                                                    } 
                                                                                   <div className="ams_contact mt-3">
                                                                                    <p className='text-white' dangerouslySetInnerHTML={{ __html: CTA.text }}></p>
                                                                                    <div className='d-flex'>
                                                                                    {CTA.btn1_Url && <a href={CTA.btn1_Url} target="_blank" className="btn HoverBtn" >{CTA.btn1}</a> }
                                                                                        <a href={CTA.btn2_url} target="_blank" className="btn HoverBtn ms-3" >{CTA.btn2}</a>
                                                                                    </div>
                                                                                   </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column align-items-end mb-3">
                                                                                <img src={require(`../../image/OurPartners/CornerstoneSbx/${image1}.png`)} alt="HCM" className="img-fluid erp_stack_img_height" />
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <> 
                                                                        </>
                                                                    )}
        
                                                                </div>
                                                            </div>
        
                                                        </div>
                                                    </div>
                                                );
                                            })}
        
                                        </div>
                                    </div>
                                    )
                                }

                            </div>
                        )
                    })
                }
            </div >
            <FooterBrands />
            <GetStarted />
        </>
    )
}
