
import React, { useState } from "react";
import hcmcoe from '../../data/HCM/peopleAnalytics.json';
import { Banner } from "../Banner";
import FooterBrands from "../FooterBrands";
import { GetStarted } from "../FooterSection/GetStarted";
import { Helmet } from 'react-helmet';


const PeopleAnalytics = () => {

    const [activeTab, setActiveTab] = useState("tab1");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            <Helmet>
                <title>People-Centered People analytics Implementation, Optimization, and Support</title>
                <meta name="description" content="Phenom eCloud HCM Center of Excellence provides people analytics implementation, testing, dashboards, and optimization." />
            </Helmet>
            <Banner />
            <div className="" style={{ overflowX: 'hidden' }}>
                {hcmcoe.map((item) => {
                    const { id, title, desc, bannerImage, infotext, grid, card } = item;
                    return (
                        <div className="" key={id}>
                            {id === 1 && (
                                <div className="row d-flex justify-content-around mt-5 mb-5">
                                    <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_remove padding_fix">
                                        <div className=" ">
                                            <div className="">
                                                <h2 className="text-center fw-bold fs-2 title-font" style={{ color: 'rgb(242,111,33)' }}>{title}</h2>
                                                <div>
                                                    <p className="text-center title-font">{desc}</p>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="row ">
                                                    <div className="col-lg-4 col-md-12 col-sm-12 col-12 img-h text-center">
                                                        <img src={require(`../../image/HCM Coe/PeopleAnalytics/${bannerImage}.png`)} alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="col-lg-8 col-md-12 col-sm-12 col-12 d-flex flex-column justify-content-evenly" style={{ padding: '16px' }}>
                                                        {infotext.map((paragraph, index) => (
                                                            <p className="fw-normal mb-3 normal-text-font" key={index}>{paragraph}</p>
                                                        ))}

                                                        {card && (
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                                    <div className="card card-radius card-box-fade-shadow" style={{ padding: "4%", borderRadius: "14px" }}>
                                                                        <div className="card-body">
                                                                            <div className="d-flex  flex-column mb-2 ">
                                                                                <div className="d-flex flex-row mb-3 align-items-center">
                                                                                    <div>
                                                                                        <img src={require(`../../image/HCM Coe/Learning/${card.icon}.png`)} alt={card.icon} />
                                                                                    </div>
                                                                                    <div style={{ marginLeft: '10px' }}>
                                                                                        <p className="normal-text-font fw-bold">{card.title}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ marginLeft: '10px', textAlign: "center" }}>
                                                                                    <a href={card.url} className="normal-text-font " style={{ textDecoration: "none", fontWeight: "600", color: "#434693" }}>{card.desc}</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )}
                            {id === 2 && (
                                <div className="row d-flex justify-content-around mt-2 card_section_bg py-4">
                                    <div className="col-11 d-flex justify-content-around flex-column px-5 padding_remove">
                                        <div className="row d-flex align-items-center justify-content-between">
                                            {grid && grid.map((gridItem) => {
                                                const { position, icon, title, tab } = gridItem;
                                                return (
                                                    <div className="col-lg-3 col-md-6 col-sm-12 col-12 mt-3" key={position}>
                                                        <div
                                                            className={`tab d-flex align-items-center flex-column talent_management_service_btn service-button ${activeTab === tab ? 'active-tab service_btn_border_color' : ''}`}
                                                            onClick={() => handleTabClick(tab)}
                                                            style={{ backgroundColor: activeTab === tab ? 'rgb(255 255 255)' : '', color: activeTab === tab ? '#000' : '', width: '95%' }}
                                                        >
                                                            <div className="mt-2 img-hh" style={{ filter: activeTab === tab ? '' : 'brightness(0) invert(1)' }}>
                                                                <img src={require(`../../image/HCM Coe/PeopleAnalytics/${icon}.png`)} className="img-fluid" alt={icon} />
                                                            </div>
                                                            <div className="mb-2 button-text">
                                                                {title}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="row ">
                                            {grid && grid.map((gridItem) => {
                                                const { position, tab, storyl } = gridItem;
                                                return (
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12" key={position}>
                                                        {activeTab === tab && storyl && storyl.map((story) => {
                                                            const { id, servicegrid } = story;
                                                            return (
                                                                <div className="py-3 text-center" key={id}>
                                                                    <div className="row d-flex align-items-center flex-column">
                                                                        <div className="col-lg-12 col-md-12 col-sm-11 col-11 mt-3">
                                                                            <h4 className="fw-bold  Pcolor title-font">{story.title}</h4>
                                                                            <p className="  pb-3 normal-text-font line-_height">{story.subject}</p>
                                                                            <h4 className="fw-bold  Pcolor text-center title-font">{story.subtitle}</h4>
                                                                        </div>
                                                                        <div className="col-12 padding_fix">
                                                                            <div className="row justify-content-center">
                                                                                {servicegrid && servicegrid.map(service => {
                                                                                    return (
                                                                                        <div className={`col-12 col-md-6 col-sm-6  ${service.position === "CTA" ? 'col-lg-8' : 'col-lg-4 TwoCardWidth'} mt-3`} key={service.position}>
                                                                                            <div className={`d-flex ${service.className} ${service.position === "CTA" ? 'flex-row-reverse' : 'flex-column'}card text-center erp-card-radius card-bg-color erp-card-background Description-Fontsize p-4 h-100 our_service_card  obj-backward card  w-box w-100`}>
                                                                                                <div className={`d-flex justify-content-around ${service.position === "CTA" ? 'align-items-center' : 'null'}`} style={{ width: service.position === "CTA" ? '25%' : '100%' }}>
                                                                                                    <img src={require(`../../image/HCM Coe/PeopleAnalytics/${service.icon}.png`)} alt={service.icon} height="25" className="img-fluid height-img" />
                                                                                                </div>
                                                                                                <div className="d-flex flex-column justify-content-between text-start h-100">
                                                                                                    <div style={{ width: service.position === "CTA" ? '75%' : '100%' }}>
                                                                                                        <p className=" fw-bold title-font">{service.title}</p>
                                                                                                        <p className="normal-text-font" dangerouslySetInnerHTML={{ __html: service.desc }} />
                                                                                                    </div>

                                                                                                    {service.btn && <div> <a href={service.url} target="_blank" className="btn button-hover overlaybtn text-white normal-text-font" rel="noreferrer noopener">{service.btn}</a> </div>}


                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            })}


                                        </div>
                                    </div>

                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            <FooterBrands />
            <GetStarted />
        </>
    );
}

export default PeopleAnalytics;

