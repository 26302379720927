import React, { useState } from 'react';
import searchData from '../data/data.json'; // Import your JSON data
import { Link } from 'react-router-dom'; // For navigation
import '../style/search.css'
import SearchPopup from './SearchPopup';
import searchIcon from '../image/HeadingImg/Search.png'


const SearchComponent = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const [isInputBoxOpen, setIsInputBoxOpen] = useState(false);

  const handleSearchClick = () => {
    setIsInputBoxOpen(true);
  };
  const handleCloseClick = () => {
    setIsInputBoxOpen(false);
    setShowPopup(false);
    setQuery('');
    setResults([]);
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setQuery(searchQuery);

    if (!searchQuery) {
      setResults([]);
      setShowPopup(false);
      return;
    }

    const filteredResults = Object.keys(searchData)
      .filter(key => {
        const data = searchData[key];
        const description = data.description ? data.description.toLowerCase() : '';
        const title = data.title ? data.title.toLowerCase() : '';
        const content = data.content ? data.content.toLowerCase() : '';
        return description.includes(searchQuery) || title.includes(searchQuery);
      })
      .map(key => ({
        path: searchData[key].path,
        title: searchData[key].title,
        description: searchData[key].description,
        content: searchData[key].content,
      }));

    setResults(filteredResults);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className={`SearchBox p-2 col-md-6 d-flex align-items-center  ${isInputBoxOpen ? 'open' : ''}`}>
      <input type="text" value={query} onChange={handleSearch} placeholder="Search..." />
      <span className='search' onClick={handleSearchClick}>
        <img className='searchIcon' src={searchIcon} alt="" />
      </span>
      <i className="fa-solid fa-xmark close-icon" onClick={handleCloseClick}></i>
      {showPopup && (
        <SearchPopup results={results} query={query} onClose={handleClosePopup} />
      )}
    </div>
  );
};

export default SearchComponent;
