import React from 'react'
import { Banner } from '../../Banner'
import TrustedOrganization from '../../TrustedOrganization'
import FooterBrands from '../../FooterBrands'
import AdminResourceData from '../../../data/HCMLearning/AdminResource.json'
import { GoChevronRight } from "react-icons/go";
import { Link } from 'react-router-dom';
import { FooterContact } from '../../FooterContact'
import { GetStarted } from '../../FooterSection/GetStarted'

const AdminResource = ({ adata }) => {
  const AdminResourceData = adata;
  return (
    <>
      <Banner />
      <TrustedOrganization />

      <div className={AdminResourceData[0].MainClassName}>
        {
          AdminResourceData[0].Cards.map((item, index) => (
            <div className={`Content_resource_cards ${item.className}`} key={index} style={{ padding: '0px', margin: '0px' }}>
              <div className="container" style={{ padding: '3%' }}>


                <h3 className='Content_Card_header text-center fw-bold'>{item.Name}</h3>
                {
                  item.Desc.map((item2, index2) => (
                    <p className={item.Desc_bg} key={index2}>{item2.Name}</p>
                  ))
                }

                <div className="row justify-content-around position-relative">
                  {
                    item.card.map((item1, index1) => (
                      <>
                        {
                          (item1.Dot1 || item1.Dot2) && (
                            <>
                              <p className='Card-Dot1'>{item1.Dot1}</p>
                              <p className='Card-Dot2'>{item1.Dot2}</p>
                            </>
                          )
                        }
                        <div className={`${item1.className}`} key={index1}>
                          <div className="cardImg text-start d-flex justify-content-between align-items-center w-100">
                            <p className='text-start fw-bold'>{item1.Header}</p>
                            <img src={require(`../../../image/HCMLearning/${item1.Img}.png`)} height="50" width="50" alt="" />
                          </div>
                          <p className='text-start mt-2'>{item1.Desc}</p>
                        </div>
                      </>
                    ))
                  }
                </div>
              </div>
            </div>

          ))
        }
      </div>
      {/* <FooterContact /> */}
      <FooterBrands />
      <GetStarted />
    </>
  )
}

export default AdminResource