import React from 'react'
import { Link } from 'react-router-dom'
import { ImLocation2 } from "react-icons/im";

export const Footer = () => {
    return (
        <div className="container footer_body">
            <div className="row justify-content-between my-3">
                <div className="col-lg-4 col-md-4 col-sm-12 footer_body_content">
                    <p className="fw-bold footerhead " style={{textAlign:'center !important'}}>Industries</p>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className="small normal-text-font w-50">
                            <a href=""> <p>Automotive</p></a>
                            <a href=""><p>Banking</p></a>
                            <a href=""> <p>Capital Markets</p></a>
                            <a href=""> <p>Education</p></a>
                            <a href=""> <p>Financial Services</p></a>
                            <a href=""> <p>Healthcare</p></a>

                        </div>
                        <div className='small normal-text-font w-50'>
                            <a href=""> <p>Technology</p></a>
                            <a href=""><p>Life Sciences</p></a>
                            <a href=""> <p>Manufacturing</p></a>
                            <a href=""> <p>Pharmaceutical</p></a>
                            <a href=""> <p>Public Sector</p></a>
                            <a href=""> <p>Retail</p></a>

                        </div>
                    </div>

                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 footer_body_content">
                    <p className="fw-bold footerhead ">Services</p>
                    <div className="small normal-text-font">
                        <Link to={'/enterprise-business/eb-coe-offers-to-you'}> <p>Enterprise Business</p></Link>
                        <Link to={'/enterprise-cloud/ec-coe-offers-to-you'}> <p>Enterprise Cloud </p></Link>
                        <Link to={'/enterprise-digital-transformation/edt-coe-offers-to-you'}> <p>Enterprise Digital Transformation</p></Link>
                        <Link to={'/hcm/hcm-coe'}> <p>Enterprise Human Capital Management</p></Link>
                        <Link to={'/enterprise-resource-planning/erp-coe-offers-to-you'}> <p>Enterprise Resource Planning</p></Link>
                    </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 footer_body_content">
                    <div>
                        <p className="fw-bold footerhead ">Who we are</p>
                        <div className="small normal-text-font">
                            <Link to={'/aboutUs'}><p>About us</p></Link>
                            <a href="https://blog.phenomecloud.com/"><p>Blogs</p></a>
                            <a href=""><p>Careers</p></a>
                            <a href=""> <p>Resources</p></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 footer_body_content" >
                    <p className="fw-bold footerhead  ">Headquarters:</p>
                    <a href="https://maps.app.goo.gl/xeYAkmovaPrjQG9T8" target="_blank" rel="noopener noreferrer" className="small Address"><ImLocation2 /> 1333 Corporate Dr, Suite #212, Irving, TX, USA.</a>
                    <a href="tel:+1 903-306-2430" className="ContactNumber small fw-normal text-decoration-none" target="_blank" rel="noopener noreferrer"><i className="fa-solid fa-phone me-2"></i> +1-903-306-2430</a>
                    <a href="mailto:info@phenomecloud.com" className="Email small siz fw-normal text-decoration-none" target="_blank" rel="noopener noreferrer"><i className="fa-solid fa-envelope me-2"></i> info@phenomecloud.com</a>
                </div>
            </div>
        </div>
    )
}
