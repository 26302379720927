
import React, { useState } from "react";
import hcmcoe from '../../data/HCM/talent_management.json';
import { Banner } from "../Banner";
import FooterBrands from "../FooterBrands";
import { Link } from "react-router-dom";
import TrustedOrganization from "../TrustedOrganization";
import { GetStarted } from "../FooterSection/GetStarted";
import { Helmet } from 'react-helmet';

const TalentManagement = () => {

    const [activeTab, setActiveTab] = useState("tab1");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="Talent_management_body" style={{ overflowX: 'hidden' }}>
             <Helmet>
                <title>Phenom eCloud Talent Management and Support</title>
                <meta name="description" content="Phenom eCloud HCM Center of Excellence guides you through making the right decisions in talent management technology implementation, maintenance, and upgrades." />
            </Helmet>
            <Banner />
            <TrustedOrganization />
            {hcmcoe.map((item) => {
                const { id, title, desc, bannerImage, infotext, grid, btn, btnLink } = item;
                return (
                    <div className="" key={id}>
                        {id === 2 && (
                            <div className="row d-flex justify-content-around py-4 talent_boost_body mt-3  ">
                                <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_remove talent_boost padding_fix">
                                    <div className="d-flex flex-column align-items-center justify-content-around mt-3 ">
                                        <div className="col-lg-8 col-md-8 col-sm-11 col-11">
                                            <h4 className="text-center fw-bolder  title-font">{title}</h4>
                                            <div>
                                                <p className="text-center fw-bold fs-5 title-font">{desc}</p>
                                            </div>
                                        </div>
                                        <div className="infotexts">
                                            <div className="row ">
                                                <div className="col-lg-4 col-md-3 col-sm-4 col-12 img-h ">
                                                    <img src={require(`../../image/HCM Coe/Talent/${bannerImage}.png`)} alt="" className="img-fluid img" />
                                                </div>
                                                <div className="col-lg-8 col-md-9 col-sm-8 col-12 d-flex flex-column justify-content-evenly" style={{ padding: '16px' }}>
                                                    {infotext.map((paragraph, index) => (
                                                        <p className="fw-bold normal-text-font" key={index}>{paragraph}</p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {
                            id === 5 || id === 6 ? (
                                <div className={`row d-flex justify-content-around py-4  mb-3 ${title}`}>
                                    <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_remove padding_fix">
                                        <div className="d-flex flex-column align-items-center justify-content-around mt-3 ">

                                            <div className="infotexts">
                                                <div className={`row ${title}`}>
                                                    <div className="col-lg-8 col-md-9 col-sm-8 col-12 d-flex flex-column justify-content-center" style={{ padding: '16px' }}>
                                                        {infotext.map((paragraph, index) => (
                                                            <p className="fw-bold normal-text-font mb-3" key={index}>{paragraph}</p>
                                                        ))}
                                                        {btn && <div> <a className="btn HoverBtn button-hover overlaybtn text-white normal-text-font" href={btnLink}>{btn}</a></div>}
                                                    </div>
                                                    <div className="col-lg-4 col-md-3 col-sm-4 col-12 img-h ">
                                                        <img src={require(`../../image/HCM Coe/Talent/${bannerImage}.png`)} alt="" className="img-fluid img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (<></>)
                        }
                        {id === 3 && (
                            <div className="row d-flex justify-content-around mt-2 card_section_bg">
                                <div className="col-12 d-flex justify-content-around flex-column ">
                                    <div className="row d-flex align-items-center justify-content-center py-4">
                                        <div className="col-md-11 row d-flex">
                                            {grid && grid.map((gridItem) => {
                                                const { position, icon, title, tab } = gridItem;
                                                return (
                                                    <div className="col-lg-3 d-flex justify-content-center col-md-6 col-sm-6  mt-3" key={position}>
                                                        <div
                                                            className={`tab d-flex align-items-center flex-column service-button talent_management_service_btn ${activeTab === tab ? 'active-tab service_btn_border_color' : ''}`}
                                                            onClick={() => handleTabClick(tab)}
                                                            style={{ backgroundColor: activeTab === tab ? 'rgb(255 255 255)' : '', color: activeTab === tab ? '#000' : '', width: '95%' }}
                                                        >
                                                            <div className="mt-2 img-hh mb-2" style={{ filter: activeTab === tab ? '' : 'brightness(0) invert(1)' }}>
                                                                <img src={require(`../../image/HCM Coe/${icon}.png`)} className="img-fluid" alt={icon} />
                                                            </div>
                                                            <div className="mb-2 ">
                                                                {title}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="row">
                                        {grid && grid.map((gridItem) => {
                                            const { position, tab, storyl } = gridItem;
                                            return (
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 " key={position}>
                                                    {activeTab === tab && storyl && storyl.map((story) => {
                                                        const { id, servicegrid } = story;
                                                        return (
                                                            <div className="py-3 text-center" key={id}>
                                                                <div className="row d-flex align-items-center flex-column ">
                                                                    <div className="col-lg-10 col-md-11 col-sm-11 col-11 mt-3">
                                                                        <h4 className="fw-bold  Pcolor title-font FGHGFH">{story.title}</h4>
                                                                        {
                                                                            story.subject.map((data, index) => (
                                                                                <p className="pb-3 normal-text-font line-_height " key={index}>{data.desc}</p>

                                                                            ))
                                                                        }
                                                                        <h4 className="fw-bold  Pcolor text-center title-font">{story.subtitle}</h4>
                                                                    </div>
                                                                    <div className="col-10">
                                                                        <div className="row justify-content-center">
                                                                            {servicegrid && servicegrid.map(service => {
                                                                                // const { icon } = service;
                                                                                return (
                                                                                    <div className={`col-12 col-md-6 col-sm-6 ${service.position === "CTA" ? 'col-lg-8 OneCardWidth' : 'col-lg-4 TwoCardWidth'} mt-3`} key={service.position}>
                                                                                        {
                                                                                            service.position === "CTA" ? (<div className={`card text-center erp-card-radius card-bg-color py-3 px-3 erp-card-background Description-Fontsize tm-card-fix-height ${service.position === "CTA" ? 'flex-row-reverse' : 'flex-column'}`}>
                                                                                                <div className="cardImg text-start">
                                                                                                    {
                                                                                                        service.title && (
                                                                                                            <p className='fw-bolder card-heading-size'>{service.title}</p>
                                                                                                        )
                                                                                                    }
                                                                                                    <div className="card-body px-1 d-flex flex-column text-start justify-content-center pb-0 pt-0">
                                                                                                        <div className="row align-items-center">
                                                                                                            <div className="row last-card-img-reverse">
                                                                                                                <div className="col-md-9 d-flex flex-column justify-content-center">
                                                                                                                    {
                                                                                                                        <p className="normal-text-font line-_height text-start mb-3" dangerouslySetInnerHTML={{ __html: service.desc }}></p>
                                                                                                                    }
                                                                                                                    {service.btn &&
                                                                                                                        <div className="mb-2 get_ebbok_btn" style={{ width: service.position === "CTA" ? '75%' : '100%' }}>
                                                                                                                            <Link to={service.url} className="btn HoverBtn overlaybtn text-white normal-text-font" rel="noreferrer noopener">{service.btn}</Link>
                                                                                                                        </div>}
                                                                                                                </div>
                                                                                                                <div className="col-md-3">
                                                                                                                    {service.icon && (
                                                                                                                        <div className="d-flex justify-content-center">
                                                                                                                            <img className='last-card-iconimg float-end' src={require(`../../image/HCM Coe/Talent/${service.icon}`)} alt={service.icon} />
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div></div>
                                                                                            ) : (<div className={`card text-center erp-card-radius card-bg-color py-3 px-3 erp-card-background Description-Fontsize tm-card-fix-height ${service.position === "CTA" ? 'flex-row-reverse' : 'flex-column'}`} style={{ width: service.position === "CTA" ? '97%' : '97%' }}>
                                                                                                <div className={`cardImg text-start d-flex justify-content-between align-items-center ${service.position === "CTA" ? 'align-items-center' : 'null'}`} style={{ width: service.position === "CTA" ? '25%' : '100%' }}>
                                                                                                    <p className=" fw-bold title-font p-1  m-0 title_font_size">{service.title}</p>
                                                                                                    <img src={require(`../../image/HCM Coe/Talent/${service.icon}`)} alt={service.icon} height="25" className="img-fluid height-img" />
                                                                                                </div>
                                                                                                <div className="card-body px-1 d-flex">
                                                                                                    <div className="d-flex justify-content-center align-items-center" style={{ width: service.position === "CTA" ? '75%' : '100%' }}>
                                                                                                        <p className="normal-text-font line-_height text-start">{service.desc}</p>
                                                                                                    </div>
                                                                                                    {service.btn &&
                                                                                                        <div className="mb-2 get_ebbok_btn" style={{ width: service.position === "CTA" ? '75%' : '100%' }}>
                                                                                                            <Link to={service.url} className="btn HoverBtn overlaybtn text-white normal-text-font" rel="noreferrer noopener">{service.btn}</Link>
                                                                                                        </div>}
                                                                                                </div>
                                                                                            </div>)}
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}


                                    </div>
                                </div>

                            </div>
                        )}
                    </div>
                );
            })}
            <FooterBrands />
            <GetStarted />
        </div>
    );
}

export default TalentManagement;

