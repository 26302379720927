import React from "react";
import { Banner } from "./Banner";
import TrustedOrganization from "./TrustedOrganization";
import "../style/DigitalTransformation.css";
import digitalData from "../data/DigitalTransformation.json";
import { Link } from "react-router-dom";
import { GetStarted } from "./FooterSection/GetStarted";

const DigitalTransformation = () => {
  return (
    <>
      <Banner />
      <TrustedOrganization />
      <div className="digitalTransformation">
        {digitalData.CardData.map((item, index) => (
          <div key={index} className={`template_width ${item.className}`}>
            <div className="row   align-items-center info_section">
              <div className="col-md-12 sub_head py-4">
                <h1 className={`text-center mb-3 font-weight-bold  ${item.HeaderClassName}`}>
                  {item.TopHeading}
                </h1>
                <div className="row">
                  <div className="col-md-8 offset-md-2 top_header_description_body">
                    {item.TopSubDescription.map((item1, index1) => (
                      <p className="text-center top_header_desc"> {item1.Desc}</p>
                    ))}
                  </div>
                </div>
                {
                  item.leftBodyContent.length === 0 && item.rightBodyContent.length === 0 ? (
                    <></>
                  ) : (
                    <div className="row workday_section my-5">
                      <div className="col-md-10 offset-md-1">
                        <div className={`row align-items-center  ${item.className}`}>
                          <div className="col-md-7">
                            <div className="py-2 px-5">
                              <div>
                                {item.leftBodyContent.map((item2, index2) => (
                                  <div className="pb-3 d-flex align-middle align-items-center">
                                    <span>
                                      <img src={item2.img !== "" ? require(`../image/DigitalTransformation/${item2.img}`) : ""} alt="" srcset="" />
                                    </span>
                                    <p className="px-2">{item2.Desc}</p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          {index === 0 ? (
                            <div className="col-md-5 d-flex justify-content-center">
                              <div className="py-4 px-3 right_box">
                                <div>
                                  <div className="d-flex mb-3">
                                    <div>
                                      <img width='50' src={item.rightBodyContent[0].img !== "" ? require(`../image/DigitalTransformation/${item.rightBodyContent[0].img}`) : ""} alt="" />
                                    </div>
                                    <div className="text-center">
                                      <p className="font-weight-bold mb-0">
                                        {item.rightBodyContent[0].Desc}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="hcm_link text-center">
                                    <Link style={{ color: "#434690" }} className="text-decoration-none fw-bold" to={"/hcm/hcm-coe"}>
                                      {item.rightBodyContent[0].link}
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-5  d-flex justify-content-center">
                              <div className="ImgSection">
                                <img src={item.rightBodyContent[0].img !== "" ? require(`../image/DigitalTransformation/${item.rightBodyContent[0].img}`) : ""} alt="" />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                }


                <div className="row pt-5">
                  <div className="col-md-12">
                    {item.buttomContent[0].img === "" ? (
                      <div className="text-center">
                        {item.buttomContent[0].header === "" ? (
                          ""
                        ) : (
                          <h3 className="font-weight-bold mb-2">
                            {item.buttomContent[0].header}
                          </h3>
                        )}
                        {item.buttomContent[0].desc === "" ? (
                          ""
                        ) : (
                          <p className="mb-0">{item.buttomContent[0].desc}</p>
                        )}
                      </div>
                    ) : (
                      <div className="col-md-8 offset-md-2 mb-5">
                        <div className="blue_section">
                          <div className="quote1">
                            <img width="35" src={item.buttomContent[0].img1 !== "" ? require(`../image/DigitalTransformation/${item.buttomContent[0].img1}`) : ""} alt="" />
                          </div>
                          <h3 className="mb-0">
                            {" "}
                            {item.buttomContent[0].header}{" "}
                          </h3>
                          <p className="mb-0 mt-4">
                            {item.buttomContent[0].desc}
                          </p>
                          <p className="mb-0">
                            {item.buttomContent[0].desc1}
                          </p>
                          <div className="quote2">
                            <img width="35" src={item.buttomContent[0].img2 !== "" ? require(`../image/DigitalTransformation/${item.buttomContent[0].img2}`) : ""} alt="" />
                          </div>
                        </div>

                      </div>

                    )}
                  </div>
                </div>
                <div className="row">
                  {
                    item.buttomContent[0].foter_img && (
                      <div className="text-center sub_botton_content">
                        <p>{item.buttomContent[0].foter_img_header}</p>
                        <img src={item.buttomContent[0].foter_img !== "" ? require(`../image/DigitalTransformation/${item.buttomContent[0].foter_img}`) : ""} />
                      </div>
                    )

                  }
                </div>

              </div>
            </div>
          </div>
        ))}
      </div>
      <GetStarted />
    </>
  );
};

export default DigitalTransformation;
