import React, { useEffect, useRef, useState } from 'react'
import img from '../image/PageNotFound/galaxy-background.jpg'
import Jupiter from '../image/PageNotFound/Jupiter.png'
import sun from '../image/PageNotFound/planet2.png'
import planet1 from '../image/PageNotFound/planet1.png'
import astronot from '../image/PageNotFound/astronot.png'
import { Link } from 'react-router-dom'

export const PageNotFoundError = () => {

    const [stars, setStars] = useState([]);

    useEffect(() => {
        const starCount = 100; // Adjust the number of stars here
        const generatedStars = Array.from({ length: starCount }).map((_, index) => ({
            id: index,
            left: Math.random() * 100, // Random left position (0-100%)
            top: Math.random() * 100, // Random top position (0-100%)
            duration: Math.random() * 3 + 2, // Random duration (between 2s to 5s)
        }));

        setStars(generatedStars);
    }, []);
    return (
        <>
            <div className="PageNotFound">
                <img className='galaxyBG' src={img} alt="" />
                <ul className="starBlink">
                    {stars.map((star) => (
                        <li
                            key={star.id}
                            style={{
                                left: `${star.left}vw`,
                                top: `${star.top}vh`,
                                animationDuration: `${star.duration}s`,
                            }}
                        />
                    ))}
                </ul>
                <section className="MainContent p-3 h-100">
                    <div className="NotFound h-100 d-flex flex-column justify-content-center align-items-center">
                        <h1 className='BigText m-0'>404</h1>
                        <h3 className='fw-bolder text-white mb-3'>Uh oh, Notting here</h3>
                        <Link to={'/'} className='btn HoverBtn'>Back To Home</Link>
                    </div>
                </section>
                <img className='movingPlanet' src={Jupiter} alt="Moving Planet" />
                <img className='planet2' src={sun} alt="Moving Planet" />
                <img className='planet1' src={planet1} alt="Moving Planet" />
            </div>
        </>
    )
}
