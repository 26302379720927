import React, { useEffect, useState } from 'react'
import { Banner } from '../Banner'
import TrustedOrganization from '../TrustedOrganization'
import { Link } from 'react-router-dom';
import Aos from 'aos';
import { GetStarted } from '../FooterSection/GetStarted';
import FooterBrands from '../FooterBrands';
import { Helmet } from 'react-helmet';

export const EB_COE = ({ adata }) => {
    const EBCOE = adata;
    useEffect(() => {
        Aos.init({ duration: 2000 })
    })

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const updateScreenSize = () => {
            setIsSmallScreen(window.innerWidth <= 1269);
        };

        window.addEventListener('resize', updateScreenSize);

        updateScreenSize();

        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, []);

    return (
        <>
           <Helmet>
                <title>Enterprise Business COE Services | Phenom eCloud</title>
                <meta name="description" content="Phenom eCloud's Enterprise Business Center of Excellence (COE) delivers customized solutions in strategy, operations, technology integration, and process optimization, driving efficiency and fueling business growth across industries." />
            </Helmet>
            <Banner />
            <TrustedOrganization />

            <div className="EbCoe">
                {
                    EBCOE.map((item, index) => {
                        const { className, ContentData } = item;
                        return (
                            className === "FlexContentBody" && (
                                <div className={`${className} p-5 py-0 border-padding-remove`} key={index}>
                                    {ContentData && (
                                        ContentData.map((item, index) => {
                                            const { className, Grid } = item;
                                            return (
                                                <div className={className} key={index}>
                                                    {
                                                        Grid.map((item, index) => {
                                                            const { className, TextData } = item;
                                                            return (
                                                                <div className={className} key={index}>
                                                                    {
                                                                        TextData.map((item, index) => {
                                                                            const { className, BtnClassName, Name, iconImg, Img, BtnUrl } = item;
                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    {
                                                                                        Name ? (
                                                                                            <div className="d-flex align-items-center" key={index} data-aos="linear    ">
                                                                                                {
                                                                                                    iconImg && (
                                                                                                        <div className="ImgSection">
                                                                                                            <img className='small-icon me-3' src={require(`../../image/EC/EC COE/${iconImg}`)} alt="" />
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                                {BtnUrl ? (
                                                                                                    <Link to={BtnUrl} className={className}>{Name}</Link>
                                                                                                ) : (
                                                                                                    <p className={className}>{Name}</p>
                                                                                                )}
                                                                                            </div>
                                                                                        ) : (
                                                                                            Img && (
                                                                                                <div className="d-flex justify-content-center" >
                                                                                                    <img className='img-size' src={require(`../../image/EB/EB COE/${Img}`)} alt="" />
                                                                                                </div>)
                                                                                        )
                                                                                    }

                                                                                </React.Fragment>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        }))
                                    }
                                </div>
                            )
                        )
                    })
                }
                {
                    EBCOE.map((item, index) => {
                        const { className, HeadingData, CardSection } = item;
                        return (
                            className === "OurServices" && (
                                <div className={`${className}`} key={index}>
                                    {
                                        HeadingData && (
                                            HeadingData.map((item, index) => {
                                                const { className, Grid, CardSection } = item;
                                                return (
                                                    <div className={className} key={index}>
                                                        <div className="Heading-Content justify-content-center text-center align-items-center row px-4 padding_remove">
                                                            {
                                                                Grid.map((item, index) => {
                                                                    const { GridName, className, Name } = item;
                                                                    return (
                                                                        <div className={GridName} key={index}>
                                                                            <p className={className} dangerouslySetInnerHTML={{ __html: Name }}></p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        {
                                                            CardSection && (
                                                                CardSection.map((item, index) => {
                                                                    const { className, CardData } = item;
                                                                    return (
                                                                        <div className={className} key={index}>
                                                                            <div className="card-section-content row px-5 justify-content-center text-center padding_remove inside-border-padding-remove">
                                                                                {
                                                                                    CardData.map((item, index) => {
                                                                                        const { GridName, className, ImgName, Heading, Name, UrlName, Url, DescriptionName, popUp, LastCard } = item;
                                                                                        return (
                                                                                            <div className={GridName} key={index} data-aos={`${isSmallScreen ? 'fade-up' : popUp}`}>
                                                                                                {LastCard !== "yes" ? (<div className={`${className}`}>
                                                                                                    <div className="cardImg text-start d-flex justify-content-between align-items-center">
                                                                                                        {
                                                                                                            Heading && (
                                                                                                                <p className='fw-bolder card-heading-size'>{Heading}</p>
                                                                                                            )
                                                                                                        }
                                                                                                        {ImgName && (
                                                                                                            <img className='icon-size icon-box mix-blend' src={require(`../../image/EB/EB COE/${ImgName}`)} alt="" />
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="card-body px-1 d-flex flex-column text-start justify-content-center">

                                                                                                        {
                                                                                                            Name && (
                                                                                                                <p className="Description text-start m-0">{Name}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    </div>
                                                                                                    {
                                                                                                        Url && (
                                                                                                            <div className="UrlSection px-1 d-flex align-items-center justify-content-end">
                                                                                                                <Link to={Url} className='text-start text-decoration-none UrlText px-2' href="">{UrlName}</Link>
                                                                                                                <Link className='btn card-btn-color' to={Url}><i className="fa-solid fa-arrow-right px-2 "></i></Link>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }

                                                                                                </div>) : (<div className={`${className}`}>
                                                                                                    <div className="cardImg text-start d-flex justify-content-between align-items-center">
                                                                                                        {
                                                                                                            Heading && (
                                                                                                                <p className='fw-bolder card-heading-size'>{Heading}</p>
                                                                                                            )
                                                                                                        }

                                                                                                    </div>
                                                                                                    <div className="card-body px-1 d-flex flex-column text-start justify-content-center pb-0 pt-0">
                                                                                                        <div className="row">
                                                                                                            <div className="row last-card-img-reverse">
                                                                                                                <div className="col-md-9 d-flex flex-column justify-content-center">
                                                                                                                    {
                                                                                                                        DescriptionName && (
                                                                                                                            DescriptionName.map((item, index) => {
                                                                                                                                const { className, BoldName, Name, BtnName, DescriptionName } = item;
                                                                                                                                return (
                                                                                                                                    // BtnName ? (<div><a className='btn HoverBtn text-white Description-Fontsize'> {BtnName}</a></div>) : (<p className={className} key={index}><strong>{BoldName}</strong> {Name}</p>)
                                                                                                                                    <React.Fragment key={index}>
                                                                                                                                        {Name && (
                                                                                                                                            <p className={className}><strong>{BoldName}</strong> {Name}</p>
                                                                                                                                        )}
                                                                                                                                    </React.Fragment>

                                                                                                                                )
                                                                                                                            })
                                                                                                                        )
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className="col-md-3">
                                                                                                                    {ImgName && (
                                                                                                                        <div className="d-flex justify-content-center">
                                                                                                                            <img className='last-card-iconimg float-end mix-blend' src={require(`../../image/EB/EB COE/${ImgName}`)} alt="" />
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-md-12">
                                                                                                                {
                                                                                                                    DescriptionName && (
                                                                                                                        DescriptionName.map((item, index) => {
                                                                                                                            const { className, BoldName, Url, BtnName, DescriptionName } = item;
                                                                                                                            return (
                                                                                                                                <React.Fragment key={index}>
                                                                                                                                    {
                                                                                                                                        DescriptionName && (
                                                                                                                                            <>
                                                                                                                                                <p className={className}><strong>{BoldName}</strong> {DescriptionName}</p>
                                                                                                                                                {
                                                                                                                                                    Url && (
                                                                                                                                                        <Link to={Url} className='btn HoverBtn text-white Description-Fontsize'> {BtnName}</Link>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            </>
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                </React.Fragment>

                                                                                                                            )
                                                                                                                        })
                                                                                                                    )
                                                                                                                }
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>)}


                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            )

                                                        }
                                                    </div>
                                                )
                                            })
                                        )
                                    }

                                </div>
                            )
                        )
                    })
                }
            </div>
            <FooterBrands />
            <GetStarted />
        </>
    )
}
