import React from 'react'
import { Banner } from '../../Banner'
import TrustedOrganization from '../../TrustedOrganization'
import { GoChevronRight } from "react-icons/go";
import { Link } from 'react-router-dom';
import AugmenetedData from '../../../data/HCMLearning/AugmentedReality.json'
import FooterBrands from '../../FooterBrands';
import { FooterContact } from '../../FooterContact';
import { GetStarted } from '../../FooterSection/GetStarted';

const AugmentedReality = ({ adata }) => {
  const AugmenetedData = adata;
  return (
    <>
      <Banner />
      <TrustedOrganization />
      <div className={AugmenetedData[0].MainClassName}>
        {
          AugmenetedData[0].Cards.map((item, index) => (
            <div className={`augmented_cards ${item.className}`} key={index} style={{ padding: '0px', margin: '0px' }}>
              <div className="container-fluid">


                <h3 className='Content_Card_header text-center fw-bold'>{item.Name}</h3>
                {
                  item.Desc.map((item2, index2) => (
                    <p className={item.Desc_bg}>{item2.Name}</p>
                  ))
                }

                <div className="row justify-content-center" >
                  {
                    item.card.map((item1, index1) => (
                      <div className={`${item1.className}`}>
                        <img src={require(`../../../image/HCMLearning/${item1.Img}.png`)} height="50" width="50" alt="" />
                        <p className='text-center mt-4'>{item1.Header}</p>
                        <p className='text-start mt-2'>{item1.Desc}</p>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

          ))
        }
      </div>
      {/* <FooterContact /> */}
      <FooterBrands />
      <GetStarted />
    </>
  )
}

export default AugmentedReality