import React, { useState } from "react";
import { Banner } from "../../Banner";
import TrustedOrganization from "../../TrustedOrganization";
import workspaceData from "../../../data//HCM-COE/HcmDeploymentWorkshops.json";
import "../../../style/SubHcmCoe.css";
import FooterBrands from "../../FooterBrands";
import { GetStarted } from "../../FooterSection/GetStarted";

const HcmDeploymentWorkshops = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Banner />
      <TrustedOrganization />

      {workspaceData.map((item) => {
        const { id, title, desc, bannerImage, infotext, grid } = item;
        return (
          <div className="" key={id}>
            {
              <div className="row d-flex justify-content-around mt-2 mb-3">
                <div className="col-11 d-flex justify-content-around flex-column">
                  <div className="row d-flex align-items-center justify-content-around">
                    {grid &&
                      grid.map((gridItem) => {
                        const { position, icon, title, tab, storyl } = gridItem;
                        return (
                          <div
                            className="col-lg-3 col-md-6 col-sm-6 col-6 mt-3"
                            key={position}
                          >
                            <div
                              className={`tab d-flex align-items-center flex-column service-button   ${activeTab === tab ? "active-tab" : ""
                                }`}
                              onClick={() => handleTabClick(tab)}
                              style={{
                                backgroundColor:
                                  activeTab === tab ? "rgb(242,111,33)" : "",
                                color: activeTab === tab ? "#FFFFFF" : "",
                              }}
                            >
                              <div className="mt-2 tab_icon_height">
                                <img
                                  src={require(`../../../image/HCM Coe/${icon}.png`)}
                                  className="img-fluid"
                                  width={50}
                                  alt={icon}
                                />
                              </div>
                              <div className="mb-2 button-text fw-bolder">
                                {title}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="row mt-5">
                  {grid &&
                    grid.map((gridItem) => {
                      const { position, tab, storyl } = gridItem;
                      return (
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-12"
                          key={position}
                        >
                          {activeTab === tab &&
                            storyl &&
                            storyl.map((story, index) => {
                              const { id, title, subtitle, servicegrid, url } =
                                story;

                              return (
                                <div
                                  className={`text-center ${story.className}`}
                                  key={id}
                                >
                                  <div className={`row d-flex align-items-center flex-column ${story.className
                                    }`}>
                                    {story.id === 411 ? (
                                      <>
                                        <div className={story.subClassName}>
                                          <div className="col-md-6 col-sm-12">
                                            <img
                                              src={require(`../../../image/HCMCOESub/${story.img}.png`)}
                                              alt={story.img}
                                              className="pb-3 blue-bg-img "
                                            />
                                          </div>
                                          <div className="col-md-6 col-sm-12 text-start">
                                            <h3 className="fw-bold title-font">
                                              {story.title}
                                            </h3>
                                            {story.subject.map(
                                              (value, index4) => (
                                                <p className="normal-text-font  " dangerouslySetInnerHTML={{ __html: value.desc }}>
                                                </p>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="col-lg-8 col-md-8 col-sm-10 col-11 mt-3">
                                          <h4 className="fw-bold  Pcolor title-font">
                                            {story.title}
                                          </h4>
                                          {story.subject.map(
                                            (value, index4) => (
                                              <p className="normal-text-font  " dangerouslySetInnerHTML={{ __html: value.desc }}>
                                              </p>
                                            )
                                          )}
                                        </div>
                                        <div className="col-12 d-flex justify-content-center">
                                          <div className="row justify-content-center container">
                                            {servicegrid &&
                                              servicegrid.map((service) => {
                                                const { icon, Parent_Class_Name } = service;
                                                return (
                                                  <div
                                                    className={`col-md-4 col-sm-6 workshop_card_body mt-4`}
                                                    key={service.position}
                                                  >
                                                    <div
                                                      className={`d-flex justify-content-evenly h-100 ${Parent_Class_Name} ${service.position ===
                                                        "CTA"
                                                        ? "flex-row-reverse"
                                                        : "flex-column"
                                                        } ${service.img_class !== ""
                                                          ? service.img_class
                                                          : ""
                                                        }  workshop_cards obj-backward card`}
                                                    >
                                                      <div
                                                        className={` ${service.img_class !==
                                                          ""
                                                          ? service.img_class
                                                          : ""
                                                          }  d-flex justify-content-around ${service.position ===
                                                            "CTA"
                                                            ? "align-items-center"
                                                            : "null"
                                                          }`}
                                                      >
                                                        <img
                                                          src={require(`../../../image/HCMCOESub/${service.icon}.png`)}
                                                          alt={service.icon}
                                                          height="25"
                                                          className="img-fluid height-img"
                                                        />
                                                      </div>
                                                      <div className="d-flex flex-column justify-content-between">
                                                        <div className="card_body_content">
                                                          <p className=" fw-bold title-font mt-3">
                                                            {service.title}
                                                          </p>
                                                          <p
                                                            className="normal-text-font"
                                                            title={service.desc}
                                                          >
                                                            {service.desc}
                                                          </p>
                                                        </div>
                                                        <div>
                                                          {service.btn && (
                                                            <a
                                                              href={service.url}
                                                              target="_blank"
                                                              className="btn button-hover overlaybtn text-white normal-text-font"
                                                              rel="noreferrer noopener"
                                                            >
                                                              {service.btn}
                                                            </a>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-10 col-11 mt-3">
                                          <p className=" mt-3 pt-3 normal-text-font">
                                            {story.subtitle}
                                          </p>
                                        </div>
                                      </>
                                    )}

                                    <div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                </div>
              </div>
            }
          </div>
        );
      })}

      <FooterBrands />
      <GetStarted />
    </>
  );
};

export default HcmDeploymentWorkshops;
