import React from 'react'
import { Banner } from '../../Banner'
import TrustedOrganization from '../../TrustedOrganization'
import UiDesignData from '../../../data/HCMLearning/UIDesign.json'
import { GoChevronRight } from "react-icons/go";
import { Link } from 'react-router-dom';
import FooterBrands from '../../FooterBrands';
import { FooterContact } from '../../FooterContact';
import { GetStarted } from '../../FooterSection/GetStarted';

const UIDesign = ({ adata }) => {
  const UiDesignData = adata;
  return (
    <>
      <Banner />
      <TrustedOrganization />

      {
        UiDesignData.map((data, index) => (
          index === 2 ? (
            <></>
          ) : (
            <div className={data.MainClassName}>
              {
                data.Cards.map((item, index) => (
                  <div className={`UiDesign_cards ${item.className}`} key={index} style={{ padding: '0px', margin: '0px' }}>
                    <div className="container" style={{ padding: '3%' }}>


                      <h3 className='UiDesign_Card_header text-center fw-bold'>{item.Name}</h3>
                      {
                        item.Desc.map((item2, index2) => (
                          <p key={index2} className={item.Desc_bg}>{item2.Name}</p>
                        ))
                      }

                      <div className="row justify-content-evenly" >
                        {
                          item.card.map((item1, index1) => (
                            <div className={`${item1.className}`}>
                              <img src={require(`../../../image/HCMLearning/${item1.Img}.png`)} height="50" width="50" alt="" />
                              <p className='text-center mt-4'>{item1.Header}</p>
                              <p className='text-start mt-2'>{item1.Desc}</p>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>

                ))
              }
            </div >
          )

        ))
      }
      <FooterBrands />
      <GetStarted />
    </>
  )
}

export default UIDesign