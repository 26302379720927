import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import BannerData from '../data/Banner.json'
export const SliderCarousel = () => {

    const boxesRef = useRef([]);
    const stageRef = useRef(null);

    const setTransformOrigin = (box, index, angle) => {
        if (window.matchMedia("(max-width: 1176px) and (min-width: 992px)").matches) {
            gsap.set(box, {
                rotationY: index * angle,
                transformOrigin: "50% 50% -170"
            });
        }
        else if (window.matchMedia("(max-width: 992px) and (min-width: 768px)").matches) {
            gsap.set(box, {
                rotationY: index * angle,
                transformOrigin: "50% 50% -130"
            });
        }
        else if (window.matchMedia("(max-width: 767px) and (min-width: 500px)").matches) {
            gsap.set(box, {
                rotationY: index * angle,
                transformOrigin: "50% 50% -150"
            });
        }
        else if (window.matchMedia("(max-width: 499px) and (min-width: 350px)").matches) {
            gsap.set(box, {
                rotationY: index * angle,
                transformOrigin: "50% 50% -110"
            });

        } else if (window.matchMedia("(max-width: 349px) and (min-width: 250px)").matches) {
            gsap.set(box, {
                rotationY: index * angle,
                transformOrigin: "50% 50% -90"
            });
        } else {
            gsap.set(box, {
                rotationY: index * angle,
                transformOrigin: "50% 50% -220"
            });
        }
    };

    useEffect(() => {
        const boxes = boxesRef.current;
        const stage = stageRef.current;
        const $nav = document.getElementById('nav');

        const angle = 360 / 4;

        gsap.set(stage, {
            perspective: 1100,
            transformStyle: "preserve-3d"
        });


        boxes.forEach((box, index) => {
            setTransformOrigin(box, index, angle);
            box.dataset.rotationY = index * angle;
        });


        const handleResize = () => {
            boxes.forEach((box, index) => {
                setTransformOrigin(box, index, angle);
            });
        };

        window.addEventListener('resize', handleResize);

        const handleNavigation = (event) => {
            const isPrev = event.target.id === "prev";
            const direction = isPrev ? -1 : 1;
            const angle = 360 / boxes.length;

            gsap.to(boxes, {
                duration: 1,
                rotationY: function (index, target) {
                    const currentRotationY = parseFloat(target.dataset.rotationY || 0);
                    const newRotationY = currentRotationY + direction * angle;
                    target.dataset.rotationY = newRotationY;
                    return newRotationY;
                },
                stagger: 0
            });
        };


        if ($nav) {
            $nav.addEventListener("click", handleNavigation);
        }

        return () => {
            if ($nav) {
                $nav.removeEventListener("click", handleNavigation);
            }
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="stage" ref={stageRef}>
            {
                BannerData.map((item) => {
                    const { ScrollImg } = item;
                    return (
                        ScrollImg && (
                            ScrollImg.map((item, index) => {
                                const { Id, Img } = item;
                                return (
                                    Img && (
                                        <div className="box" ref={(el) => boxesRef.current[Id] = el}>
                                            {
                                                Img && (
                                                    <img className='BannerImg' src={require(`../image/BannerImg/${Img}`)} alt="" />
                                                )
                                            }
                                        </div>
                                    )
                                )
                            }))
                    )
                })
            }
        </div>
    )
}
