import React from 'react'
import { Banner } from '../Banner'
import FooterBrands from '../FooterBrands';
import hcmams from '../../data/HCM/hcm_ams.json'
import TrustedOrganization from '../TrustedOrganization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { GetStarted } from '../FooterSection/GetStarted';
import { Helmet } from 'react-helmet';

const HCM_AMS = () => {
    return (
        <>
            <Helmet>
                <title>Application Management Services </title>
                <meta name="description" content="Maximize the potential of your HCM technology stack with our expert Application Management Services, designed to streamline operations and boost performance." />
            </Helmet>
            <Banner />
            <TrustedOrganization />
            {hcmams.map((item, index) => {
                const { id, title, desc, bannerImage, infotext, grid, sub_title, card } = item;
                return (
                    <div className="" key={id}>
                        {
                            index == 2 ? (
                                <div></div>
                            ) : (
                                <div className={`row d-flex justify-content-center align-items-center py-5  hcm_offer_body px-4 ${index % 2 == 0 ? 'ams_body' : ''}`}>
                                    {title && <h4 className="text-center fw-bold  hcm_offer_header">{title}</h4>}
                                    {sub_title && <h6 className="text-center  hcm_offer_subheader">{sub_title}</h6>}
                                    {infotext && <p className="hcm_offer_desc text-center mb-5">{infotext}</p>}

                                    <div className={`row d-flex justify-content-center align-items-center hcm_offer_body_content    ${index % 2 == 0 ? 'Ams_Hr_operation' : 'flex-row-reverse'}`}>

                                        <div className="col-md-4 col-sm-12 col-12">
                                            <div>
                                                <img src={require(`../../image/HCM Coe/AMS/${bannerImage}.png`)} className="img-size" />
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-sm-12 col-12">
                                            {
                                                desc.map((data, index) => (
                                                    <div className="head_on_desc_body mb-3">
                                                        {data.Desc_header && <p className="mb-2 text-start" style={{ fontWeight: '600', lineHeight: '20px', fontSize: '16px' }}>{data.Desc_header}</p>}
                                                        {data.Desc &&
                                                            data.Desc.map((value, index1) => (

                                                                <div className="explore_service_section">
                                                                    <div className="d-flex align-items-center mb-2" key={index}>
                                                                        {
                                                                            value.icon && <div> <img src={require(`../../image/HCM Coe/AMS/${value.icon}.png`)} className="AMS-small-icon me-2" alt="HCM Coe" />  </div>
                                                                        }

                                                                        <div><p className="normal-text-font" style={{ fontWeight: '600', lineHeight: '20px', fontSize: '16px' }}>{value.text} </p></div>

                                                                    </div>
                                                                </div>

                                                            ))
                                                        }
                                                        {data.btn_url && <div> <a className="btn HoverBtn button-hover overlaybtn text-white normal-text-font" href={data.btn_url}>{data.btn}</a></div>}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                </div>
                            )
                        }

                        {
                            id == 3 && (
                                <div className="row d-flex justify-content-center align-items-center our_service_body">
                                    <h3 className="text-center fw-bold hcm_offer_header mt-4 px-5">{title}</h3>
                                    {
                                        desc.map((data, index) => (
                                            <p className="our_service_card_desc text-center m-2 px-5">{data.text}</p>
                                        ))
                                    }

                                    <div className="card-section-content row px-5 justify-content-center text-center">
                                        {
                                            card.map((data, index) => (
                                                <div className="col-md-4 mb-4 ThreeCardWidth">
                                                    <div className="AMS-card-fix-height card text-center p-4 erp-card-radius card-bg-color erp-card-background Description-Fontsize">
                                                        <div className='cardImg text-start d-flex justify-content-between align-items-center'>
                                                            <p className="fw-bold">{data.card_name}</p>
                                                            <img src={require(`../../image/HCM Coe/AMS/${data.icon}`)} className="icon-size icon-box" />
                                                        </div>
                                                        <div className="card-body px-1 d-flex">
                                                            <p className="our_service_card_desc">{data.card_desc}</p>
                                                        </div>
                                                        {
                                                            data.card_url && (
                                                                <div className="UrlSection px-1 d-flex align-items-center justify-content-end">
                                                                    <Link to={data.card_url} className='text-start text-decoration-none UrlText px-2' href="">{data.card_url_name}</Link>
                                                                    <Link className='btn card-btn-color' to={data.card_url}><i className="fa-solid fa-arrow-right px-2 "></i></Link>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }


                                    </div>

                                </div>
                            )
                        }


                    </div>
                );
            })}

            <FooterBrands />
            <GetStarted />

        </>
    )
}

export default HCM_AMS