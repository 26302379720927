
import React, { useState } from "react";
import hcmcoe from '../../data/HCM/learning.json';
import { Banner } from "../Banner";
import FooterBrands from "../FooterBrands";
import '../../style/HCMLearning.css'
import TrustedOrganization from "../TrustedOrganization";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { GetStarted } from "../FooterSection/GetStarted";
import { Helmet } from 'react-helmet';


const HCMLearning = () => {

    const [activeTab, setActiveTab] = useState("tab1");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="" style={{ overflowX: 'hidden' }}>
            <Helmet>
                <title>Phenom eCloud Workforce Learning Services</title>
                <meta name="description" content="Complete services for workforce learning: strategy and planning, deployment, optimization, and content that enables you to build a learning organization." />
            </Helmet>
            <Banner />
            <TrustedOrganization />
            {hcmcoe.map((item) => {
                const { id, title, desc, bannerImage, infotext, grid, org } = item;
                return (
                    <div className="row">
                        <div className="col-12 w-100" key={id}>
                            {id === 1 && (
                                <div className="row d-flex justify-content-around mt-3 mb-3">
                                    <div className="col-lg-11 col-md-11 col-sm-11 col-11">
                                        <div className="d-flex flex-column align-items-center justify-content-around mt-3 ">
                                            <div className="col-lg-8 col-md-8 col-sm-11 col-11 mb-2">
                                                <h4 className="text-center fw-bold  title-font" style={{ color: 'rgb(242,111,33)' }}>{title}</h4>
                                                <div>
                                                    <p className="text-center fw-bold fs-5 title-font">{desc}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )}
                            {id === 2 && (
                                <div className="row d-flex justify-content-around mt-2 mb-3 ">
                                    <div className="col-12 d-flex justify-content-around flex-column ">
                                        <div className="row d-flex justify-content-around">
                                            <div className="col-11 d-flex justify-content-around flex-column px-5">
                                                <div className=" row d-flex align-items-center justify-content-between">
                                                    {grid && grid.map((gridItem) => {
                                                        const { position, icon, title, tab, storyl } = gridItem;
                                                        return (
                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-12 mt-3" key={position}>
                                                                <div
                                                                    className={`tab d-flex align-items-center flex-column service-button ${activeTab === tab ? 'active-tab' : ''}`}
                                                                    onClick={() => handleTabClick(tab)}
                                                                    style={{ backgroundColor: activeTab === tab ? 'rgb(242,111,33)' : '', color: activeTab === tab ? '#FFFFFF' : '', width: '95%' }}
                                                                >
                                                                    <div className="mt-2 img-hh mb-2" style={{ filter: activeTab === tab ? 'brightness(0) invert(1)' : '' }}>
                                                                        <img src={require(`../../image/HCM Coe/Learning/${icon}.png`)} className="img-fluid" alt={icon} />
                                                                    </div>
                                                                    <div className="mb-2 button-text">
                                                                        {title}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row ">
                                            {grid && grid.map((gridItem) => {
                                                const { position, tab, storyl } = gridItem;
                                                if (position != 4.4) {
                                                    return (
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12" key={position}>
                                                            {activeTab === tab && storyl && storyl.map((story) => {
                                                                const { id, title, subtitle, servicegrid, url } = story;
                                                                return (
                                                                    <div className="py-3 " key={id}>
                                                                        <div className="row d-flex align-items-center flex-column">
                                                                            <div className="col-lg-8 col-md-8 col-sm-10 col-11 mt-3 text-center">
                                                                                <h4 className="fw-bold  Pcolor title-font">{story.title}</h4>
                                                                                <p className="  pb-3 normal-text-font">{story.subject}</p>
                                                                                <p className="  pb-3 normal-text-font">{story.subcontent}</p>
                                                                                <h4 className="fw-bold  Pcolor text-center title-font">{story.subtitle}</h4>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="row justify-content-center">
                                                                                    <div className="col-12">
                                                                                        {servicegrid.map((item, index) => {
                                                                                            const { id, icon, title, desc, btn, url, bullets, card, cta } = item;
                                                                                            const rowColor = index % 2 === 0 ? 'bg-white' : 'bg-blue';
                                                                                            const isEven = index % 2 === 0;




                                                                                            return (
                                                                                                <>
                                                                                                    <div className={`col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end align-items-center my-4 ${rowColor}`} key={id}>
                                                                                                        <div className="row d-flex flex-row align-items-center justify-content-around p-3 ">
                                                                                                            <div className="col-lg-10 col-md-10 col-sm-11 col-12 flex-row align-items-center">
                                                                                                                <div className="row flex-row align-items-center">
                                                                                                                    {isEven ? (
                                                                                                                        <>
                                                                                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column  box-h2">
                                                                                                                                <div>
                                                                                                                                    <p className="fw-bold fs-3" style={{ color: 'rgb(242,111,33)' }}>{title}</p>
                                                                                                                                    {desc.map((paragraph, idx) => (
                                                                                                                                        <p className="normal-text-font mb-3" key={idx}>{paragraph}</p>
                                                                                                                                    ))}
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    {btn && <Link to={url} className="btn BtnHover overlaybtn text-white normal-text-font" rel="noreferrer noopener">{btn}</Link>}
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    {bullets && bullets.map((bullet, index) => {
                                                                                                                                        const { icon, desc } = bullet;
                                                                                                                                        return (
                                                                                                                                            <div className="d-flex align-items-center mb-2" key={index}>
                                                                                                                                                <div>
                                                                                                                                                    <img className="AMS-small-icon" src={require(`../../image/HCM Coe/Learning/${icon}.png`)} alt={bullet.icon} />
                                                                                                                                                </div>
                                                                                                                                                <div><p className="normal-text-font" style={{ marginLeft: '10px' }}>{desc}</p></div>

                                                                                                                                            </div>
                                                                                                                                        )
                                                                                                                                    })}
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    {card && (
                                                                                                                                        <div className="card card-radius card-box-fade-shadow">
                                                                                                                                            <div className="card-body">
                                                                                                                                                <div className="d-flex align-items-center mb-2 ">
                                                                                                                                                    <div>
                                                                                                                                                        <img className="icon-box" src={require(`../../image/HCM Coe/Learning/${card.icon}.png`)} alt={card.icon} />
                                                                                                                                                    </div>
                                                                                                                                                    <div style={{ marginLeft: '10px' }}>
                                                                                                                                                        <p className="normal-text-font fw-bold">{card.desc}</p>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                <div className="text-center">
                                                                                                                                                    <Link to={card.url} className="normal-text-font">{card.btn}</Link>
                                                                                                                                                </div>                                                                                                                                    </div>
                                                                                                                                        </div>
                                                                                                                                    )}

                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    {cta && (

                                                                                                                                        <div className="d-flex flex-column mb-2 ">

                                                                                                                                            <div >
                                                                                                                                                <p className="normal-text-font mb-3" style={{ color: 'rgb(239,87,19)' }}>{cta.desc}</p>
                                                                                                                                            </div>
                                                                                                                                            <div>
                                                                                                                                                {cta.url && <Link to={cta.url} className="btn BtnHover overlaybtn text-white normal-text-font" rel="noreferrer noopener">{cta.btn}</Link>}
                                                                                                                                            </div>
                                                                                                                                        </div>


                                                                                                                                    )}

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column align-items-end mb-3">

                                                                                                                                <div>
                                                                                                                                    <img src={require(`../../image/HCM Coe/Learning/${icon}.png`)} alt="HCM" className="img-fluid" />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column align-items-start mb-3">
                                                                                                                                <div>
                                                                                                                                    <img src={require(`../../image/HCM Coe/Learning/${icon}.png`)} alt="HCM" className="img-fluid" />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column  box-h2" style={{ color: "white" }}>
                                                                                                                                <div>
                                                                                                                                    <p className="fw-bold fs-3">{title}</p>
                                                                                                                                    {desc.map((paragraph, idx) => (
                                                                                                                                        <p className="normal-text-font mb-3" key={idx}>{paragraph}</p>
                                                                                                                                    ))}
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    {btn && <Link to={url} className="btn BtnHover overlaybtn text-white normal-text-font" rel="noreferrer noopener">{btn}</Link>}
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    {bullets && bullets.map((bullet, index) => {
                                                                                                                                        const { icon, desc } = bullet;
                                                                                                                                        return (
                                                                                                                                            <div className="d-flex align-items-center mb-2" key={index}>
                                                                                                                                                <div>
                                                                                                                                                    <img src={require(`../../image/HCM Coe/Learning/${icon}.png`)} />
                                                                                                                                                </div>
                                                                                                                                                <div><p className="normal-text-font" style={{ marginLeft: '10px' }}>{desc}</p></div>

                                                                                                                                            </div>
                                                                                                                                        )
                                                                                                                                    })}
                                                                                                                                </div>

                                                                                                                                <div>
                                                                                                                                    {card && (
                                                                                                                                        <div className="card">
                                                                                                                                            <div className="card-body">

                                                                                                                                                <div className="d-flex align-items-center mb-2">
                                                                                                                                                    <div>
                                                                                                                                                        <img src={require(`../../image/HCM Coe/Learning/${card.icon}.png`)} alt={card.icon} />
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <p className="normal-text-font mb-3">{card.desc}</p>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <Link to={card.url} className="normal-text-font">{card.btn}</Link>
                                                                                                                                                </div>


                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    )}
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    {cta && (

                                                                                                                                        <div className="d-flex flex-column mb-2 ">

                                                                                                                                            <div >
                                                                                                                                                <p className="normal-text-font mb-3" style={{ color: 'rgb(239,87,19)' }}>{cta.desc}</p>
                                                                                                                                            </div>
                                                                                                                                            <div>
                                                                                                                                                {cta.url && <Link to={cta.url} className="btn BtnHover overlaybtn text-white normal-text-font" rel="noreferrer noopener">{cta.btn}</Link>}
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    )}

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                    )}

                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            );

                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );



                                                            })}
                                                        </div>
                                                    );
                                                }
                                                else {

                                                    return (
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 align-items-center d-flex flex-column" key={position}>
                                                            {activeTab === tab && storyl && storyl.map((story, index) => {
                                                                const { id, title, subtitle, servicegrid, url, icon } = story;
                                                                const isEven = index % 2 === 0;
                                                                const rowColor = isEven ? 'bg-white' : 'bg-blue';
                                                                const textColor = isEven ? '' : 'text-white';
                                                                const imgG = isEven ? '' : 'exclusion';
                                                                return (
                                                                    <div className={`py-3 ${rowColor}`} key={id}>
                                                                        <div className={`row d-flex align-items-center flex-column ${textColor}`}>
                                                                            <div className="col-lg-8 col-md-8 col-sm-10 col-11 mt-3 text-center">
                                                                                <h4 className="fw-bold  Pcolor title-font">{story.title}</h4>
                                                                                {icon && <img src={require(`../../image/HCM Coe/Learning/${icon}.png`)} alt="HCM" className="img-fluid" />}
                                                                                <p className="  pb-3 normal-text-font">{story.subject}</p>
                                                                                <h4 className="fw-bold  Pcolor text-center title-font">{story.subtitle}</h4>
                                                                                <p className="  pb-3 normal-text-font">{story.subtext}</p>
                                                                            </div>
                                                                            <div className={`col-lg-11 col-md-11 col-sm-11 col-12 mt-3`}>
                                                                                <div className="row justify-content-center padding_fix">

                                                                                    {servicegrid.map((item, index) => {
                                                                                        const { id, className, icon, title, desc, btn, btnName, url, GridclassName } = item;
                                                                                        const rowColor = index % 2 === 0 ? 'bg-white' : 'bg-blue';
                                                                                        const isEven = index % 2 === 0;
                                                                                        return (
                                                                                            <>
                                                                                                <div className={`${GridclassName === "4" ? 'col-md-4 mb-4 TwoCardWidth' : `col-md-${GridclassName} mb-4 TwoCardWidth`}`} key={id} >
                                                                                                    <div className={`d-flex  flex-column py-2 px-2 h-100 ${icon ? "text-center erp-card-radius card-bg-color py-3 px-3 erp-card-background Description-Fontsize border" : ""}`} >
                                                                                                        <div className="cardImg text-start d-flex justify-content-between align-items-center text-dark">
                                                                                                            {icon && (
                                                                                                                <>
                                                                                                                    <p className=" fw-bold d-flex align-items-center justify-content-around">{title}</p>
                                                                                                                    <img src={require(`../../image/HCM Coe/Learning/${icon}.png`)} className="icon-box" alt="HCM" />
                                                                                                                </>
                                                                                                            )}
                                                                                                        </div>
                                                                                                        {
                                                                                                            desc && (
                                                                                                                <div className="card-body px-1 d-flex flex-column text-start justify-content-center mt-2 align-items-center text-dark">
                                                                                                                    {icon && (
                                                                                                                        <>
                                                                                                                            <p className="normal-text-font">{desc}</p>
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            )
                                                                                                        }
                                                                                                        <div className="mb-2">
                                                                                                            {btnName && <Link to={url} className="btn HoverBtn fix-highr-btn overlaybtn text-white normal-text-font justify-content-center" rel="noreferrer noopener">{btnName}</Link>}
                                                                                                        </div>
                                                                                                        {
                                                                                                            btn && (
                                                                                                                <div className="UrlSection px-1 d-flex align-items-center justify-content-end"><Link to={url}> <button class="btn card-btn-color"> <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} /></button></Link></div>
                                                                                                            )
                                                                                                        }

                                                                                                    </div>
                                                                                                </div>

                                                                                            </>
                                                                                        );

                                                                                    })}

                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );



                                                            })}
                                                        </div>
                                                    );

                                                }
                                            })}


                                        </div>
                                    </div>

                                </div>
                            )}

                        </div>
                    </div>

                );
            })}
            <FooterBrands />
            <GetStarted />
        </div>
    );
}

export default HCMLearning;

