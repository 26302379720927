
import React, { useState } from "react";
import hcmcoe from '../../data/HCM/compensation.json';
import { Banner } from "../Banner";
import FooterBrands from "../FooterBrands";
import TrustedOrganization from "../TrustedOrganization";
import { GetStarted } from "../FooterSection/GetStarted";
import { Helmet } from 'react-helmet';



const Compensation = () => {

    const [activeTab, setActiveTab] = useState("tab1");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="" style={{ overflowX: 'hidden' }}>
             <Helmet>
                <title>People-Centered Compensation Implementation, Optimization, and Support </title>
                <meta name="description" content="Phenom eCloud provides strategy, implementation, and support services for compensation technology implementation and optimization. Services from a single application to integrating." />
            </Helmet>
            <Banner />
            <TrustedOrganization />
            {hcmcoe.map((item) => {
                const { id, title, desc, bannerImage, infotext, grid } = item;
                return (
                    <div className="" key={id}>

                        {id === 2 && (
                            <div className="row d-flex justify-content-around mt-3 mb-5">
                                <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_remove compesation_banner_content">
                                    <div className="d-flex flex-column align-items-center justify-content-around mt-3 ">
                                        <div className="col-lg-8 col-md-8 col-sm-11 col-11 mb-2">
                                            <h4 className="text-center fw-bold  title-font">{title}</h4>
                                            <div>
                                                <p className="text-center fw-bold fs-5 title-font">{desc}</p>
                                            </div>
                                        </div>
                                        <div className="infotext">
                                            <div className="row ">
                                                <div className="col-lg-3 col-md-3 col-sm-12 col-12 img-h ">
                                                    <img src={require(`../../image/HCM Coe/${bannerImage}.jpg`)} className="img-fluid img" />
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-12 col-12 d-flex flex-column justify-content-evenly" style={{ padding: '16px' }}>
                                                    {infotext.map((paragraph, index) => (
                                                        <p className="fw-bold normal-text-font" key={index}>{paragraph}</p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {id === 3 && (
                            <div className="row d-flex justify-content-around mt-2 mb-3 card_section_bg py-5">
                                <div className="col-11 d-flex justify-content-around flex-column px-5 border-padding-remove">
                                    <div className="row d-flex align-items-center justify-content-between">
                                        {grid && grid.map((gridItem) => {
                                            const { position, icon, title, tab } = gridItem;
                                            return (
                                                <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center  mt-3" key={position}>
                                                    <div
                                                        className={`tab d-flex align-items-center flex-column service-button talent_management_service_btn ${activeTab === tab ? 'active-tab service_btn_border_color' : ''}`}
                                                        onClick={() => handleTabClick(tab)}
                                                        style={{ backgroundColor: activeTab === tab ? 'rgb(255 255 255)' : '', color: activeTab === tab ? '#000' : '', width: '95%' }}
                                                    >
                                                        <div className="mt-2 img-hh mb-2" style={{ filter: activeTab === tab ? '' : 'brightness(0) invert(1)' }}>
                                                            <img src={require(`../../image/HCM Coe/${icon}.png`)} className="img-fluid" alt={icon} />
                                                        </div>
                                                        <div className="mb-2 button-text">
                                                            {title}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="row ">
                                        {grid && grid.map((gridItem) => {
                                            const { position, tab, storyl } = gridItem;
                                            return (
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12" key={position}>
                                                    {activeTab === tab && storyl && storyl.map((story) => {
                                                        const { id, servicegrid } = story;
                                                        return (
                                                            <div className="py-3 text-center" key={id}>
                                                                <div className="row d-flex align-items-center flex-column">
                                                                    <div className="col-lg-8 col-md-8 col-sm-10 col-11 mt-3">
                                                                        <h4 className="fw-bold  Pcolor title-font">{story.title}</h4>
                                                                        <p className="  pb-3 normal-text-font">{story.subject}</p>
                                                                        <h4 className="fw-bold  Pcolor text-center title-font">{story.subtitle}</h4>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className="row justify-content-center">
                                                                            {servicegrid && servicegrid.map(service => {
                                                                                return (
                                                                                    <div className={`col-12 col-md-6 col-sm-6 col-lg-${service.position === "CTA" ? 8 : 4} mt-3`} key={service.position}>
                                                                                        <div className={`card text-center erp-card-radius card-bg-color py-3 px-3 erp-card-background Description-Fontsize h-100 ${service.className} ${service.position === "CTA" ? 'flex-row-reverse' : 'flex-column'} `}>
                                                                                            <div className={`d-flex justify-content-around ${service.position === "CTA" ? 'align-items-center' : 'null'}`} style={{ width: service.position === "CTA" ? '25%' : '100%' }}>
                                                                                                <img src={require(`../../image/HCM Coe/Compensation/${service.icon}.png`)} alt={service.icon} height="25" style={{ width: '55px' }} className="img-fluid height-img" />
                                                                                            </div>
                                                                                            <div className="d-flex flex-column mt-2 justify-content-between ">
                                                                                                <div style={{ width: service.position === "CTA" ? '75%' : '100%' }}>
                                                                                                    <p className=" fw-bold title-font d-flex align-items-center justify-content-around text-center">{service.title}</p>
                                                                                                    {service.desc && <p className="normal-text-font text-left line-_height" style={{ fontSize: '15px' }}>{service.desc}</p>}
                                                                                                </div>
                                                                                                <div className="mb-2">
                                                                                                    {service.btn && <a href={service.url} className="btn HoverBtn overlaybtn text-white normal-text-font" rel="noreferrer noopener">{service.btn}</a>}

                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}


                                    </div>
                                </div>

                            </div>
                        )}
                        {id === 4 && (
                            <div className="row d-flex justify-content-around mt-3 mb-3">
                                <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_remove compesation_banner_content">
                                    <div className="d-flex flex-column align-items-center justify-content-around mt-3 ">
                                        <div className="">
                                            <div className="row compensation_details_body">
                                                <div className="col-lg-7 col-md-7 col-sm-12 col-12 d-flex flex-column justify-content-center align-items-center" style={{ padding: '16px' }}>
                                                    <div className="compensation_details col-md-8">
                                                        {infotext.map((paragraph, index) => (
                                                            <p className=" normal-text-font" key={index}>{paragraph}</p>
                                                        ))}
                                                        <a href={item.btnUrl}><button className="btn HoverBtn">{item.button}</button></a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-12 col-12 img-h ">
                                                    <img src={require(`../../image/HCM Coe/Compensation/${bannerImage}.png`)} className="img-fluid img" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
            <FooterBrands />
            <GetStarted />
        </div>
    );
}

export default Compensation;

