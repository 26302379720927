
import React, { useState } from "react";
import hcmcoe from '../../data/HCM/hcm_coe.json';
import { Banner } from "../Banner";
import FooterBrands from "../FooterBrands";
import { Link } from "react-router-dom";
import TrustedOrganization from "../TrustedOrganization";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from "react";
import Aos from "aos";
import { GetStarted } from "../FooterSection/GetStarted";
import { Helmet } from 'react-helmet';



const HCMCOE = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    })
    // const [activeTab, setActiveTab] = useState("tab1");

    // const handleTabClick = (tab) => {
    //     setActiveTab(tab);
    // };


    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const updateScreenSize = () => {
            setIsSmallScreen(window.innerWidth <= 1269);
        };

        window.addEventListener('resize', updateScreenSize);

        updateScreenSize();

        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, []);

    return (
        <div className="HCM-COE" style={{ overflowX: 'hidden' }}>
               <Helmet>
                <title>Human Capital Management COE Services | Phenom eCloud</title>
                <meta name="description" content="Phenom eCloud's HCM Center of Excellence (COE) offers expert planning, deployment, support, and optimization services for human capital management technology, ensuring efficient HR operations and workforce growth." />
            </Helmet>
            <Banner />
            <TrustedOrganization />
            {hcmcoe.map((item) => {
                const { id, title, desc, bannerImage, infotext, grid, sub_title, card } = item;
                return (
                    <div className="" key={id}>

                        {
                            id == 5 && (
                                <div className="row d-flex justify-content-center align-items-center py-4 coe_future_head px-5 left-right-p-remove">
                                    <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
                                        <h4 className="coe_header text-center fw-bold">{title}</h4>
                                        <div className="coe_future_head_content row d-flex justify-content-center align-items-center">
                                            <div className="col-md-3 col-sm-12 col-12 text-center">
                                                <img src={require(`../../image/HCM Coe/${bannerImage}.png`)} className="img-fluid img Hearding-img" />
                                            </div>
                                            <div className="col-md-7 col-sm-12 col-12">
                                                {
                                                    desc.map((data, index) => (
                                                        <div className="head_on_desc_body mb-3">
                                                            {data.Desc_header && <p className="fw-bold mb-0" style={{ fontSize: '18px' }}>{data.Desc_header}</p>}
                                                            {data.Desc && <p style={{ fontWeight: '500', lineHeight: '20px' }}>{data.Desc}</p>}
                                                            {data.btn_url && <a className="btn HoverBtn button-hover overlaybtn text-white normal-text-font" href={data.btn_url}>{data.btn}</a>}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {
                            id == 6 && (
                                <div className="row d-flex justify-content-center align-items-center py-4 px-5 hcm_offer_body fix-p-remove">
                                    <h4 className="text-center fw-bold hcm_offer_header">{title}</h4>
                                    <h6 className="text-center fw-bold hcm_offer_subheader">{sub_title}</h6>
                                    <p className="hcm_offer_desc text-center mb-5">{infotext}</p>

                                    <div className="row d-flex justify-content-center align-items-center hcm_coe_body_content  mb-3">
                                        <div className="col-md-6 col-sm-12 col-12 p-0 OneCardWidth">
                                            {
                                                desc.map((data, index) => (
                                                    <div className="head_on_desc_body mb-3">
                                                        {data.Desc_header && <p className="fw-bold mb-2" style={{ fontSize: '18px' }}>{data.Desc_header}</p>}
                                                        {data.Desc &&
                                                            data.Desc.map((value, index1) => (
                                                                index1 == 2 ? (
                                                                    <div className=" col-md-9 text-light hcm_offer_card_body mt-3 mb-4" data-aos="fade-right">
                                                                        <h3 className="text-center"><span style={{ content: '\\201C' }}>&#8220;</span>{value.text}<span>&#8221;</span></h3>
                                                                        <p className="text-end">{value.author}</p>
                                                                    </div>
                                                                ) : (
                                                                    <div className="explore_service_section">
                                                                        <div className="d-flex align-items-center mt-3" key={index}>
                                                                            {
                                                                                value.icon && <img src={require(`../../image/HCM Coe/${value.icon}.png`)} className="icon-size icon-box me-2" alt="HCM Coe" />
                                                                            }

                                                                            <div><p className="normal-text-font" style={{ fontWeight: '600', lineHeight: '20px', fontSize: '16px' }}>{value.text} </p></div>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            ))
                                                        }
                                                        {data.btn && <a className="btn HoverBtn button-hover overlaybtn text-white normal-text-font" href={data.btn_url}>{data.btn}</a>}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="col-md-5 col-sm-12 col-12 TwoCardWidth" data-aos="fade-left">
                                            <img src={require(`../../image/HCM Coe/${bannerImage}.png`)} className="img-size" />
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        {
                            id == 7 && (
                                <div className="row d-flex justify-content-center align-items-center allcoe_service_card_bg card-section-content px-5 text-center padding_remove inside-border-padding-remove">
                                    <h3 className="text-center fw-bold hcm_offer_header mt-4">{title}</h3>

                                    <div className="card-section-content row px-5 justify-content-center text-center border-padding-remove pb-4">
                                        {
                                            card.map((data, index) => (
                                                <div className="col-md-4 mb-4 TwoCardWidth">
                                                    <div className="card text-center erp-card-radius card-bg-color py-3 px-3 erp-card-background Description-Fontsize HCM-card-fix-height" data-aos={`${isSmallScreen ? 'fade-up' : data.popUp}`} >
                                                        <div className="cardImg text-start d-flex justify-content-between align-items-center">
                                                            <p className="fw-bold">{data.card_name}</p>
                                                            <img src={require(`../../image/HCM Coe/COEimages/${data.icon}`)} className="img-fluid hcm_coe_img" />
                                                        </div>
                                                        <div className="card-body px-1 d-flex">
                                                            <p className="text-start">{data.card_desc}</p>
                                                        </div>
                                                        {
                                                            data.card_url && (
                                                                <div className="UrlSection px-1 d-flex align-items-center justify-content-end">
                                                                    <Link to={data.card_url} className='text-start text-decoration-none UrlText px-2' href="">{data.UrlName}</Link>
                                                                    <Link className='btn card-btn-color' to={data.card_url}><i className="fa-solid fa-arrow-right px-2 "></i></Link>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }


                                    </div>

                                </div>
                            )
                        }
                    </div>
                );
            })}

            <FooterBrands />
            <GetStarted />

        </div>
    );
}

export default HCMCOE;

