import React, { useState } from "react";
import hcmcoe from '../../data/HCM/enterpriseIntegrations.json';
import '../../style/EnterpriseIntegration.css'
import { Banner } from "../Banner";
import FooterBrands from "../FooterBrands";
import TrustedOrganization from "../TrustedOrganization";
import { GoChevronRight } from "react-icons/go";
import { Link } from "react-router-dom";
import { FooterContact } from '../../components/FooterContact'
import { GetStarted } from "../FooterSection/GetStarted";
import { Helmet } from 'react-helmet';



const EnterpriseIntegrations = () => {

    const [activeButton, setActiveButton] = useState('Contact us');

    const handleButtonClick = (button) => {
        setActiveButton(button);
    };

    const [activeTab, setActiveTab] = useState("tab1");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="Integrations" style={{ overflowX: 'hidden' }}>
            <Helmet>
                <title>Enterprise Application Integrations services </title>
                <meta name="description" content="Phenom eCloud delivers state-of-the-art integration services, from a single application to integrating your entire enterprise." />
            </Helmet>
            <Banner />
            <TrustedOrganization />
            {hcmcoe.map((item) => {
                const { id, title, desc, bannerImage, infotext, tabledata } = item;
                return (
                    <div className="" key={id}>
                        {id === 1 && (
                            <div className="row d-flex justify-content-around mt-3 mb-3">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 card_section_bg">
                                    <div className="row  justify-content-around mt-3 ">
                                        <div className="col-lg-11 col-md-11 card_body_section_bg col-sm-12 col-12 mb-2 d-flex flex-column align-items-center justify-content-center">
                                            <h4 className="text-center fw-bold  title-font" style={{ color: 'rgb(242,111,33)' }}>{title}</h4>
                                            <div>
                                                <p className="text-center fw-bold fs-5 title-font">{desc}</p>
                                            </div>
                                            <div className="col-lg-11 col-md-11 col-sm-12 col-12 text-center d-flex align-items-center flex-column justify-content-evenly">
                                                {infotext.map((paragraph, index) => (
                                                    <p className=" normal-text-font" key={index}>{paragraph}</p>
                                                ))}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-12">
                                    {tabledata.map((item, index) => {
                                        const { id, image1, head, text, btntext, btn1, btn2, Url1, Url2 } = item;
                                        const rowColor = index % 2 === 0 ? 'bg-white' : 'bg-blue';
                                        const isEven = index % 2 === 0;

                                        return (
                                            <div className={`col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end align-items-center my-4 ${rowColor}`} key={id}>
                                                <div className="row d-flex flex-row align-items-center justify-content-around p-3 ">
                                                    <div className="col-lg-10 col-md-10 col-sm-11 col-12 flex-row align-items-center">
                                                        <div className="row flex-row align-items-center card-content">
                                                            {isEven ? (
                                                                <>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column  box-h2">
                                                                        <div>
                                                                            <p className="fw-bold fs-3" style={{ color: 'rgb(242,111,33)' }}>{head}</p>
                                                                            {text.map((paragraph, idx) => (
                                                                                <p className="normal-text-font" key={idx}>{paragraph}</p>
                                                                            ))}
                                                                            {
                                                                                btn1 && (
                                                                                    <div className="text-center">
                                                                                        <Link to={Url1}>
                                                                                            <button className={` button-text btv mx-2 ${activeButton === 'Contact us' ? 'active-btn' : ''}`} style={{ height: "45px" }} onClick={() => handleButtonClick('Contact us')}>
                                                                                                {btn1} <GoChevronRight />
                                                                                            </button>
                                                                                        </Link>
                                                                                        <Link to={Url2}>
                                                                                            <button to={Url2} className={` button-text btv mb-3 mx-2 ${activeButton === 'Back to Resource Center' ? 'active-btn' : ''}`} style={{ height: "45px" }} onClick={() => handleButtonClick('Back to Resource Center')}>
                                                                                                {btn2} <GoChevronRight />
                                                                                            </button>
                                                                                        </Link>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column align-items-end mb-3">
                                                                        <img src={require(`../../image/HCM Coe/Enterprise Integrations/${image1}.png`)} alt="HCM" className="img-fluid" />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column align-items-start mb-3">
                                                                        <img src={require(`../../image/HCM Coe/Enterprise Integrations/${image1}.png`)} alt="HCM" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column  box-h2 " style={{ color: "white" }}>
                                                                        <div>
                                                                            <p className="fw-bold fs-3">{head}</p>
                                                                            {text.map((paragraph, idx) => (
                                                                                <p className="normal-text-font" key={idx}>{paragraph}</p>
                                                                            ))}
                                                                        </div>


                                                                    </div>
                                                                </>
                                                            )}

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        );
                                    })}

                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
            {/* <FooterContact /> */}
            <FooterBrands />
            <GetStarted />
        </div>
    );
}

export default EnterpriseIntegrations;

