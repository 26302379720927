import React from 'react'
import FooterBrandData from '../data/FooterBrands.json'
import { Link, useParams } from 'react-router-dom';

function FooterBrands() {

    let { className } = useParams();
    if (className === undefined) {
        className = 'home';
    }

    return (
        <div>
            {
                FooterBrandData.map((item, index) => {
                    const { Id, MainClassName, Grid1, Grid2, Heading, BrandData, PageName } = item;
                    return (
                        className === PageName && (
                            <div className="BrandSection" key={Id}>
                                <div className={MainClassName}>
                                    <div className={Grid1}>
                                        <h5>{Heading}</h5>
                                    </div>

                                    <div className={`${Grid2} tablet_padding_remove`}>
                                        <div className="row container justify-content-center px-5 tablet_padding_remove">
                                            {
                                                BrandData.map((item) => {
                                                    const { Id, Grid, className, Img, Url } = item;
                                                    return (<div className={Grid} key={Id}>
                                                        <div className={className}>
                                                            <Link to={Url}>
                                                                <img className='card-img-top BrandImg' src={require(`../image/Brands/${Img}`)} alt="" />
                                                            </Link>
                                                        </div>
                                                    </div>);
                                                })
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>)
                    );
                })

            }
        </div>
    )
}

export default FooterBrands
