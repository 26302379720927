import React from 'react'
import { Banner } from './Banner'
import TrustedOrganization from "./TrustedOrganization";
import policyData from "../data/TermsandConditions.json";
export const TermsandConditions = () => {
    return (
        <>
            <Banner />
            <TrustedOrganization />

            <div className="privacy_policy_body d-flex container-fluid" style={{ padding: "5%" }}>
                <div className="row">
                    <div className="col-md-6 col-sm-12 privacy_policy_left_body" style={{ padding: "1%" }}>
                        {policyData[0].Data.map((item, index) =>
                            index < 9 ? (
                                <div className="left_content">
                                    <h5 style={{ color: "#f37021", fontWeight: "bold" }}>{item.Header}</h5>
                                    {item.desc.map((item1, index1) => (
                                        <p className="privacy_desc" dangerouslySetInnerHTML={{ __html: item1.Desc }}></p>
                                    ))}
                                </div>
                            ) : (
                                ""
                            )
                        )}
                    </div>
                    <div className="col-md-6 col-sm-12 privacy_policy_right_body" style={{ padding: "1%" }}>
                        {policyData[0].Data.map((item, index) =>
                            index > 8 ? (
                                <div className="right_content">
                                    <h5 style={{ color: "#f37021", fontWeight: "bold" }}>{item.Header}</h5>
                                    {
                                        item.desc && (
                                            item.desc.map((item1, index1) => (
                                                <p className="privacy_desc">{item1.Desc} &nbsp; {item1.Link && (<a href='mailto:support@phenomecloud.com'>{item1.Link}</a>)}</p>
                                            ))
                                        )
                                    }
                                </div>
                            ) : (
                                ""
                            )
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
