import React, { useState } from 'react'
import { Banner } from '../Banner'
import TrustedOrganization from '../TrustedOrganization'
import ErpAmsData from '../../data/ERP/Erp_Ams.json'
import { Accordion, AccordionButton } from 'react-bootstrap'
import { GoChevronRight, GoHorizontalRule, GoPlus } from 'react-icons/go'
import { GetStarted } from '../FooterSection/GetStarted'
import { Link } from 'react-router-dom' 
import { Helmet } from 'react-helmet'
import LazyLoad from 'react-lazyload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const Erp_Ams = () => {
 
    
    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (eventKey) => {
        setExpandedItem(expandedItem === eventKey ? null : eventKey);
    };

    const [activeButton, setActiveButton] = useState('Contact us');

    const handleButtonClick = (button) => {
        setActiveButton(button);
    };


  return (
    <>
       <Helmet>
                <title>Phenom eCloud AMS for Enterprise Resource Planning</title>
                <meta name="description" content="Learn how application management services can help you tame your ERP tech stack." />
       </Helmet>
       <div className='Erp_Ams_body'>
       <Banner />
       <TrustedOrganization />
       {ErpAmsData.map((item, index) => {
                const { id, title, desc, bannerImage, accordians, tabledata, services, infotext, sub_desc, disc, subSection, Description, cards, grid, sub_title, card } = item;
                return (
                    <div className="" key={id}> 
                     {id == 1 ? (
                            <div class="row d-flex justify-content-evenly py-4    align-items-center    whitebg_section discover_possible">
                                <div className="col-md-3 text-center col-sm-12 col-12">
                                    <div>
                                        <img src={require(`../../image/ERP/AMS/${bannerImage}.png`)} className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 col-12">
                                    {
                                        desc.map((data, index) => (
                                            <div className="head_on_desc_body mb-3">
                                                {data.Desc_header && <p className="mb-3" style={{ fontWeight: 'bolder', lineHeight: '23px', fontSize: '27px' }}>{data.Desc_header}</p>}
                                                {data.Desc &&
                                                    data.Desc.map((value, index1) => (
                                                        <div className="explore_service_section">
                                                            <div className="d-flex align-items-center mb-2" key={index}>
                                                                <div>
                                                                    <p className="normal-text-font" style={{ fontWeight: '600', lineHeight: '20px', fontSize: '16px' }}>{value.text} </p>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        ) : ''}

                     {id === 2 && (
                            <div className="row d-flex justify-content-around  ">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 card_section_bg">
                                    <div className="row  justify-content-around mt-3 ">
                                        <div className="col-lg-11 col-md-11 card_body_section_bg col-sm-12 col-12 mb-2 d-flex flex-column align-items-center justify-content-center">
                                            <h4 className="text-center fw-bold  title-font" style={{ color: 'rgb(242,111,33)' }}>{title}</h4>
                                            <div>
                                                <p className="text-center fw-bold fs-5 title-font">{desc}</p>
                                            </div>
                                            <div className="col-lg-11 col-md-11 col-sm-12 col-12 text-center d-flex align-items-center flex-column justify-content-evenly">
                                                {infotext.map((paragraph, index) => (
                                                    <p className=" normal-text-font" key={index}>{paragraph}</p>
                                                ))}
                                            </div>
                                            <h4 className="text-center fw-bold mt-4 title-font" style={{ color: '#fff' }}>{sub_title}</h4>
                                            <div>
                                                <p className="text-center  title-font">{sub_desc}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-12">
                                    {tabledata.map((item, index) => {
                                        const { id, image1, head, text, bannerImage, ul, CTA, Desc, desc, Description, btntext, btn, btn_url, btn1, btn2, Url1, Url2 } = item;
                                        const rowColor = index % 2 === 0 ? 'bg-white' : 'bg-blue';
                                        const isEven = index % 2 === 0;
                                        
                                     if(index == 0){
                                        return (
                                            <div className={`col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end align-items-center my-4 ${rowColor}`} key={id}>
                                                  <div className="row d-flex justify-content-center align-items-center hcm_coe_body_content px-5 reverse_flow ipad_col_rev mb-3">
                                                   <div className="col-md-6 col-sm-12 col-12 p-0 OneCardWidth">
                                                    {
                                                       desc.map((data, index) => (
                                                        <div className="head_on_desc_body mb-3">
                                                        {data.Desc_header && <p className="fw-bold mb-2" style={{ fontSize: '25px' }}>{data.Desc_header}</p>}
                                                        
                                                        {data.Description &&
                                                            data.Description.map((value, index1) => ( 
                                                               ( index == 1 &&  index1 == 0)   ? (
                                                                  
                                                                        <div className=" col-md-9 text-light hcm_offer_card_body erp_ams_quote mt-3 mb-4" >
                                                                        {
                                                                            value.text.map((quote,index2) =>(
                                                                                <h4 className="text-center"><span style={{ content: '\\201C' }}>&#8220;</span>{quote.quote}<span>&#8221;</span></h4>
                                                                            ))
                                                                        }
                                                                    
                                                                            <p className="text-end">{value.author}</p>
                                                                        </div>
                                                                   
                                                                   
                                                                ) : (
                                                                    <div className="explore_service_section">
                                                                        <div className="d-flex align-items-start " key={index}>
                                                                            {
                                                                                value.icon && <img src={require(`../../image/ERP/AMS/${value.icon}.png`)} className="icon-size icon-box me-2" alt="HCM Coe" />
                                                                            }

                                                                            <div className='d-flex '> <p className="normal-text-font" style={{ fontWeight: '600', lineHeight: '20px', fontSize: '16px', marginLeft:'1%' }}  dangerouslySetInnerHTML={{ __html: value.text }}></p></div>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            ))
                                                        }
                                                       
                                                        {data.btn && <a className="btn HoverBtn button-hover overlaybtn text-white normal-text-font" href={data.btn_url}>{data.btn}</a>}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="col-md-5 col-sm-12 col-12 TwoCardWidth text-center" >
                                            <img src={require(`../../image/ERP/AMS/${bannerImage}.png`)} className="img-size erp_stack_img_height" />
                                        </div>
                                    </div>
                                            </div>
                                        );
                                     }
                                     else{
                                        return (
                                            <div className={`col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end align-items-center my-4  ${rowColor}`} key={id}>
                                                <div className="row d-flex flex-row align-items-center justify-content-around p-3 erp_card_stack">
                                                    <div className="col-lg-10 col-md-10 col-sm-11 col-12 flex-row align-items-center">
                                                        <div className={`row flex-row align-items-center card-content  ${isEven ?'reverse_flow ':'small_screen_col_reverse'}`}>
                                                            {isEven ? (
                                                                <>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column  box-h2">
                                                                        <div>
                                                                            <p className="fw-bold fs-3" style={{ color: 'rgb(242,111,33)' }}>{head}</p>
                                                                            {text.map((paragraph, idx) => (
                                                                                <p className="normal-text-font" style={{fontWeight:'500'}} key={idx}>{paragraph}</p>
                                                                            ))}
                                                                            {
                                                                                 ul && ul.map((list,index) =>(
                                                                                    <li className="normal-text-font"  dangerouslySetInnerHTML={{ __html: list.li }}></li>
                                                                                 ))
                                                                            }
                                                                            {
                                                                                  CTA &&  <div className="ams_contact mt-3">
                                                                                 <p><strong>{CTA.article_title}</strong><a className="fw-bold" style={{color:'#467886'}} href='https://blog.phenomecloud.com/6-reasons-you-need-data-governance' target='_blank'>{CTA.quote}</a></p>   
                                                                                  <p className='fw-bold'>{CTA.text}</p>
                                                                                  <div>
                                                                                      <Link to={CTA.btn1_Url}   className="btn HoverBtn" >{CTA.btn1}</Link> 
                                                                                  </div>
                                                                                 </div>
                                                                            }
                                                                             {
                                                                                btn && (
                                                                                    <div className="text-start">
                                                                                        <Link to={btn_url}>
                                                                                            <button className={` button-text btv   `} >
                                                                                                {btn} <GoChevronRight />
                                                                                            </button>
                                                                                        </Link> 
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            {
                                                                                btn1 && (
                                                                                    <div className="text-center">
                                                                                        <Link to={Url1}>
                                                                                            <button className={` button-text btv mx-2 ${activeButton === 'Contact us' ? 'active-btn' : ''}`} style={{ height: "45px" }} onClick={() => handleButtonClick('Contact us')}>
                                                                                                {btn1} <GoChevronRight />
                                                                                            </button>
                                                                                        </Link>
                                                                                        <Link to={Url2}>
                                                                                            <button to={Url2} className={` button-text btv mb-3 mx-2 ${activeButton === 'Back to Resource Center' ? 'active-btn' : ''}`} style={{ height: "45px" }} onClick={() => handleButtonClick('Back to Resource Center')}>
                                                                                                {btn2} <GoChevronRight />
                                                                                            </button>
                                                                                        </Link>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column align-items-end mb-3">
                                                                        <img src={require(`../../image/ERP/AMS/${image1}.png`)} alt="HCM" className="img-fluid erp_stack_img_height" />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column align-items-start mb-3">
                                                                        <img src={require(`../../image/ERP/AMS/${image1}.png`)} alt="HCM" className="img-fluid erp_stack_img_height" />
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column  box-h2 " style={{ color: "white" }}>
                                                                        <div>
                                                                            <p className="fw-bold fs-3">{head}</p>
                                                                            {text.map((paragraph, idx) => (
                                                                                <p className="normal-text-font" key={idx}>{paragraph}</p>
                                                                            ))}
                                                                              {
                                                                                 ul && ul.map((list,index) =>(
                                                                                    <li className="normal-text-font"  dangerouslySetInnerHTML={{ __html: list.li }}></li>
                                                                                 ))
                                                                            }
                                                                             {
                                                                                btn && (
                                                                                    <div className="text-start text-white">
                                                                                        <Link to={btn_url}>
                                                                                            <button className={` button-text btv mx-2 text-white`} >
                                                                                                {btn} <GoChevronRight />
                                                                                            </button>
                                                                                        </Link> 
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>


                                                                    </div>
                                                                </>
                                                            )}

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        );
                                     }
                                       
                                    })} 
                                </div>
                            </div>
                        )}

                        {id === 3 && (
                            <div className="row d-flex justify-content-around mb-3">
                              
                                <div className="col-12">
                                    {tabledata.map((item, index) => {
                                        const { id, image1, head, text, ul, CTA, btntext, btn1, btn2, Url1, Url2 } = item;
                                        const rowColor = index % 2 === 0 ? ' ' : 'bg-white small_screen_col_reverse ';
                                        const isEven = index % 2 === 0;

                                        return (
                                            <div className={`col-lg-12 col-md-12 col-sm-12 col-12 justify-content-end align-items-center  ${rowColor}`} key={id}>
                                                <div className="row d-flex flex-row align-items-center justify-content-around p-3 erp_application">
                                                    <div className="col-lg-10 col-md-10 col-sm-11 col-12 flex-row align-items-center">
                                                        <div className={`row flex-row align-items-center card-content ipad_col_rev erp_apm ${rowColor}`}>
                                                            {isEven ? (
                                                                <>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column  box-h2" style={{ color: "black" }}>
                                                                        <div>
                                                                            <p className="fw-bold fs-3" style={{ color: 'rgb(242,111,33)' }} >{head}</p>
                                                                            {text.map((paragraph, idx) => (
                                                                                <p className={`normal-text-font ${idx == 2 ? 'fw-bold':'mb-3'}`} key={idx}>{paragraph}</p>
                                                                            ))}
                                                                            
                                                                            {
                                                                                ul.map((list,index3) =>(
                                                                                    <li className="normal-text-font">{list.li}</li>
                                                                                ))
                                                                            } 
                                                                           <div className="ams_contact mt-3">
                                                                            <p className='fw-bold'>{CTA.text}</p>
                                                                            <div>
                                                                                <a href={CTA.btn1_Url} target="_blank" className="btn HoverBtn" >{CTA.btn1}</a>
                                                                                <a href={CTA.btn2_url} target="_blank" className="btn HoverBtn ms-3" >{CTA.btn2}</a>
                                                                            </div>
                                                                           </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column align-items-end mb-3">
                                                                        <img src={require(`../../image/ERP/AMS/${image1}.png`)} alt="HCM" className="img-fluid erp_stack_img_height" />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <> 
                                                                </>
                                                            )}

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        );
                                    })}

                                </div>
                            </div>
                        )}

                       {
                           id === 4 && (
                            <div
                              className="MainCardBox p-5 d-flex align-items-center text-center row justify-content-center allcoe_service_card_bg"
                              key={index}
                            >
                              <div className="col-md-7">
                                <h3>{title}</h3>
                              </div>
                              <div className="col-md-9">
                                {disc.map((item, index) => (
                                  <p
                                    className="Description"
                                    dangerouslySetInnerHTML={{ __html: item.text }}
                                  ></p>
                                ))}
                                {/* */}
                              </div>
                            
                                <div className="row d-flex justify-content-center align-items-center our_service_body_content mt-3 mb-3">
                                <h3>{subSection.Header}</h3>
                                {  
                                subSection.card.map((data,index) =>(
                                    
                                      <div className="col-md-5 col-dm-12 m-3 our_service_card card d-flex flex-column card-bg-color justify-content-evenly p-3 DataManagementCards">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <p className="fw-bold">{data.card_name}</p>
                                        <img src={require(`../../image/ERP/AMS/${data.icon}`)} alt="" srcset="" className='icon-box' />
                                    </div>
                                    <div className="card-body px-1 d-flex flex-column">
                                        {
                                            data.card_desc && data.card_desc.map((item,index) =>(
                                                <div className="d-flex col-md-11 align-items-baseline">
                                                    <span style={{marginRight:'3%'}}><FontAwesomeIcon icon={faArrowRight} style={{ color: "#5CB6F2" }} /></span>
                                                    <p className="our_service_card_desc"  dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                                </div>
                                               
                                            ))
                                        } 
                                    </div>
                                    <div className="  col-md-12 d-flex justify-content-end align-items-center">
                                 {data.card_url &&    <Link style={{ marginRight: '2%', textDecoration: 'none' }} to={data.card_url}>
                                            <p >Discover your possibilities</p></Link>}   
                                            {data.card_url &&   <Link to={data.card_url} className=''>
                                            <button class="btn card-btn-color"> <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} /></button></Link>}   
                                    
                                     </div>
                                </div>
                                )) 
                                 }
                                </div>
                            
                                
                                <div className="col-md-9 mt-4 erp_ams_card_description">
                                 <p>{subSection.Description}</p>
                                 </div>
                              <div className="row justify-content-center mt-4 CardBodySection erp_ams_excellence">
                             
                                {tabledata.sort((a, b) => a.head.localeCompare(b.head)) .map((item, index) => {
                                  const { id, image, head, Url, desc } = item;
                                  return (
                                    <div className="col-md-3 mb-4" key={id}>
                                      <div className="cardSection">
                                        {Url !== "" ? (
                                          <Link to={Url}>
                                            <div className="card">
                                              <LazyLoad offset={100}>
                                                <img
                                                  className="agile-image"
                                                  src={require(`../../image/Agile/${image}`)}
                                                  alt=""
                                                />
                                              </LazyLoad>
                                              <div className="card-body">
                                                <p className='fw-bold' >{head}</p> 
                                                <div className='h-100 d-flex align-items-center'>
                                                <p>{desc}</p>
                                                </div> 
                                              </div>
                                            </div>
                                          </Link>
                                        ) : (
                                          <p>
                                            <div className="card">
                                              <img  className="agile-image"
                                                src={require(`../../image/ERP/AMS/${image}`)}
                                                alt=""
                                              />
                                              <div className="card-body">
                                                <p className='fw-bold'>{head}</p>
                                                <div className='h-100 d-flex align-items-center'>
                                                      <p>{desc}</p>
                                                </div> 
                                              
                                              </div>
                                            </div>
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )
                       }

                        {id === 5 && (
                            <div className="row d-flex justify-content-around mt-3 mb-3  ">
                                <div className="col-lg-11 col-md-11 col-sm-11 col-11 px-5 padding_fix">
                                    <div className=" ">
                                        <div className="">
                                            <p className="text-center fw-bold pt-4" style={{ fontSize: '25px' }}>{title}</p>
                                            {
                                                desc.map((data, id) => (
                                                    <p className='line-_height text-center'>{data.text}</p>
                                                ))
                                            }
                                        </div>
                                        <div className="">
                                            <div className="row d-flex justify-content-center whitebg_section reverse_flow reverse_flow_tab erp_critical Advisory_service">
                                                
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center dataManagement_Acrd">
                                                    <img src={require(`../../image/ERP/AMS/${bannerImage}.png`)} className=" accordian_img" />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column  " style={{ padding: '16px' }}>
                                                    {<Accordion className='d-flex flex-column EcCoe'>
                                                        {accordians.map((item, index) => {
                                                            const { header, desc } = item;
                                                            return (
                                                                <Accordion.Item eventKey={index.toString()} className='mb-2 acoordian_items' key={index} style={{ border: 'none', background: '#E1EDFE' }} >
                                                                    <AccordionButton className='Dropdown-Bg-Color'
                                                                        onClick={() => handleToggle(index.toString())}
                                                                        aria-expanded={expandedItem === index.toString()}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                                            <span className='fw-bolder sm-font-size'>{header}</span>
                                                                            {expandedItem === index.toString() ? <GoHorizontalRule /> : <GoPlus className='PlusIcon' />}
                                                                        </div>
                                                                    </AccordionButton>

                                                                    <Accordion.Body key={index} className='py-1'>
                                                                        {
                                                                            desc && desc.map((item,index) =>(
                                                                                <p className='text-start'  dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                                                            ))
                                                                        } 
                                                                    </Accordion.Body>

                                                                </Accordion.Item>
                                                            );
                                                        })}
                                                    </Accordion>
                                                    }
                                                    {
                                                        <div className="services_section mt-3">
                                                            <h5 className='fw-bold'>{services[0].header}</h5>
                                                            {
                                                                services[0].desc.map((descitem,index) =>(
                                                                    <li className="normal-text-font"  dangerouslySetInnerHTML={{ __html: descitem.text }}></li>
                                                                ))
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
        <GetStarted />
        </div>
    </>
  )
}

export default Erp_Ams