import React from 'react'
import { Banner } from '../Banner'
import { Helmet } from 'react-helmet'
import TrustedOrganization from '../TrustedOrganization'
import onbordingData from '../../data/HCM/onboarding.json'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { GetStarted } from '../FooterSection/GetStarted'

const Onboarding = () => {
  return (
    <>
     <Helmet>
        <title>Phenom eCloud Onboarding</title>
        <meta
          name="description"
          content="Discover how remote onboarding and cloud-based tools can enrich the new hire experience by providing seamless integration, personalized training, and instant communication."
        />
      </Helmet>
      <Banner />
      <TrustedOrganization />
      {onbordingData.map((item, index) => {
                const { id, title, desc, bannerImage, accordians, infotext,tabledata, grid, sub_title, card } = item;
                return (
                    <div className="" key={id}>
                        {id == 1 ? (
                            <div class="row d-flex justify-content-evenly py-4  mb-3 align-items-center whitebg_section discover_possible  allcoe_service_card_bg">
                                <div className="col-md-4 text-center col-sm-12 col-12">
                                    <div>
                                        <img src={require(`../../image/HCM Coe/Onboarding/${bannerImage}.png`)} className="img-fluid " />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 col-12">
                                    {
                                        desc.map((data, index) => (
                                            <div className="head_on_desc_body mb-3">
                                                {data.Desc_header && <p className="mb-3" style={{ fontWeight: 'bolder', lineHeight: '20px', fontSize: '25px' }}>{data.Desc_header}</p>}
                                                {data.Desc &&
                                                    data.Desc.map((value, index1) => (
                                                        <div className="explore_service_section">
                                                            <div className="d-flex align-items-center mb-2" key={index}>
                                                                <div>
                                                                    <p className="normal-text-font" style={{ fontWeight: '600', lineHeight: '20px', fontSize: '16px' }}>{value.text} </p>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        ) : ''} 
                         {
                            id == 2  && (
                                <div className="row d-flex justify-content-center align-items-center mt-4 ">
                                    {title && <h3 className="text-center fw-bold hcm_offer_header mt-4">{title}</h3>}
                                    {
                                        desc.map((value, items) => (
                                            value && <p key={items} className="text-center service_desc" style={{ width: '50%', fontSize: '16px' }}>{value.text}</p>
                                        ))
                                    }

                                    <div className="row d-flex justify-content-center align-items-center our_service_body_content mt-3 mb-3 onbording_Card_Section">
                                        {
                                            card.map((data, index) => { 

                                                {
                                                  if(  data.card_name !== 'What Sets Us Apart' ) {
                                                    return <div className={`col-md-3  our_service_card col-dm-12 m-3 card text-center erp-card-radius card-bg-color py-3 px-3 erp-card-background Description-Fontsize onboarding-card-fix-height ${data.card_class}`}>

                                                    <div className='cardImg text-start d-flex justify-content-between align-items-center'>

                                                        <p className="fw-bold">{data.card_name}</p>
                                                        <img src={require(`../../image/HCM Coe/Onboarding/${data.icon}`)} alt=""  className='icon-box' />
                                                    </div>
                                                    <div className="card-body px-1 d-flex">

                                                       <div className="onboarding_card_desc" > <p className="our_service_card_desc"  dangerouslySetInnerHTML={{ __html: data.card_desc }}></p></div>
                                                    </div>
                                                    {
                                                        data.card_url && (
                                                            <div className=" col-md-12 d-flex justify-content-end align-items-center">
                                                                <Link style={{ marginRight: '2%', textDecoration: 'none' }} to={data.card_url}>
                                                                    <p >{data.card_btn}</p></Link>
                                                                <Link to={data.card_url} >
                                                                    <button class="btn card-btn-color"> <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} /></button></Link>
                                                            </div>
                                                        )
                                                    }
                                                    </div> 
                                                  }
                                                  else{
                                                    return <div className={`col-md-6 col-sm-12 onboarding_unique_card our_service_card col-dm-12 m-3 card text-center erp-card-radius card-bg-color py-3 px-3 erp-card-background Description-Fontsize onboarding-card-fix-height ${data.card_class}`}>

                                                    <div className='cardImg text-start d-flex justify-content-between align-items-center'>

                                                        <p className="fw-bold">{data.card_name}</p> 
                                                    </div>
                                                    <div className="card-body px-1 d-flex"> 
                                                       <div className="col-md-9 d-flex flex-column justify-content-center" >
                                                       {
                                                            data.card_desc.map((item1,index2) =>(
                                                                <p className="our_service_card_desc"  dangerouslySetInnerHTML={{ __html: item1.text }}></p>
                                                            ))
                                                        } 
                                                       </div>
                                                       <div class="col-md-3"> <img src={require(`../../image/HCM Coe/Onboarding/${data.icon}`)} alt="" srcset="" className='last-card-iconimg ' /></div>
                                                    </div>
                                                    {
                                                        data.card_url && (
                                                            <div className=" col-md-12 d-flex justify-content-end align-items-center">
                                                                <Link style={{ marginRight: '2%', textDecoration: 'none' }} to={data.card_url}>
                                                                    <p >{data.card_btn}</p></Link>
                                                                <Link to={data.card_url} >
                                                                    <button class="btn card-btn-color"> <FontAwesomeIcon icon={faArrowRight} style={{ color: "#ffffff" }} /></button></Link>
                                                            </div>
                                                        )
                                                    }
                                                </div> 
                                                  }
                                                }
                                               
                                                  
                                            })
                                        }


                                    </div>

                                </div>
                            )
                        }
                        
                    </div>
                );
            })}
        <GetStarted />
    </>
  )
}

export default Onboarding