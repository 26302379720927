import React, { useState, useRef, useEffect } from 'react'
import NavLogo from '../image/navLogo.png'
import phoneIcon from '../image/HeadingImg/Phone.png'
import emailIcon from '../image/HeadingImg/Email.png'
import { Link } from 'react-router-dom'
import { GoChevronDown } from "react-icons/go";
import Data from '../data/Hearder.json'
import { GoChevronRight } from "react-icons/go";
import { TfiClose } from "react-icons/tfi";
import { TfiAlignJustify } from "react-icons/tfi";
import SearchComponent from './SearchComponent'


const Header = () => {
    var NavBarData = Data;


    // Sorting each subarray based on the Name property
    // NavBarData.forEach(item => {
    //     if (item.IdName === "WhatWeAre") {
    //         return; // Skip this item
    //     }

    //     if (item.NavbarDropDown) {
    //         item.NavbarDropDown.forEach(item1 => {
    //             if (item1.DropDownData) {
    //                 // Sort DropDownData alphabetically
    //                 item1.DropDownData.sort((a, b) => a.Name.localeCompare(b.Name));

    //                 // Find the specific item
    //                 const targetItem = item1.DropDownData.find(item2 => item2.Name.includes("COE offers to you"));

    //                 // If found, move it to the front if not already there
    //                 if (targetItem) {
    //                     // Remove the item from its current position
    //                     item1.DropDownData = item1.DropDownData.filter(item2 => item2 !== targetItem);
    //                     // Add it to the front
    //                     item1.DropDownData.unshift(targetItem);
    //                 }
    //             }
    //         });
    //     }
    // });

    const [activeClass, setActiveClass] = useState('');
    const [openCollapse, setOpenCollapse] = useState('');
    const [innerCollapse, setInnerCollapse] = useState('');
    const [defaultInnerCollapse, setDefaultInnerCollapse] = useState('');
    const [isChildManuallyChanged, setIsChildManuallyChanged] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const navbarRef = useRef(null);
    const collapseRef = useRef({}); // Store refs for collapse divs

    let enterTimeout;
    let leaveTimeout;

    const [expandedCollapse, setExpandedCollapse] = useState('');
    const [highlightedCollapse, setHighlightedCollapse] = useState('');
    const collapseRefs = useRef({});

    const setCollapseRefs = (el, className) => {
        collapseRef.current[className] = el;
        collapseRefs.current[className] = el;
    };

    const getDefaultInnerClass = (parentClass) => {
        switch (parentClass) {
            case 'NavWeDo':
                return 'EnterpriseBusiness';
            case 'NavWeAre':
                return 'AboutUs';
            case 'NavWeThink':
                return 'Blogs'; // Replace with appropriate default class if needed
            default:
                return '';
        }
    };

    // Hover and Show the Collapse
    const handleMouseEnter = (className) => {
        clearTimeout(leaveTimeout);
        clearTimeout(enterTimeout);

        // Hide any currently open collapses
        if (openCollapse && openCollapse !== className) {
            const previousCollapseElement = document.getElementById(openCollapse);
            if (previousCollapseElement) {
                previousCollapseElement.classList.remove('show');
                document.querySelector(`[href="#${openCollapse}"]`)?.classList.add('collapsed');
            }
        }

        enterTimeout = setTimeout(() => {
            const collapseElement = document.getElementById(className);
            if (collapseElement) {
                collapseElement.classList.add('show');
                document.querySelector(`[href="#${className}"]`)?.classList.remove('collapsed');

                collapseElement.addEventListener('mouseleave', () => {
                    handleMouseLeave(className);
                });
            }

            // Update state
            setOpenCollapse(className);
            setActiveClass(className);

            const firstInnerClass = getDefaultInnerClass(className);
            if (!isChildManuallyChanged || openCollapse !== className) {
                setDefaultInnerCollapse(firstInnerClass);
                setInnerCollapse(firstInnerClass);
                setIsChildManuallyChanged(false);
                setExpandedCollapse(firstInnerClass);
                setHighlightedCollapse(firstInnerClass);

                const innerCollapseElement = document.getElementById(firstInnerClass);
                if (innerCollapseElement) {
                    innerCollapseElement.classList.add('show');
                    document.querySelector(`[href="#${firstInnerClass}"]`)?.classList.remove('collapsed');
                }
            }
        }, 250); // Adjust this delay if needed
    };

    // Handle hover out
    const handleMouseLeave = (className) => {
        clearTimeout(enterTimeout);

        leaveTimeout = setTimeout(() => {
            const navContentElement = document.querySelector('.NavContent');
            if (navContentElement && navContentElement.matches(':hover')) {
                return; // Do nothing if hovering over NavContent
            }

            const collapseElement = document.getElementById(className);
            if (collapseElement) {
                collapseElement.classList.remove('show');
                document.querySelector(`[href="#${className}"]`)?.classList.add('collapsed');
            }

            // Reset state
            setOpenCollapse('');
            setActiveClass('');
            setInnerCollapse('');
            setDefaultInnerCollapse('');
            setIsChildManuallyChanged(false);
        }, 250); // Adjust this delay if needed
    };




    // Toggle collapse on click
    const handleLinkClick = (event, className) => {
        // handleMouseEnter(className);
        event.preventDefault(); // Prevent the default action
        event.stopPropagation(); // Stop click event from propagating

        // Toggle parent collapse visibility
        if (openCollapse !== className) {
            // Close the previously open parent collapse
            document.getElementById(openCollapse)?.classList.remove('show');
            document.querySelector(`[href="#${openCollapse}"]`)?.classList.add('collapsed');

            // Open the new parent collapse
            document.getElementById(className)?.classList.add('show');
            document.querySelector(`[href="#${className}"]`)?.classList.remove('collapsed');
            setOpenCollapse(className);

            // Set and show the default child collapse when opening the parent
            const defaultChildClass = getDefaultInnerClass(className);
            setDefaultInnerCollapse(defaultChildClass);
            setInnerCollapse(defaultChildClass);
            setIsChildManuallyChanged(false);

            // Force the default inner collapse to be shown
            const childElement = document.getElementById(defaultChildClass);
            if (childElement) {
                childElement.classList.add('show');
                document.querySelector(`[href="#${defaultChildClass}"]`)?.classList.remove('collapsed');
            }
        } else {
            // Ensure the inner collapse stays open if clicking the parent collapse again
            if (defaultInnerCollapse) {
                setInnerCollapse(defaultInnerCollapse);
                const childElement = document.getElementById(defaultInnerCollapse);
                if (childElement) {
                    childElement.classList.add('show');
                    document.querySelector(`[href="#${defaultInnerCollapse}"]`)?.classList.remove('collapsed');
                }
            }
        }
        setActiveClass(className);
    };

    // Toggle inner collapse
    const handleToggleInnerCollapse = (className) => {

        if (activeCollapseId === className) {
            setActiveCollapseId(null); // Collapse the currently open one if it's the same
        } else {
            setActiveCollapseId(className); // Open the new one and close the previous
        }

        setExpandedCollapse(className);
        setHighlightedCollapse(className);
        if (innerCollapse !== className) {
            // Close the previously open inner collapse
            if (innerCollapse) {
                document.getElementById(innerCollapse)?.classList.remove('show');
                document.querySelector(`[href="#${innerCollapse}"]`)?.classList.add('collapsed');
            }
            // Open the new inner collapse
            document.getElementById(className)?.classList.add('show');
            document.querySelector(`[href="#${className}"]`)?.classList.remove('collapsed');
            setInnerCollapse(className);
            setIsChildManuallyChanged(true);
        } else {
            // Ensure the inner collapse stays open if clicking the same item
            document.getElementById(className)?.classList.add('show');
            document.querySelector(`[href="#${className}"]`)?.classList.remove('collapsed');
        }
    };
    // Click Outside to Close Collapses
    const handleClickOutside = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target) &&
            !Object.values(collapseRef.current).some(ref => ref && ref.contains(event.target))) {
            // Click is outside the navbar and collapse content
            setActiveClass('');
            if (openCollapse) {
                document.getElementById(openCollapse)?.classList.remove('show');
                document.querySelector(`[href="#${openCollapse}"]`)?.classList.add('collapsed');
                setOpenCollapse('');
            }
            setInnerCollapse('');
            setDefaultInnerCollapse('');
            setIsChildManuallyChanged(false);
        }


    };
    const ClickLink = () => {
        if (openCollapse) {
            document.getElementById(openCollapse)?.classList.remove('show');
            document.querySelector(`[href="#${openCollapse}"]`)?.classList.add('collapsed');
            setOpenCollapse('');
        }
        toggleNavbar();
    }

    const RemoveParentCollapse = (event) => {
        if (window.innerWidth <= 991) {  // Check if the screen size is 991px or less
            const navbarCollapse = document.getElementById('NavBarContentRight');
            if (navbarCollapse) {
                navbarCollapse.classList.remove('show');
            }
        }
        // Add any other functionality needed when the link is clicked
    };
    useEffect(() => {
        return () => {
            clearTimeout(enterTimeout);
            clearTimeout(leaveTimeout);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openCollapse, innerCollapse]);


    // Small Screen Size Class Name Change 
    const [isDropdown, setIsDropdown] = useState(false);
    useEffect(() => {
        // Function to check screen size and update class
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth >= 100 && screenWidth <= 991) {
                setIsDropdown(true);
            } else {
                setIsDropdown(false);
            }
        };

        // Initial check
        handleResize();

        // Add event listener on resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [activeCollapseId, setActiveCollapseId] = React.useState(null);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };


    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            // Check if the screen width has increased from 991 or less to a larger value
            if (screenWidth <= 991 && newWidth > 991) {
                toggleNavbar();
            }

            setScreenWidth(newWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [screenWidth]);

    // ----------------Search-Remove------------------

    const [searchText, setSearchText] = useState('');

    // Function to handle input change
    const handleInputChange = (e) => {
        setSearchText(e.target.value);
    };

    // Function to clear the search text
    const clearSearchText = () => {
        setSearchText(''); // Clear the input value
    };




    return (
        <div>
            <nav className="navbar navbar-expand-lg bg-light">
                <div className="container-fluid NavContent">
                    <div className="col-md-3">
                        <Link to={`/`} className=" NavLogo">
                            <img className='navImg' src={NavLogo} alt="" />
                        </Link>
                    </div>

                    <div className={`collapse navbar-collapse ${isDropdown ? 'collapse' : ''} col-md-5 NavToggleMain`} id="NavBarContentRight" ref={navbarRef}>
                        <div className={`${isDropdown ? 'card card-body' : 'd-flex'}`}>
                            {
                                NavBarData.map((item) => {
                                    const { Id, IdName, ToggleId, Name, className, NavbarDropDown } = item;
                                    return (
                                        <div className={`${isDropdown ? 'accordion' : IdName} ${isDropdown ? 'accordion-item rounded-0' : ''}`} key={Id} ref={navbarRef} onMouseEnter={() => handleMouseEnter(className)} >
                                            <a className={`nav-item text-decoration-none ${isDropdown ? 'accordion-button' : ''} ${activeCollapseId === className ? '' : 'collapsed'}  ${activeClass === className ? 'active' : ''}`} data-bs-toggle="collapse" href={`#${className}`} role="button"
                                                id={`${isDropdown ? IdName : IdName}`}
                                                aria-expanded={openCollapse === className ? 'true' : 'false'}
                                                aria-controls={`${isDropdown ? ToggleId : className}`}
                                                onClick={(event) => { handleLinkClick(event, className); }}
                                                onMouseEnter={() => handleMouseEnter(className)}
                                                data-bs-target={`#${ToggleId}`}>
                                                <p className={`${className} p-3 `} aria-current="page">
                                                    {Name}<GoChevronDown className='down-icon' />
                                                </p>
                                            </a>
                                            <div className={`row sub-toggle ${isDropdown ? 'accordion-collapse collapse' : ''}`} id={ToggleId} aria-labelledby={`${isDropdown ? IdName : ''}`} data-bs-parent={`${isDropdown ? `#${IdName}` : ''}`}>
                                                <div className="col">
                                                    <div className={`collapse multi-collapse ${openCollapse === className ? 'show' : ''} ${expandedCollapse === className ? 'show' : ''} `} id={className} ref={el => setCollapseRefs(el, className)}>
                                                        <div className="card card-body border-0 rounded-0 p-0">
                                                            <div className={`${isDropdown ? '' : 'row navbar-box-shadow main-width'}`}>
                                                                {
                                                                    !isDropdown && (
                                                                        <div className={`${isDropdown ? 'col-md-12' : 'col-md-3 ps-0'}`}>
                                                                            <div className="HeadingContent background-color p-0">
                                                                                {NavbarDropDown && (
                                                                                    NavbarDropDown
                                                                                        // .sort((a, b) => a.Name.localeCompare(b.Name)) // Sort by Name in ascending order
                                                                                        .map((item, index) => {
                                                                                            const { headingClass, IdName, ToggleId, className: innerClassName, tabClass, Name } = item;
                                                                                            return (
                                                                                                <div className={`${headingClass} ${isDropdown ? 'accordion-item accordion' : IdName} ${isDropdown ? '' : `${highlightedCollapse === innerClassName ? 'white-bg-color' : ''}`}`}
                                                                                                    id={IdName} key={index}>
                                                                                                    <a className={`text-decoration-none ${tabClass}`} data-bs-toggle="collapse" href={`#${innerClassName}`} role="button"
                                                                                                        id={`${isDropdown ? IdName : IdName}`}
                                                                                                        aria-expanded={innerCollapse === innerClassName ? 'true' : 'false'}
                                                                                                        aria-controls={`${isDropdown ? ToggleId : innerClassName}`}
                                                                                                        data-bs-target={`#${ToggleId}`}
                                                                                                        onClick={(e) => {
                                                                                                            // e.preventDefault();
                                                                                                            handleToggleInnerCollapse(innerClassName);
                                                                                                        }}>{Name} <GoChevronRight className='NavArow' /></a>
                                                                                                </div>
                                                                                            )
                                                                                        }))
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    isDropdown && (
                                                                        NavbarDropDown && (
                                                                            NavbarDropDown.map((item, index) => {
                                                                                const { headingClass, parentId, IdName, ToggleId, className: innerClassName, tabClass, Name, DropDownData } = item;
                                                                                return (
                                                                                    <div className={`${headingClass} background-color accordion d-flex flex-column  col-md-12`} id={parentId} key={index}>
                                                                                        <div className="accordion-item rounded-0 bg-transparent" id={ToggleId}>
                                                                                            <a
                                                                                                className={`text-decoration-none ${tabClass} accordion-button text-white fw-normal ${activeCollapseId === innerClassName ? '' : 'collapsed'}`}
                                                                                                data-bs-toggle="collapse"
                                                                                                href={`#${innerClassName}`}
                                                                                                role="button"
                                                                                                aria-controls={IdName}
                                                                                                onClick={(e) => {
                                                                                                    // e.preventDefault();
                                                                                                    handleToggleInnerCollapse(innerClassName);
                                                                                                }}
                                                                                            >
                                                                                                {Name}
                                                                                            </a>
                                                                                            <div
                                                                                                className={`accordion-collapse collapse ${activeCollapseId === innerClassName ? 'show' : ''} col-md-12`}
                                                                                                id={IdName}
                                                                                                aria-labelledby={ToggleId}
                                                                                                data-bs-parent={`#${parentId}`}
                                                                                            >
                                                                                                <div className="p-4 card card-body border-0 rounded-0 p-0 accordion-body ">
                                                                                                    <div className="row text-dark">
                                                                                                        {
                                                                                                            DropDownData && (
                                                                                                                DropDownData.map((item, index) => {
                                                                                                                    const { Grid, Validation, Description, className, Img, Name, Url } = item;
                                                                                                                    return (
                                                                                                                        <div className={Grid} key={index}>
                                                                                                                            {
                                                                                                                                Validation === "12" && (
                                                                                                                                    <div className={className}>
                                                                                                                                        {
                                                                                                                                            Description && (
                                                                                                                                                Description.map((item, index) => {
                                                                                                                                                    const { Name } = item;
                                                                                                                                                    return (
                                                                                                                                                        <h6 className="text-dark Description-Fontsize mb-3 text-start " key={index}>{Name}</h6>
                                                                                                                                                    )
                                                                                                                                                })
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                        <div className='AboutUsBtn'>
                                                                                                                                            <Link to={`/aboutUs`} className={`float-end col-md-1`} onClick={(event) => { ClickLink(event); RemoveParentCollapse(); }} >
                                                                                                                                                <button className='btn'>
                                                                                                                                                    <i className="fa-solid fa-arrow-right"></i>
                                                                                                                                                </button>
                                                                                                                                            </Link>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            }
                                                                                                                            {
                                                                                                                                Validation === "4" && (
                                                                                                                                    <div className={`d-flex ${className}`}>
                                                                                                                                        {
                                                                                                                                            Img && (
                                                                                                                                                <img className="me-3 nav-icon-size img_text_align_fix" src={require(`../image/NavIcon/${Img}`)} alt="" />
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            Url !== "/" ? (
                                                                                                                                                <Link to={Url} className="navTab-size text-decoration-none text-dark" onClick={(event) => { ClickLink(event); RemoveParentCollapse(); }}>{Name}</Link>
                                                                                                                                            ) : (<p className="navTab-size text-decoration-none text-dark text-start ">{Name}</p>)
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            }
                                                                                                                            {
                                                                                                                                className === "OurPartners" && (
                                                                                                                                    <div className={`NavOurPartners`}>
                                                                                                                                        <div className='ImgBox my-2 d-flex align-items-center'>
                                                                                                                                            <img src={require(`../image/HeadingImg/${Img}`)} alt="" />
                                                                                                                                            <Link to={Url} onClick={(event) => { ClickLink(event); RemoveParentCollapse(); }}>
                                                                                                                                                <button className='btn'>
                                                                                                                                                    <i className="fa-solid fa-arrow-right"></i>
                                                                                                                                                </button>
                                                                                                                                            </Link>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            }
                                                                                                                            {
                                                                                                                                className === "OurClients" && (
                                                                                                                                    <div className={`NavOurPartners`}>
                                                                                                                                        <div className='ImgBox my-2 d-flex align-items-center'>
                                                                                                                                            <img src={require(`../image/HeadingImg/${Img}`)} alt="" />
                                                                                                                                            <Link to={Url} onClick={(event) => { ClickLink(event); RemoveParentCollapse(); }}>
                                                                                                                                                <button className='btn'>
                                                                                                                                                    <i className="fa-solid fa-arrow-right"></i>
                                                                                                                                                </button>
                                                                                                                                            </Link>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })
                                                                                                            )
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        )

                                                                    )
                                                                }
                                                                {
                                                                    !isDropdown && (
                                                                        <div className={`col-md-9 pe-0`}>
                                                                            <div className="row h-100">
                                                                                <div className="col mainSubContent">
                                                                                    {
                                                                                        NavbarDropDown && (
                                                                                            NavbarDropDown
                                                                                                .sort((a, b) => a.Name.localeCompare(b.Name)) // Sort by Name in ascending order
                                                                                                .map((item, index) => {
                                                                                                    const { IdName, ToggleId, className: innerClassName, DropDownData } = item;
                                                                                                    return (
                                                                                                        <div key={index} ref={el => collapseRef.current[innerClassName] = el}
                                                                                                            className={`collapse multi-collapse ${innerCollapse === innerClassName ? 'show' : ''}`}
                                                                                                            id={`${isDropdown ? ToggleId : innerClassName}`} aria-labelledby={`${isDropdown ? IdName : ''}`} data-bs-parent={`${isDropdown ? `#${IdName}` : ''}`}>
                                                                                                            <div className="card card-body border-0 rounded-0 p-0 ">
                                                                                                                <div className="row text-dark">
                                                                                                                    {
                                                                                                                        DropDownData && (
                                                                                                                            DropDownData.map((item, index) => {
                                                                                                                                const { Grid, Validation, className, Img, Name, Description, Url } = item;
                                                                                                                                {
                                                                                                                                    return (
                                                                                                                                        <div className={Grid} key={index}>
                                                                                                                                            {
                                                                                                                                                Validation === "12" && (
                                                                                                                                                    <div className={className}>
                                                                                                                                                        {
                                                                                                                                                            Description && (
                                                                                                                                                                Description.map((item, index) => {
                                                                                                                                                                    const { Name } = item;
                                                                                                                                                                    return (
                                                                                                                                                                        <h6 className=" text-dark" key={index}>{Name}</h6>
                                                                                                                                                                    )
                                                                                                                                                                })
                                                                                                                                                            )
                                                                                                                                                        }
                                                                                                                                                        <div className='AboutUsBtn'>
                                                                                                                                                            <Link to={`/aboutUs`} className={`float-end col-md-1`} >
                                                                                                                                                                <button className='btn' onClick={(event) => { ClickLink(event); RemoveParentCollapse(); }}>
                                                                                                                                                                    <i className="fa-solid fa-arrow-right"></i>
                                                                                                                                                                </button>
                                                                                                                                                            </Link>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                            {
                                                                                                                                                Validation === "4" && (
                                                                                                                                                    <div className={`row ${className}`}>
                                                                                                                                                        {
                                                                                                                                                            Img && (
                                                                                                                                                                <div className='ImgeSection col-md-2'>
                                                                                                                                                                    <img className="nav-icon-size img_text_align_fix" src={require(`../image/NavIcon/${Img}`)} alt="" />
                                                                                                                                                                </div>
                                                                                                                                                            )
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            Url !== "/" ? (
                                                                                                                                                                <div className='TextSection col-md-10 ps-0'>
                                                                                                                                                                    <Link to={Url} className="navTab-size text-decoration-none text-dark" onClick={(event) => { ClickLink(event); RemoveParentCollapse(); }}>{Name}</Link>
                                                                                                                                                                </div>
                                                                                                                                                            ) : (<div className='TextSection col-md-10 ps-0'> <p className="navTab-size text-decoration-none text-dark">{Name}</p> </div>)
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                            {
                                                                                                                                                className === "OurPartners" && (
                                                                                                                                                    <div className={`NavOurPartners`}>

                                                                                                                                                        <div className='ImgBox my-2 d-flex align-items-center'>
                                                                                                                                                            <img src={require(`../image/HeadingImg/${Img}`)} alt="" />
                                                                                                                                                            <Link to={Url} >
                                                                                                                                                                <button className='btn' onClick={(event) => { ClickLink(event); RemoveParentCollapse(); }}>
                                                                                                                                                                    <i className="fa-solid fa-arrow-right"></i>
                                                                                                                                                                </button>
                                                                                                                                                            </Link>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            }
                                                                                                                                            {
                                                                                                                                                className === "OurClients" && (
                                                                                                                                                    <div className={`NavOurPartners`}>

                                                                                                                                                        <div className='ImgBox my-2 d-flex align-items-center'>
                                                                                                                                                            <img src={require(`../image/HeadingImg/${Img}`)} alt="" />
                                                                                                                                                            <a href={Url} target="_blank">
                                                                                                                                                                <button className='btn' onClick={(event) => { ClickLink(event); RemoveParentCollapse(); }}>
                                                                                                                                                                    <i className="fa-solid fa-arrow-right"></i>
                                                                                                                                                                </button>
                                                                                                                                                            </a>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            }

                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            })
                                                                                                                        )
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                        )
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )

                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className="col-md-4 LinkBox">
                        <div className="Links d-flex align-items-center">
                            <div className="row d-flex LinkChild">
                                <SearchComponent />
                                <div className="col-md-6 d-flex align-items-center Link p-2">

                                    <a href="tel:+1 903-306-2430" className='px-1' target="_blank" rel="noopener noreferrer">
                                        <img className='phoneIcon p-2' src={phoneIcon} alt="" />
                                    </a>

                                    <a href="mailto:info@phenomecloud.com" className='px-1' target="_blank" rel="noopener noreferrer">
                                        <img className='emailIcon p-2' src={emailIcon} alt="" />
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>

                    <button
                        className={`navbar-toggler ${isOpen ? 'toggler-x' : ''}`}
                        type="button"
                        data-bs-toggle="collapse"
                        aria-expanded={isOpen}
                        data-bs-target="#NavBarContentRight"
                        aria-controls="NavBarContentRight"
                        onClick={toggleNavbar}
                    >
                        <span className={`${isOpen ? 'd-none' : ''}`}>
                            <TfiAlignJustify className='LineStyle' />
                        </span>
                        <span className={`${!isOpen ? 'd-none' : ''}`}>
                            <TfiClose className='LineStyle' />
                        </span>
                    </button>
                </div >
            </nav >
        </div >
    )
}
export default Header;
