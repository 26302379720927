import React from 'react'
import { Banner } from '../../Banner'
import TrustedOrganization from '../../TrustedOrganization'
import '../../../style/HCMLearning.css'
import { Link } from 'react-router-dom'
import { GoChevronRight } from "react-icons/go";
import FooterBrands from '../../FooterBrands'
import { FooterContact } from '../../FooterContact'
import { GetStarted } from '../../FooterSection/GetStarted'

const ManagedServices = ({ adata }) => {
  const serviceData = adata;
  return (
    <>
      <Banner />
      <TrustedOrganization />

      <div className="  managed_service_body">
        {
          serviceData[0].Cards.map((item, index) => (
            <div className={`managed_service_cards ${item.className}`} key={index} style={{ padding: '0px', margin: '0px' }}>
              <div className="container" style={{ padding: '3% !important' }}>


                <h3 className='managed_service_Card_header text-center'>{item.Name}</h3>
                {
                  item.Desc && (<p className={item.Desc_bg}>{item.Desc}</p>)
                }

                <div className="row justify-content-center" >
                  {
                    item.card.map((item1, index1) => (
                      <div className={`${item1.className}`}>
                        <img src={require(`../../../image/HCMLearning/${item1.Img}.png`)} height="50" width="50" alt="" />
                        <p className='text-start mt-4'>{item1.Heading}</p>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

          ))
        }
      </div>
      {/* <FooterContact /> */}
      <FooterBrands />
      <GetStarted />

    </>
  )
}

export default ManagedServices