import React, { useEffect, useState } from 'react'
import { Banner } from '../Banner'
import TrustedOrganization from '../TrustedOrganization'
import { Link } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import { GoPlus } from "react-icons/go";
import { AccordionButton } from 'react-bootstrap';
import { GoHorizontalRule } from "react-icons/go";
import AOS from 'aos'
import 'aos/dist/aos.css'
import { GetStarted } from '../FooterSection/GetStarted';
import FooterBrands from '../FooterBrands';
import { Helmet } from 'react-helmet';


export const EC_COE = ({ adata }) => {
    const ECCOE = adata

    const [expandedItem, setExpandedItem] = useState(null);

    const handleToggle = (eventKey) => {
        setExpandedItem(expandedItem === eventKey ? null : eventKey);
    };

    useEffect(() => {
        AOS.init({ duration: 2000 })
    })

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        const updateScreenSize = () => {
            setIsSmallScreen(window.innerWidth <= 1269);
        };

        window.addEventListener('resize', updateScreenSize);

        updateScreenSize();

        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Enterprise Cloud COE Services | Phenom eCloud</title>
                <meta name="description" content="Phenom eCloud's Enterprise Cloud Center of Excellence (COE) provides scalable cloud solutions, seamless integration, and advanced data management to enhance operational efficiency and foster innovation for businesses." />
            </Helmet>
            <Banner />
            <TrustedOrganization />
            <div className="EcCoe">
                {
                    ECCOE.map((item, index) => {
                        const { className, ContentData } = item;
                        return (
                            className === "FlexContentBody" && (
                                <div className={`${className} p-5 py-0 border-padding-remove`} key={index}>
                                    {ContentData && (
                                        ContentData.map((item, index) => {
                                            const { className, Grid } = item;
                                            return (
                                                <div className={className} key={index}>
                                                    {
                                                        Grid.map((item, index) => {
                                                            const { className, TextData } = item;
                                                            return (
                                                                <div className={className} key={index}>
                                                                    {
                                                                        TextData.map((item, index) => {
                                                                            const { className, BtnName, Name, Content, iconImg, Img, BtnUrl } = item;
                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    {
                                                                                        Content ? (
                                                                                            <div className="d-flex align-items-center mb-3" key={index} data-aos="linear">
                                                                                                {
                                                                                                    iconImg && (
                                                                                                        <div className="ImgSection">
                                                                                                            <img className='small-icon me-3' src={require(`../../image/EC/EC COE/${iconImg}`)} alt="" />
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                                {Name && (
                                                                                                    <p className={className}>{Name}</p>
                                                                                                )
                                                                                                }
                                                                                                {BtnUrl && (
                                                                                                    <Link to={BtnUrl} className={className}>{BtnName}</Link>
                                                                                                )}
                                                                                            </div>
                                                                                        ) : (
                                                                                            Img && (
                                                                                                <div className="d-flex justify-content-center" >
                                                                                                    <img className='img-size' src={require(`../../image/EC/EC COE/${Img}`)} alt="" />
                                                                                                </div>)
                                                                                        )
                                                                                    }

                                                                                </React.Fragment>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        }))
                                    }
                                </div>
                            )
                        )
                    })
                }
                {
                    ECCOE.map((item, index) => {
                        const { className, HeadingData, CardSection } = item;
                        return (
                            className === "OurServices" && (
                                <div className={`${className}`} key={index}>
                                    {
                                        HeadingData && (
                                            HeadingData.map((item, index) => {
                                                const { className, Grid, CardSection } = item;
                                                return (
                                                    <div className={className} key={index}>
                                                        <div className="Heading-Content justify-content-center text-center align-items-center row px-4 padding_remove">
                                                            {
                                                                Grid.map((item, index) => {
                                                                    const { GridName, className, Name } = item;
                                                                    return (
                                                                        <div className={GridName} key={index}>
                                                                            <p className={className} dangerouslySetInnerHTML={{ __html: Name }}></p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        {
                                                            CardSection && (
                                                                CardSection.map((item, index) => {
                                                                    const { className, CardData } = item;
                                                                    return (
                                                                        <div className={className} key={index}>
                                                                            <div className="card-section-content row px-5 justify-content-center text-center padding_remove">
                                                                                {
                                                                                    CardData.map((item, index) => {
                                                                                        const { GridName, className, ImgName, Heading, Name, UrlName, Url, popUp } = item;
                                                                                        return (
                                                                                            <div className={GridName} key={index} data-aos={`${isSmallScreen ? 'fade-up' : popUp}`}>
                                                                                                <div className={className}>
                                                                                                    <div className="cardImg text-start d-flex justify-content-between align-items-center">
                                                                                                        {
                                                                                                            Heading && (
                                                                                                                <p className='fw-bolder card-heading-size'>{Heading}</p>
                                                                                                            )
                                                                                                        }
                                                                                                        {ImgName && (
                                                                                                            <img className='icon-size icon-box' src={require(`../../image/EC/EC COE/${ImgName}`)} alt="" />
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="card-body px-1 d-flex flex-column text-start justify-content-center">
                                                                                                        <p className="Description text-start m-0">{Name}</p>
                                                                                                    </div>
                                                                                                    {
                                                                                                        Url && (
                                                                                                            <div className="UrlSection px-1 d-flex align-items-center justify-content-end">
                                                                                                                <Link to={Url} className='text-start text-decoration-none UrlText px-2' href="">{UrlName}</Link>
                                                                                                                <Link className='btn card-btn-color' to={Url}><i className="fa-solid fa-arrow-right px-2 "></i></Link>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            )

                                                        }
                                                    </div>
                                                )
                                            })
                                        )
                                    }

                                </div>
                            )
                        )
                    })
                }
                {
                    ECCOE.map((item, index) => {
                        const { className, ContentData, CardSection } = item;
                        return (
                            className === "FlexContent" && (
                                <div className={className} key={index}>
                                    {ContentData && (
                                        ContentData.map((item, index) => {
                                            const { containerClass, className, Grid } = item;
                                            return (
                                                <div className={className} key={index}>
                                                    <div className={`${containerClass ? containerClass : ''}`}>
                                                        {
                                                            Grid.map((item, index) => {
                                                                const { className, TextData } = item;
                                                                return (
                                                                    <div className={`${className}`} key={index}>
                                                                        {
                                                                            TextData.map((item, index) => {
                                                                                const { HeadinClass, className, BtnName, DropDown, IdName, Name, iconImg, Img, BtnUrl, mainGridClass } = item;
                                                                                return (
                                                                                    <React.Fragment key={index}>
                                                                                        {
                                                                                            HeadinClass ? (
                                                                                                <div className={`${HeadinClass ? HeadinClass : ''}`} id={IdName} >
                                                                                                    {
                                                                                                        iconImg && (
                                                                                                            <div className="ImgSection">
                                                                                                                <img className='small-icon me-2' src={require(`../../image/ERP/ERP COE/${iconImg}`)} alt="" />
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                    {Name && (
                                                                                                        <p className={className}>{Name}</p>
                                                                                                    )
                                                                                                    }
                                                                                                    {BtnUrl && (
                                                                                                        <Link to={BtnUrl} className={className}>{BtnName}</Link>
                                                                                                    )}

                                                                                                </div>
                                                                                            ) : (
                                                                                                < >
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            mainGridClass ? (
                                                                                                <div className={`${mainGridClass ? mainGridClass : ''}`} id={IdName} >
                                                                                                    <p className={className}>{Name}</p>
                                                                                                    {
                                                                                                        DropDown && (
                                                                                                            <Accordion className='mb-5 mt-5 Ec-fix-Height' data-aos="zoom-in">
                                                                                                                {DropDown.map((item, index) => {
                                                                                                                    const { Name, DescriptionData } = item;
                                                                                                                    return (
                                                                                                                        <Accordion.Item eventKey={index.toString()} className='m-2 border-0' key={index} >
                                                                                                                            <AccordionButton className='Dropdown-Bg-Color'
                                                                                                                                onClick={() => handleToggle(index.toString())}
                                                                                                                                aria-expanded={expandedItem === index.toString()}
                                                                                                                            >
                                                                                                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                                                                                                    <span className='fw-bolder sm-font-size'>{Name}</span>
                                                                                                                                    {expandedItem === index.toString() ? <GoHorizontalRule /> : <GoPlus className='PlusIcon' />}
                                                                                                                                </div>
                                                                                                                            </AccordionButton>
                                                                                                                            {DescriptionData.map((descItem, descIndex) => {
                                                                                                                                const { Heading, Name } = descItem;
                                                                                                                                return (
                                                                                                                                    <Accordion.Body key={descIndex} className='py-1'>
                                                                                                                                        <p className='fw-bolder sm-font-size'>{Heading}</p>
                                                                                                                                        <p className='sm-font-size'>{Name}</p>
                                                                                                                                    </Accordion.Body>
                                                                                                                                );
                                                                                                                            })}
                                                                                                                        </Accordion.Item>
                                                                                                                    );
                                                                                                                })}
                                                                                                            </Accordion>)
                                                                                                    }

                                                                                                    {Img && (
                                                                                                        <div className=" d-flex justify-content-center" data-aos="zoom-in">
                                                                                                            <img className='img-size float-end' src={require(`../../image/EC/EC COE/${Img}`)} alt="" />
                                                                                                        </div>)}


                                                                                                </div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )
                                                                                        }

                                                                                    </React.Fragment>
                                                                                );
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }))
                                    }

                                </div>
                            )
                        )
                    })
                }

            </div>
            <FooterBrands />
            <GetStarted />
        </>
    )
}
