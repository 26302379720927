import React from 'react'
import { Banner } from '../../Banner'
import TrustedOrganization from '../../TrustedOrganization'
import { GoChevronRight } from "react-icons/go";
import FooterBrands from '../../FooterBrands';
import { Link } from 'react-router-dom';
import { FooterContact } from '../../FooterContact';
import { GetStarted } from '../../FooterSection/GetStarted';

export const Gamification = ({ adata }) => {
    const GamificationData = adata;
    return (
        <>
            <Banner />
            <TrustedOrganization />
            <div className="GamificationContainer">
                {
                    GamificationData.map((item, index) => {
                        const { className, HeadingData, CardSection } = item;
                        return (
                            className !== "FlexContentBody" && (
                                <div className={className} key={index}>
                                    {
                                        HeadingData && (
                                            HeadingData.map((item, index) => {
                                                const { className, Grid } = item;
                                                return (
                                                    <div className={className} key={index}>
                                                        <div className="Heading-Content justify-content-center text-center align-items-center row px-4">
                                                            {
                                                                Grid.map((item, index) => {
                                                                    const { GridName, className, Name } = item;
                                                                    return (
                                                                        <div className={GridName} key={index}>
                                                                            <p className={className}>{Name}</p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        )
                                    }
                                    {
                                        CardSection && (
                                            CardSection.map((item, index) => {
                                                const { className, CardData } = item;
                                                return (
                                                    <div className={className} key={index}>
                                                        <div className="card-section-content row px-5 justify-content-center">
                                                            {
                                                                CardData.map((item, index) => {
                                                                    const { GridName, className, ImgName, Name, Heading } = item;
                                                                    return (
                                                                        <div className={GridName} key={index}>
                                                                            <div className={className}>
                                                                                <div className="cardImg">
                                                                                    {ImgName && (
                                                                                        <img className='icon-size' src={require(`../../../image/HCMLearning/${ImgName}`)} alt="" />
                                                                                    )}
                                                                                </div>
                                                                                <div className="card-body px-1 d-flex flex-column align-items-center justify-content-center">
                                                                                    {
                                                                                        Heading && (
                                                                                            <p className='fw-bolder'>{Heading}</p>
                                                                                        )
                                                                                    }
                                                                                    <p className="Description text-start m-0">{Name}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        )

                                    }
                                </div>
                            )
                        )
                    })
                }
                {
                    GamificationData.map((item, index) => {
                        const { className, ContentData } = item;
                        return (
                            className === "FlexContentBody" && (
                                <div className={className} key={index}>
                                    {ContentData && (
                                        ContentData.map((item, index) => {
                                            const { className, Grid } = item;
                                            return (
                                                <div className={className} key={index}>
                                                    {
                                                        Grid.map((item, index) => {
                                                            const { className, TextData } = item;
                                                            return (
                                                                <div className={className} key={index}>
                                                                    {
                                                                        TextData.map((item, index) => {
                                                                            const { className, Name, iconImg, Img } = item;
                                                                            return (
                                                                                <div key={index}>{
                                                                                    Name ? (
                                                                                        <div className="d-flex align-items-center mb-4">
                                                                                            {
                                                                                                iconImg && (
                                                                                                    <div className="ImgSection">
                                                                                                        <img className='small-icon me-3' src={require(`../../../image/HCMLearning/${iconImg}`)} alt="" />
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                            <p className={className}>{Name}</p>
                                                                                        </div>

                                                                                    ) : (<div className="d-flex justify-content-center">
                                                                                        <img className='img-size' src={require(`../../../image/HCMLearning/${Img}`)} alt="" />
                                                                                    </div>)
                                                                                }</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        }))
                                    }
                                </div>
                            )
                        )
                    })
                }
                {/* <FooterContact /> */}
            </div>
            <FooterBrands />
            <GetStarted />
        </>
    )
}
